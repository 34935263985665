import { combineReducers } from 'redux';
import { AllProjectNames, rFactorValues, SelectProjectId, SelectProjectValues, loadingToggleAction, SnackBardata, AddNewProject,loadingActionReducer ,AutocompleteFunction} from '../reducers/reducer';

const reducers = {
  rFactorValues: rFactorValues,
  allProjectNames : AllProjectNames,
  selectProjectValues:SelectProjectValues,
  SelectProjectId:SelectProjectId,
  SelectLoader:loadingToggleAction,
  loadingActionReducer:loadingActionReducer,
  SnackBarValue:SnackBardata,
  ProjectAdd:AddNewProject,
  autocomplete:AutocompleteFunction
};

const allReducers = combineReducers(reducers);

export default (state, action) => allReducers(action.type === 'USER_LOGOUT' ? undefined : state, action);
