import * as React from 'react';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { exportRecord } from '../redux/httpUtil/crud-helper';
import questions from '../json/questions.json';
import project from '../json/project.json';
import { SnackBar } from '../redux/actions/action';
import { useDispatch } from 'react-redux';
import config from "../config";

export default function Export(props) {
    const { baseFilters, isComingFromProject, filterModel } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [format, setFormat] = useState("");
    const dispatch = useDispatch();
    const sortModel = [{ field: 'createdOn', sort: 'desc' }];
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = async (e) => {
        setAnchorEl(null);
        setFormat(e.target.value);
    }

    const parseResponseAndSaveExcel = (responsetext) => {
        let responseData = null;
        try {
            let arr = new Uint8Array(responsetext);
            let str = arr.reduce((acc, i) => acc += String.fromCharCode.apply(null, [i]), '');
            responseData = JSON.parse(str);
        }
        catch (e) {
            // No need to add anything in this block because error will occur in case stream contains file. and we need to save file.
        }
        if (responseData) {
            dispatch(SnackBar({ open: 'true', message: `${(responseData.message || responseData.Message || "Download Issue due to no record found")}` }));
        }
        else {
            const url = window.URL.createObjectURL(new Blob([responsetext], { type: "application/vnd.ms-excel" }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', isComingFromProject ? "Project.xlsx" : "SuveyData.xlsx");
            document.body.appendChild(link);
            link.click();
        }
    }

    useEffect(() => {
        if (format && format !== "")
            exportRecord({
                api: `${config.api_url}/${isComingFromProject ? project.api : questions.api}/export`, setActiveRecord: (res) => {
                    if (res) {
                        parseResponseAndSaveExcel(res);
                    }
                }, sortModel, baseFilters, format, filterModel
            })
        setFormat("")
    }, [format]);

    return (
        <div>
            <Button

                id="basic-button"
                startIcon={<ImportExportIcon />}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                Export
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {/* <MenuItem value={"0"} onClick={handleClose}>PDF</MenuItem> */}
                <MenuItem value={"1"} onClick={handleClose}>EXCEL</MenuItem>
            </Menu>
        </div>
    );
}