import React, { useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import ShareIcon from '@mui/icons-material/Share';
import { Grid, Box } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from "@mui/material/Tooltip";
import { useFormik } from "formik";
import { FormHelperText, DialogActions } from '@material-ui/core';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import GridBase from "../components/GridBase";
import IconButton from '@mui/material/IconButton';
import { getRecord, saveRecord } from "../redux/httpUtil/crud-helper";
import Util from "../util";
import useStyles from '../styles';
import project from "../json/project.json";
import { useDispatch, useSelector } from "react-redux";
import { SelectedProject, SelectedProjectId, AddProject, SnackBar } from "../redux/actions/action";
import { FetechAllProjectName } from "../redux/httpUtil/fetchAllproject";
import { InvitationModal } from "../components/InvitationModal";
import ParseJwtToken from "../components/jwtToken";
import ProjectBase from "../components/ProjectBase";
import { Link } from '@mui/material';
import config from "../config";

const ProjectQuestionnaire = (props) => {
  var classes = useStyles()
  const defaultPageSize = 25;
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [projectParam, setProjectParam] = useState(null);
  const [modale, setModale] = useState(false);
  const toggle = () => setModal(!modal);
  const [params, setParams] = useState({ Id: "" });
  const [data, setData] = useState([]);
  const [updateValidData, setUpdateValidData] = useState(data);
  const [createdBy, setCreatedBy] = useState("");

  const addproject = useSelector((state) => state.ProjectAdd);

  const dispatch = useDispatch();

  const [activeRecord, setActiveRecord] = useState(undefined);
  const onAddClick = () => {
    const surveysCreatedByUser = data.filter(
      (item) => item.createdByUserId === Util.userId
    );
    localStorage.setItem("IsLoggedIn", false);
    dispatch(AddProject({ open: true }));
  };


  const onRowClick = ({ row }) => {
    let data = row.company;
    localStorage.setItem("selectedOption", row.name);
    localStorage.setItem("projectId", row.id);
    dispatch(SelectedProjectId(row.id));
    dispatch(SelectedProject(row?.name));
    navigate("/assessments", { state: data });
  }
  const tokenValue = localStorage.getItem("tokenValue");
  let isAdmin = false;

  if (tokenValue) {
    const decodedToken = ParseJwtToken(tokenValue);
    const userRole = decodedToken.UserRole;

    // check if user has admin privileges
    isAdmin = userRole &&userRole.includes("SuperAdmin");
  }
  let pinnedColumns = "name";
  const columns = [
    {
      field: "name",
      headerName: "Project Name",
      width: 250,
      renderCell: (params) => (
        <Tooltip title={params.row.name}>
          <Link
            component="div"
            sx={{ cursor: 'pointer', textDecoration: 'none' }}
            onClick={() => {
              onRowClick(params);
              localStorage.setItem("selectedOption", params.row.name);
              localStorage.setItem("projectId", params.row.id);
            }}
          >
            {params.row.name}
          </Link>
        </Tooltip>
      ),
    },

    {
      field: "company",
      headerName: "Company Name",
      width: 200,
      type: "string",
      renderCell: (params) => (

        <Tooltip title={params.row.company}>
          <div

            onDoubleClick={() => {
              onRowClick(params);
            }}
          >
            {params.row.company}
          </div>
        </Tooltip>

      ),
    },
    {
      field: "assessments",
      headerName: "Total Assessments",
      width: 170,
      type: "number",
      filterable: false,
      sortComparator: (value1, value2) => {
        if (value2 < value1) {
          return -1;
        }
        if (value2 > value1) {
          return 1;
        }
        return 0;
      },
      renderCell: (params) => (
        <span
          onDoubleClick={() => {
            onRowClick(params);
          }}
        >
          {params.row.assessments.length}
        </span>
      )
    },
    {
      field: "createdOn",
      headerName: "Created On",
      width: 140,
      renderCell: (params) => (
        <div
          onClick={() => {
            localStorage.setItem("selectedOption", params.row.name);
            localStorage.setItem("projectId", params.row.id);
          }}
        >
          {params.row[params.field] ? (
            <span>
              {dayjs(params.row[params.field]).format(Util.dateFormat.DateTime)}
            </span>
          ) : (
            <span>{params.row[params.field]}</span>
          )}
        </div>
      ),
      type: "date",
    },
    {
      field: "modifiedOn",
      headerName: "Modified On",
      width: 140,
      renderCell: (params) => (
        <div
          onClick={() => {
            localStorage.setItem("selectedOption", params.row.name);
            localStorage.setItem("projectId", params.row.id);
          }}
        >
          {params.row[params.field] ? (
            <span>
              {dayjs(params.row[params.field]).format(Util.dateFormat.DateTime)}
            </span>
          ) : (
            <span>{params.row[params.field]}</span>
          )}
        </div>
      ),
      type: "date",
    },
    {
      field: "createdByUser",
      headerName: "Created By",
      width: 280,
      type: "string",
      filterable: false
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 140,
      sortable: false,
      filterable: false,

      renderCell: (params) => {
        const tokenValue = localStorage.getItem("tokenValue");
        const userId = localStorage.getItem("userId");
        let isAdmin = false;
        let canEdit = false;
        let userRole;
        if (tokenValue) {
          const decodedToken = ParseJwtToken(tokenValue);
          userRole = decodedToken.UserRole;
          isAdmin = (userRole && userRole.includes("SuperAdmin"))
        }
        params.row.permittedUsers.forEach((user) => {
          if ((user.userId === userId && user.accessId === 1) || isAdmin) {
            canEdit = true;
          }
        });
        if (params.row.company === "Demo Company" && (userRole === "User" || userRole === "Admin")) {
        }
        else {
          return (
            <>
              {canEdit ? (
                <>
                  <IconButton
                    color="primary"
                    onClick={() => onEdit(params)}
                  >
                    <Tooltip title="Edit">
                      <EditIcon />
                    </Tooltip>

                  </IconButton>
                  {isAdmin && (
                    <IconButton
                      color="primary"
                      onClick={() =>
                        openModal({ id: params.id, name: "archive" })
                      }
                    >
                      <Tooltip title="Archive">
                        <ArchiveIcon />
                      </Tooltip>
                    </IconButton>
                  )}
                </>
              ) : (
                <>
                  <IconButton

                    color="primary" onClick={() => onEdit(params)}
                    disabled
                  >
                    <Tooltip title="Edit">
                      <EditIcon />
                    </Tooltip>

                  </IconButton>
                  {isAdmin && (
                    <IconButton
                      color="primary"
                      onClick={() =>
                        openModal({ id: params.id, name: "archive" })
                      }
                      disabled
                    >
                      <Tooltip title="Archive">
                        <ArchiveIcon />
                      </Tooltip>
                    </IconButton>
                  )}
                </>
              )}
              <IconButton
                color="primary"
                onClick={() => handleInvite(params)}
              >
                <Tooltip title="Share">
                  <ShareIcon />
                </Tooltip>
              </IconButton>
            </>
          );
        }
      },
    },
  ];

  const modifiedColumns = columns.filter((column) => {
    if (!isAdmin && column.field === "createdBy") {
      return false;
    }
    return true;
  });
  const onEdit = (record) => {
    if (!record.isEditable) {
      dispatch(AddProject({ open: true }));
      getRecord({
        id: `${record.id}`, api: `${config.api_url}/${project.api}`, setActiveRecord: (data) => {
          if (data) {
            const Newdata = data?.record[0] || [];
            setActiveRecord(Newdata);
          }
        },
      })
    }
  };

  const handleCellEdit = (params) => {
    if (
      setParams({
        Id: params.id,
        [params.field]: params.value,
        name: "rfactor",
      })
    ) {
      toggle();
    } else if (params.field === "toolStrategy") {
      const validGridData = data;
      const validFormData = data.filter((e) => e.surveyDataId === params.id)[0];
      if (params.value.length > 50) {
        const validString = params.value.slice(0, 50);
        validFormData.toolStrategy = validString;
        const index = validGridData.findIndex(
          (e) => e.surveyDataId == params.id
        );
        validGridData[index].toolStrategy = validString;
        setUpdateValidData(validGridData);
      } else {
        validFormData.toolStrategy = params.value;
      }
      const isSavedWork = saveRecord({
        id: params.id,
        api: `${config.api_url}/${project.api}`,
        data: validFormData,
      });
      if (isSavedWork) {
        setData(validGridData);
      }
    }
  };

  const openModal = ({ id, name }) => {
    toggle();
    setParams({ Id: id, name: name });
  };

  const validationSchema = (formfields) => {
    const validationConfig = {};
    let config;
    if (params?.name === "rfactor") {
      config = yup.string().label("OverRideRFactorComment");
      config = config.required(`Please fill the Comment`);
      validationConfig["OverRideRFactorComment"] = config;
    } else {
      config = yup.string().label("DeleteComment");
      config = config.required(`Please fill the Comment`);
      validationConfig["DeleteComment"] = config;
    }
    return yup.object(validationConfig);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {},
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      let gridData = data;

      let formData = data.filter((e) => e.id === params.Id)[0];
      formData.archiveComment = values.DeleteComment;
      formData.isArchived = true;
      formData.archivedOn = new Date();
      formData.archivedByUserId = localStorage.getItem("userId");
      gridData = gridData.filter((project) => project.id !== params.Id);

      const isSaved = saveRecord({
        id: params.Id,
        api: `${config.api_url}/${project.api}`,
        data: formData,
      });

      isSaved.then((e) => {
        if (e) {
          setData(gridData);
          dispatch(FetechAllProjectName());
          dispatch(SnackBar({ open: 'true', message: 'Project Archived Successfully' }));
        }
      });

      toggle();
      resetForm();
    },
  });

  const handleInvite = (params) => {
    setProjectParam(params);
    toggles();
  };

  const handleCancle = () => {
    formik.resetForm();
    toggle();
    setData(data);
  };

  const handleClose = () => {
    setModal(false);
    formik.resetForm();
  };

  const handleDialogSubmit = () => {
    formik.handleSubmit();
  }


  const archiveModal = (
    <Dialog open={modal} toggle={toggle} onClosed={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",  // Set your width here
          },
        },
      }}
    >
      <DialogTitle id="alert-dialog-title">
        {"Reason for Archive"}
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <TextField
            type="textarea"
            name="DeleteComment"
            id="DeleteComment"
            placeholder="Archive Comment"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            invalid={formik?.errors["DeleteComment"]}
            value={formik?.values["DeleteComment"]}
          />
          <FormHelperText
            invalid={formik?.errors["DeleteComment"]}
          >
            {formik?.errors["DeleteComment"]}
          </FormHelperText>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancle} color="error">
          Cancel</Button>
        <Box style={{ flex: 1 }} />
        <Button type="submit" onClick={handleDialogSubmit} >
          Submit</Button>
      </DialogActions>
    </Dialog>
  );

  const actionModals = {
    archive: archiveModal,
  };

  const toggles = () => {
    setModale(!modale);
  };

  const handleParamsUpdate = (updatedParams) => {
    setProjectParam(updatedParams);
  };
  const [refresh, setRefresh] = useState(null);
  return (
    <>
      {addproject.open ?
        <ProjectBase formContents={project} activeRecord={activeRecord} setRefresh={setRefresh} /> : null}
      {modale && (
        <InvitationModal data={projectParam} modal={modale} Toggle={toggles} onUpdateParams={handleParamsUpdate} />
      )}
      {actionModals[params?.name]}
      <GridBase
        columns={modifiedColumns}
        updateValidData={updateValidData}
        onCellEditCommit={(currentRow) => handleCellEdit(currentRow)}
        isCellEditable={() => true}
        isProject={true}
        data={data}
        canAdd={true}
        canDelete={false}
        canExport={true}
        setData={setData}
        onRowClick={onRowClick}
        handleClickOpen={onAddClick}
        baseParams={{ isFromAssesMentPage: false }}
        baseFilters={[
          { Field: "IsArchived", Operator: "equals", Value: "false" },
        ]}
        defaultPageSize={defaultPageSize}
        pinnedColumn={pinnedColumns}
        refresh={refresh}
      />
    </>
  );
};
export default ProjectQuestionnaire;
