import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { DialogActions } from '@material-ui/core';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';

export const AssessmentDetailModal = (props) => {
  const { modal, Toggle } = props;
  const result =
    useSelector((state) => {
      const { rFactorValues = {} } = state || {};
      return rFactorValues;
    }) || {};

  const { RfactorPredictor, ...others } = result;
  const handleClose = () => {
    Toggle();
  }

  const datas = [
    {
      id: 1,
      name: "Draft",
      description:
        "An assessment has been started, but it is at a preliminary stage.",
    },
    {
      id: 2,
      name: "In Progress",
      description:
        "The assessment has been started but has not yet been reviewed or completed.",
    },
    {
      id: 3,
      name: "Review",
      description:
        "Assessments with this status require further review before they can be marked as completed.",
    },
    {
      id: 4,
      name: "Complete",
      description:
        "An assessment with this status indicates it has been completed and there is no need for further action.",
    },
  ];

  const data = [
    {
      id: 1,
      name: "REHOST",
      description:
        "Lift and shift…” / “Quick wins. Move application to target state without any change in the application’s code or architecture. It typically involves lifting and shifting the application. Re-host signifies that the application is stable on-premises and no further code updates are necessary to do a similar job in the target cloud.",
    },
    {
      id: 2,
      name: "REPLATFORM",
      description:
        "Lift, tweak, and shift. Changes to enable the application to use modern cloud technologies/services. Migrating an application or workload to a new platform, such as from a legacy system to a modern, cloud-native platform, while making some changes to the application’s underlying architecture. Replatform is an upgrade path to the application for target cloud supportability and to reduce risks. This path is chosen often when the licensing solution of a database or operating system is very expensive to operate in the cloud.",
    },
    {
      id: 3,
      name: "REARCHITECT",
      description:
        "This option should be chosen if the application is to be retained in its current state.",
    },
    {
      id: 4,
      name: "REFACTOR",
      description:
        "Refactor - “Significant code changes required for the application migration.” Making significant changes to an application’s underlying architecture, such as breaking it down into microservices, to improve its scalability, maintainability, and overall performance.",
    },
    {
      id: 5,
      name: "RETAIN/REHOST",
      description:
        "Lift and shift” Used when code modification is impossible and the workload is not certified for the cloud, or the data center lease is expiring, and a quick migration is needed. Because the platform is still owned by the organization rehosting cannot take advantage of cloud-native features such as monitoring security and governance.",
    },
  ];

  const tableData = [
    {
      id: 1,
      name: 'Moving To Cloud',
      description: 'This option should be chosen if the application needs to be moved to the cloud.',
    },
    {
      id: 2,
      name: 'SaaS',
      description: 'This option should be chosen if the application is already a SaaS from a 3rd party provider.',
    },
    {
      id: 3,
      name: 'Retain',
      description: 'This option should be chosen if the application is to be retained in its current state.',
    },
    {
      id: 4,
      name: 'In The Cloud',
      description: 'This option should be chosen if the application is already on one of the public cloud providers.',
    },
  ];

  const tableData1 = [
    {
      id: 1,
      name: 'Outage & Criticality',
      description: 'Impact of application unavailability on business. Outages cause immediate impact to the business.',
      useCase: 'As an example, a rating of Very High indicates a significant financial, brand, or customer impact for a company in the finance industry. Conversely, if an e-mail server went down for a few hours, it would not be severe for some industries.',
    },
    {
      id: 2,
      name: 'Application Code Release',
      description: 'The frequency and speed of application updates are measured by this metric.',
      useCase: 'Updates introducing new features are more frequent in SaaS products. Daily – Very High\nWeekly – High\nMonthly – Medium\nQuarterly – Low\nN/A or None – Not important',
    },
    {
      id: 3,
      name: 'Strategic Value',
      description: 'The purpose of this metric is to identify whether this application is "differentiating" or "revenue-generating" to the business.',
      useCase: 'For instance, an eCommerce site generates revenue, while an insurance company\'s quote application generates conversions. These two examples would receive a Very High rating.',
    },
    {
      id: 4,
      name: 'Integration Complexity',
      description: "An application or system's dependencies and data exchanges are measured by this metric. Complexity increases with integrations.",
      useCase:
        'Integrations with other systems or apps:\n' +
        'Very High - ~15+ integration points\n' +
        'High - ~10+ integration points\n' +
        'Medium - ~5+ integration points\n' +
        'Low - ~1 – ~5 integration points\n' +
        'None – 0 integration points',
    },
    {
      id: 5,
      name: 'Risk Factors',
      description: "An application's risk is assessed using this metric. The risks include application stability, developer support, EOL, etc.",
      useCase: 'It is considered Critical, for example, if you are running an old Client-Server technology without support and it is crucial to the business.\n' +
        'Critical – Too many Risks\n' +
        'High – Several Risks not as Critical\n' +
        'Some – Some Risks not as High\n' +
        'Few – Few Risks not as Some\n' +
        'None – No Perceived Risk',
    },
    {
      id: 6,
      name: 'Application Knowledge',
      description: 'It determines the level of risk, supportability, and effort involved in modernizing and transforming an application in the target state.',
      useCase: 'Having no application developers in the team is perceived as None, while a large team with many app-savvy members is perceived as Very High.\n' +
        'Very High – Greater Application Knowledge\n' +
        'None – No Knowledge or Developers',
    },
    {
      id: 7,
      name: 'Latency Sensitivity',
      description: 'A metric that indicates latency risks in relation to other applications, datastores, or services that are dependent on this application.',
      useCase: 'For example,\n' +
        'Very High – It introduces significant performance-related and end-user-related issues. Examples: SAN, iSCSI, Database, OLTP, etc.\n' +
        'None – No Knowledge or Developers',
    },
    {
      id: 8,
      name: 'Technology Stack',
      description: 'The degree to which the tech stack is aligned with cloud technologies.',
      useCase: 'Cutting Edge and Modern technologies such as REACT, JSON, PYTHON, ANGULAR, etc. are well suited in the cloud realm.',
    },
    {
      id: 9,
      name: 'Move Urgency',
      description: 'Does moving this application to the cloud have a high priority?',
        useCase: 'Cloud adoption is a business decision due to a lack of vendor support or to reap the benefits of cloud computing.\n'+
      'Very Short – ASAP\n'+
      'Short – < 3 months\n'+
      'Medium – 3 - 6 months\n'+
      'Long – 6 – 12 months\n',   
    },
    {
      id: 10,
      name: 'Database Complexity',
      description: 'The ease or difficulty of migrating a Database to the cloud is determined by this factor.',
      useCase: 'It is classified as hard if it is an old database with too many customizations, uses native DLLs, or is tied to the operating system, etc.',
    },
    {
      id: 11,
      name: 'SaaS Offering?',
      description: 'In the case that your application is offered to customers as a SaaS product.',
      useCase: 'Salesforce is an example of a SaaS product.',
    },
    {
      id: 12,
      name: 'Security',
      description: 'If your application is externally communicating via HTTP(s) or API.',
      useCase: 'Frequently, applications are accessed via a browser or through APIs.',
    },
    {
      id: 13,
      name: 'Data Security',
      description: 'If the database or application stores sensitive data.',
      useCase: 'Data that is sensitive can be PHI or credit card or financial data, and encryption is required while it is in transit or at rest.',
    },
    {
      id: 14,
      name: 'High Availability',
      description: 'The application has been designed to achieve higher system reliability by incorporating redundancy, fault-tolerance, and failover mechanisms. These techniques aim to ensure uninterrupted operation of the system even in the event of hardware or software failures or other disruptions.',
      useCase: 'High availability is particularly important for critical applications and services, such as online banking, e-commerce, healthcare systems, and emergency services.',
    },

  ];
  return (
    <Dialog open={modal} size="lg" toggle={Toggle}>
      <DialogTitle toggle={Toggle}>Assessment page</DialogTitle>
      <DialogContent>
        <Box>
          <div>
            <div>
              <Typography variant="h7" component="h6">Status</Typography>
              <Typography variant="body">The four stages in the assessment lifecycle, as follows:</Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>NAME</TableCell>
                    <TableCell>DESCRIPTION</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {datas.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>{item.id}</TableCell>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.description}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              
              <div style={{ marginTop: '10px' }} />
              <h5>Application Strategy</h5>
              <p>
                The application strategy determines if a full assessment is
                required as follow,
              </p>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell sx={{ width: '20%' }}>NAME</TableCell>
                    <TableCell>DESCRIPTION</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.id}</TableCell>
                      <TableCell sx={{ width: '30%' }}>{row.name}</TableCell>
                      <TableCell>{row.description}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              
              <div style={{ marginTop: '10px' }} />
              <h5>Assessment Criteria Rules</h5>
              <p>The following table summarizes.</p>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>NAME</TableCell>
                    <TableCell>DESCRIPTION</TableCell>
                    <TableCell>USE CASE</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData1.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.id}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.description}</TableCell>
                      <TableCell>{row.useCase}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button  onClick={handleClose}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
};
