
import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import { Drawer, Toolbar, List, IconButton, ListItem, Collapse, ListItemButton, ListItemText, Box, Typography, Grid, Autocomplete, TextField } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import PollOutlinedIcon from "@mui/icons-material/PollOutlined";
import GroupIcon from '@mui/icons-material/Group';
import ArchiveIcon from "@mui/icons-material/Archive";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LogoutIcon from "@mui/icons-material/Logout";
import logo2 from "../assets/logo.png";
import reports from "../assets/reports.png";
import { useNavigate, Outlet, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { HelpModal } from "../components/HelpModal";
import { AssessmentModal } from "../components/AssessmentModal";
import { AssessmentDetailModal } from "../components/AssessmentDetailModal";
import ParseJwtToken from "../components/jwtToken";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from '@mui/material/Alert';
import menuItems from "./menuItems";
import { SnackBar, AutoCompleteAction, ProjectData } from "../redux/actions/action";
import DynamicFeedSharpIcon from '@mui/icons-material/DynamicFeedSharp';
import LibraryBooksSharpIcon from '@mui/icons-material/LibraryBooksSharp';
import CloudSyncSharpIcon from '@mui/icons-material/CloudSyncSharp';
import LuggageSharpIcon from '@mui/icons-material/LuggageSharp';
import TransformSharpIcon from '@mui/icons-material/TransformSharp'
import ChangeCircleSharpIcon from '@mui/icons-material/ChangeCircleSharp';
import { CustomLoader } from "../components/CustomLoader";
import InfoRounded from "@mui/icons-material/InfoRounded";
import config from "../config";
import Export from '../pages/Export';

const drawerWidth = 260;
const navIcons = {
  'dashboard': <DashboardIcon color='primary' />,
  'assignmentIcon': <LibraryBooksSharpIcon color='primary' />,
  'projectsIcon': <DynamicFeedSharpIcon color='primary' />,
  'devopsIcon': <CloudSyncSharpIcon color="primary" sx={{ ml: 2 }} />,
  'conatinerIcon': <LuggageSharpIcon color='primary' sx={{ ml: 2 }} />,
  'transformationIcon': <TransformSharpIcon color='primary' sx={{ ml: 2 }} />,
  'modernizationIcon': <ChangeCircleSharpIcon color='primary' sx={{ ml: 2 }} />
}

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `${0}px`,

  })
);


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


const titles = {
  "/projects": "Projects",
  "/": "Assessments",
  "/assessments": "Assessments",
  "/assessmentdetail": "Assessment Detail",
  "/addAssessment": "Add Assessment",
  "/editAssessment": "Edit Assessment",
  "/result": "Result",
  "/archive": "Archive",
  "/dashboard": "Dashboard",
  "/reports": "Reports",
  "/containers": "Containers Opportunity",
  "/devops": "DevOps Opportunity",
  "/transformation": "App.Transformation Opportunity",
  "/modern": "Modernization Opportunity",
  "/users": "Users",
  "/roles": "Roles"
};



export default function Layout() {
  const [expanded, setExpanded] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState(titles["/"]);
  const [Modal, setModal] = React.useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const prevState = useRef();
  useEffect(() => {
    prevState.current = expanded
  }, [expanded])

  const items = useSelector(state => state.SnackBarValue);
  const isLoading = useSelector(state => state.loadingActionReducer);
  const { assessmentId } = useParams();
  React.useEffect(() => {
    if ({ assessmentId }.assessmentId && { assessmentId }.assessmentId === '0') {
      setTitle(titles['/addAssessment']);
    } else if ({ assessmentId }.assessmentId && { assessmentId }.assessmentId !== '0') {
      setTitle(titles['/editAssessment']);
    } else {
      setTitle(titles[location.pathname]);

    }
  }, [location.pathname]);
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(!open);
    setExpanded(false);
  };

  const ProjectList = useSelector((state) => state);
  const { selectProjectValues, allProjectNames } = ProjectList;

  const handleLogout = () => {
    localStorage.clear();
    dispatch(ProjectData([]));
    navigate("/");
  };
  const toggle = () => {
    setModal(!Modal);
  };
  const ToggleFalse = () => {
    setModal(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleReportButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleReportMenuClose = () => {
    setAnchorEl(null);
  };

  const handleExpand = () => {
    if (open == false)
      setExpanded(false)
    else {
      setExpanded(!expanded);
    }
  };

  const handleClose = () => {
    dispatch(SnackBar({ open: 'false', message: '' }));
  }

  let value = localStorage.getItem("selectedOption");

  const [previousValue, setPreviousValue] = useState(null);
  useEffect(() => {
    if (value) {
      setPreviousValue(value);
    }
  }, [value]);

  <IconButton
    size="large"
    edge="start"
    color="inherit"
    aria-label="menu"
    sx={{ mr: 2 }}
    onClick={handleReportButtonClick}
  >
    <img src={reports} alt="Reports" />
  </IconButton>;

  let superAdmin;
  let admin;
  const tokenValue = localStorage.getItem("tokenValue");
  if (tokenValue) {
    const Decode = ParseJwtToken(tokenValue);
    // check if user has admin privileges    
    superAdmin = Decode.UserRole && Decode.UserRole.includes("SuperAdmin");
    admin = Decode.UserRole && Decode.UserRole.includes("Admin");
  }
  const handleDownloadPDF= async () => {
    try {
      const response = await fetch(`${config.api_url}/User/DownloadPDF`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Leapx Cloud R-factor Guide v1.1.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  };

  return (
    <><Box sx={{ display: "flex", width: "100%" }} className={`${open ? "openDrawer" : ""}`}>
      {Modal && location.pathname === "/dashboard" ? (
        <HelpModal modal={Modal} Toggle={toggle} />
      ) : Modal && location.pathname === "/assessments" ? (
        <AssessmentModal modal={Modal} Toggle={toggle} />
      ) : (
        <AssessmentDetailModal modal={Modal} Toggle={toggle} />
      )}
      <Snackbar
        open={items.open == 'false' ? false : items.open} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {items.message}
        </Alert>
      </Snackbar>
      <AppBar position="fixed" >
        <Toolbar>
          <Box display="flex" flexGrow={1.2}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, }}
            >
              <MenuIcon />
            </IconButton>
            <Box
              sx={{ mt: "auto", mb: "auto" }}
            >
              <img src={logo2} height={60} width={180} alt="logo" />
            </Box>
          </Box>
          <Box display="flex" flexGrow={1.2}>
            <Typography component="h1" variant="h6" color="inherit">
              {title}
            </Typography>
          </Box>
          {location.pathname === "/dashboard" || location.pathname === "/assessments" || location.pathname === "/devops" || location.pathname === "/containers"
            || location.pathname === "/transformation" || location.pathname === "/modern" || location.pathname === "/users"
            ? (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={allProjectNames.slice(1).map((item) => item.name)}
                sx={{ width: "300px", borderRadius: '8px', background: "white" }}
                value={value}
                renderInput={(params) => (
                  <TextField {...params} size="small"
                  />
                )}
                emptyText={!previousValue ? 'Select an option' : ''}
                onChange={(event, newValue) => {
                  if (newValue != "" && newValue != null)
                    dispatch(AutoCompleteAction({ on: true, newValue: newValue }));
                }}
              />
            ) : <Box sx={{ width: '300px' }}></Box>}

          <IconButton
            color="inherit"
            onClick={toggle}
          >
            {location.pathname === "/dashboard" || location.pathname === "/assessments" || location.pathname === `/assessmentdetail/${assessmentId}` ? (
              <HelpOutlineIcon />
            ) : null}
          </IconButton>

          <IconButton color="inherit" edge="end" onClick={handleLogout}>
            <LogoutIcon />
          </IconButton>

        </Toolbar>
      </AppBar>
      <div >
        <Drawer
          variant="permanent"
          open={open}
          sx={{
            display: 'flex',
            [`& .MuiDrawer-paper`]: { width: open ? 'drawerWidth' : '4em', marginTop: '65px' },
            flexShrink: 0
          }}

        >
          <List component="nav">
            <ListItem disablePadding sx={{ display: 'block' }}>
              {menuItems.map((item, ind) => {
                const { hasChildren, path, icon, children, text } = item;
                return <React.Fragment key={ind}>
                  {!hasChildren ? <Grid sx={{ display: 'flex' }}>
                    <ListItemButton
                      onClick={() => {
                        navigate(path);
                        ToggleFalse();
                      }}
                    >
                      {icon ? navIcons[icon] : null}
                      <ListItemText
                        primary={text} sx={{ opacity: open ? 1 : 0, ml: 1 }}></ListItemText>
                    </ListItemButton>
                  </Grid> : null}
                  {hasChildren ? <Grid sx={{ display: 'flex' }}>
                    <ListItemButton
                      startIcon={open && expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      onClick={handleExpand}
                      sx={{ height: '47px' }}
                    >
                      <PollOutlinedIcon color="primary" />
                      <ListItemText sx={{ opacity: open ? 1 : 0, ml: 1 }}>{text}
                        {open && expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </ListItemText>
                    </ListItemButton>
                  </Grid> : null}
                  {hasChildren ? <Collapse in={expanded} timeout="auto" unmountOnExit>
                    {children && children.map((childItem, childInd) => {
                      return <Grid sx={{ display: "flex" }} key={`${childInd}-${ind}`}>
                        <ListItemButton
                          selected={location.pathname === childItem.path}
                          onClick={() => {
                            navigate(childItem.path);
                            handleReportMenuClose();
                            ToggleFalse();
                          }}
                        >
                          {/* <PollOutlinedIcon color="primary" sx={{ ml: 2 }} /> */}

                          {icon ? navIcons[childItem.icon] : null}
                          <ListItemText
                            primary={childItem.text}
                            sx={{ ml: 1 }}
                          >
                          </ListItemText>
                        </ListItemButton>
                      </Grid>;
                    })}

                  </Collapse> : null}

                </ React.Fragment>;
              })}

              {superAdmin || admin ? (
                <Grid sx={{ display: 'flex' }}>
                  <ListItemButton
                    onClick={() => {
                      navigate("/archive");
                      ToggleFalse();
                    }}
                  >
                    <ArchiveIcon color="primary" />
                    <ListItemText primary='Archive' sx={{ opacity: open ? 1 : 0, ml: 1 }}></ListItemText>
                  </ListItemButton>
                </Grid>
              ) : null}
              {superAdmin ? (
                <Grid sx={{ display: 'flex' }}>
                  <ListItemButton
                    onClick={() => {
                      navigate("/users");
                      ToggleFalse();
                    }}
                  >
                    <GroupIcon color="primary" />
                    <ListItemText primary='Users' sx={{ opacity: open ? 1 : 0, ml: 1 }}></ListItemText>
                  </ListItemButton>
                </Grid>
              ) : null}
              <Grid sx={{ display: 'flex' }}>
                <ListItemButton
                  onClick={handleDownloadPDF}
                >
                  <InfoRounded color="primary" />
                  <ListItemText primary='Guide' sx={{ opacity: open ? 1 : 0, ml: 1 }}></ListItemText>
                </ListItemButton>
              </Grid>
            </ListItem>
          </List>
        </Drawer>
      </div>
    </Box>
      <Box sx={{ display: "flex", width: "100%" }}>
        <Main open={open}>
          <Box component="main" sx={{ flexGrow: 1, ml: expanded && open ? 38 : open && !expanded ? 20 : 8 }}>
            <CustomLoader open={isLoading} />
            <Outlet />
          </Box>
        </Main>
      </Box></>
  );
}
