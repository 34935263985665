import React, { useRef, useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useDispatch } from 'react-redux';
import { saveRecord } from '../redux/httpUtil/crud-helper';
import config from '../config';
import { SnackBar } from '../redux/actions/action';

const RegisterWithGoogle = () => {
	const dispatch = useDispatch();
	const [isLoading, setLoader] = useState(false);
	const [setActiveTabId] = useState(0);

	const handleSubmit = (values) => {
		// Handle the form submission logic here
		const formData = {};
		setLoader(true);

		if (values && !formData.email) {
			// Add the Google login data to your formData
			formData.GoogleLoginData = values;
		}
		saveRecord({
			id: null,
			api: `${config.api_url}/User/create`,
			data: formData,
			setActiveRecord: (res) => {
				setLoader(false);
				if (res.isSuccess) {
					dispatch(
						SnackBar({ open: "true", message: "User signed up successfully" })
					);
				} else {
					dispatch(SnackBar({ open: "true", message: `${res.message}` }));
				}
				setActiveTabId(0);
			},
		});
	};

	const googleLogin = useGoogleLogin({
		onSuccess: (tokenResponse) => {
			handleSubmit(tokenResponse.access_token);
		},
		onError: errorResponse => console.log(errorResponse),
	});
	return (
		<div className="spacer">
			<button className="google-login-button" onClick={googleLogin}>
				<span className="google-icon"></span>
				<span className='login-btn-text'> Sign up with Google</span>
			</button>
		</div>
	);
};

export default RegisterWithGoogle;
