import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import useStyles from '../styles';
import { useNavigate } from 'react-router-dom';
import { saveRecord } from '../redux/httpUtil/crud-helper';
import config from '../config';
import ParseJwtToken from '../components/jwtToken';
import { SnackBar } from '../redux/actions/action';

const GoogleAsLogin = () => {
	const dispatch = useDispatch();
	const [isLoading, setLoader] = useState(false);
	const classes = useStyles();
	const navigate = useNavigate();
	const [activeTabId, setActiveTabId] = useState(0);
	const items = useSelector((state) => state.SnackBarValue);

	const handleSubmit = (values) => {
		// Handle the form submission logic here
		const formData = {};
		if (values.button === "cancel") {
			setLoader(true);
		} else {
			setLoader(true);
			if (activeTabId === 0) {
				if (values && !formData.email) {
					// Add the Google login data to your formData
					formData.GoogleLoginData = values;
				}
				saveRecord({
					id: null,
					api: `${config.api_url}/User/login`,
					data: formData,
					setActiveRecord: (res) => {
						setLoader(false);
						if (res.isSuccess) {
							localStorage.setItem("tokenValue", res.value);
							localStorage.setItem("ArchiveBy", values.loginEmail);
							localStorage.setItem("IsLoggedIn", true);
							const Decode = ParseJwtToken(res.value);
							localStorage.setItem("userId", Decode.UserId);
							setTimeout(() => {
								navigate("/dashboard");
							}, 500);
						} else {
							dispatch(SnackBar({ open: "true", message: res.error }));
						}
					},
				});
			}
		}
	};
	const googleLogin = useGoogleLogin({
		onSuccess: (tokenResponse) => {
			handleSubmit(tokenResponse.access_token);
		},
		onError: errorResponse => console.log(errorResponse),
	});
	return (
		<div className="spacer">
			<button className="google-login-button" onClick={googleLogin}>
				<span className="google-icon"></span>
				<span className='login-btn-text'> Sign in with Google</span>
			</button>
		</div>

	);
};

export default GoogleAsLogin;
