import GridBase from "../components/GridBase";
import React, { useState } from "react";
import { useEffect } from "react";
import { Box, Input, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import Util from "../util";
import questions from "../json/questions.json";
import Donut from "../components/Donut";
import { getAllRecords } from "../redux/httpUtil/crud-helper";
import { useDispatch, useSelector } from "react-redux";
import { SelectedProject, SelectedProjectId, AddProject, AutoCompleteAction } from "../redux/actions/action";
import { FetechAllProjectName } from "../redux/httpUtil/fetchAllproject";
import ParseJwtToken from "../components/jwtToken";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogActions, FormControl } from "@material-ui/core";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import project from "../json/project.json";
import ProjectBase from "../components/ProjectBase";
import config from "../config";

const CustomInfoBox = (props) => {
  return <Grid item xs={12} md={4} lg={4}>
    <Paper sx={{ borderRadius: '15px' }}>
      <Box p={2.4}>
        <Typography variant="h6" >{props.text}</Typography>
        <Typography variant="h4" > {props.value}</Typography>
      </Box>
    </Paper>
  </Grid>
}
export default function Dashboard() {

  const projectId = useSelector((s) => s.SelectProjectId)
  const defaultPageSize = 25;
  const [data, setData] = useState([]);
  const [wholeDataArray, setWholeDataArray] = useState([]);
  const [wholeDataObject, setWholeDataObject] = useState({});

  const navigate = useNavigate();
  const ProjectIdLocal = localStorage.getItem("projectId")
  const [selectedOption, setSelectedOption] = useState("");
  const [selectProjectId, setselectProjectId] = useState(ProjectIdLocal === "null" ? null : ProjectIdLocal);
  const [activeRecord, setActiveRecord] = useState(undefined);
  const addproject = useSelector((state) => state.ProjectAdd);
  const [sortModel, setSortModel] = useState([
    { field: "createdOn", sort: "desc" },
  ]);
  const [overLay, setOverLay] = useState(true);
  const [filter, setfilter] = useState(true);


  const [baseFilters, setBaseFilters] = useState(
    selectProjectId === null
      ? [
        { Field: "IsArchived", Operator: "equals", Value: "false" },
        { Field: "ProjectId", Operator: "equals", Value: null },
      ]
      : [{ Field: "IsArchived", Operator: "equals", Value: "false" },
      { Field: "ProjectId", Operator: "equals", Value: selectProjectId },

      ]
  );


  // For addProject button removal
  let Admin;
  const tokenValue = localStorage.getItem("tokenValue");
  if (tokenValue) {
    const Decode = ParseJwtToken(tokenValue);
    // check if user has admin privileges
    Admin = Decode.UserRole && Decode.UserRole.includes('Admin') || Decode.UserRole && Decode.UserRole.includes('SuperAdmin');
  }

  const autocomplete = useSelector((state) => state.autocomplete);
  let datas = autocomplete.newValue;


  const [changeBaseFilter, setChangeBaseFilter] = useState([
    { Field: "IsArchived", Operator: "equals", Value: "false" },
  ]);

  const toggleOverLay = () => {
    setOverLay(!overLay);
    localStorage.setItem("IsLoggedIn", false);
  };
  const [search, setSearch] = useState("");

  const handleBaseFilter = (donutData) => {
    let arr = [...donutData, { Field: "ProjectId", Operator: "equals", Value: projectId }];
    setBaseFilters(arr);
    setChangeBaseFilter(arr);
    setfilter(false);
  };

  // get data from redux project list
  const dispatch = useDispatch();
  const ProjectList = useSelector((state) =>
    state);
  const { selectProjectValues, allProjectNames } = ProjectList;
  useEffect(() => {
    dispatch(FetechAllProjectName());
    getAllRecords({
      id: null,
      api: `${config.api_url}/${questions.api}`,
      setActiveRecord: (res) => {
        if (res) {
          setWholeDataArray(res.record.surveyData);
          setWholeDataObject(res.record);
        }
      },
      sortModel,
      baseFilters,
      changeBaseFilter
    });
  }, [baseFilters]);

  const onRowClick = (record) => {
    let currentUserId = localStorage.getItem("userId");
    let { permittedUsers } = record.row;
    let dataFilter = permittedUsers.length > 0 && permittedUsers.filter((item) => item.userId == currentUserId) || "";
    if (!record.isEditable) {
      navigate(`/assessmentdetail/${record.id}`, {
        state: { from: "dashboard", Access: dataFilter[0] },
      });
    }
  };

  useEffect(() => {
    localStorage.setItem("projectId", projectId);
  }, [projectId]);
  let pinnedColumns = "surveyTitle"
  const columns = [
    {
      field: "surveyTitle",
      headerName: "Assessment Title",
      minWidth: 250,
      type: "string",
      renderCell: (params) => (
        <Tooltip title={params.row.surveyTitle}>
          <span onClick={() => {
            onRowClick(params)
          }}>{params.row.surveyTitle}</span>
        </Tooltip>

      ),
    },
    {
      field: "status",
      headerName: "Application Strategy",
      width: 150,
      type: "string",
      renderCell: (params) => (
        <Tooltip title={params.row.status}>
          <span >{params.row.status}</span>
        </Tooltip>

      ),
    },
    {
      field: "rFactorValue",
      headerName: "Predicted R-factor",
      width: 220,
      renderCell: (params) => (
        <Tooltip title={params.row.rFactorValue}>
          <span >{Util.ToLowerCse(params.row.rFactorValue)}</span>
        </Tooltip>

      ),
    },
    {
      field: "overRideRFactorValue",
      headerName: "Override R-factor",
      width: 200,
      renderCell: (params) => (
        <Tooltip title={params.row.overRideRFactorComment}>
          <span>{Util.ToLowerCse(params.row.overRideRFactorValue)}</span>
        </Tooltip>
      ),
    },
    {
      field: "assessmentStatusId",
      headerName: "Status",
      width: 110,
      valueGetter: (params) => {
        switch (params.value) {
          case 0:
            return "Draft";
          case 1:
            return "In progress";
          case 2:
            return "Review";
          case 3:
            return "Complete";
          default:
            return "";
        }
      },
      valueOptions: ({ row }) => ['Draft', 'In Progress', 'Review', 'Complete'],
      type: "singleSelect",
    },
    {
      field: "toolStrategy",
      headerName: "Tool Strategy",
      width: 350,
    },
    {
      field: "createdOn",
      headerName: "Created On",
      width: 140,
      valueGetter: (params) => {
        const originalValue = params.row[params.field];
        if (originalValue) {
          return dayjs(originalValue).format(Util.dateFormat.DateTime);
        }
        return originalValue;
      },
      type: "date",
    },
    {
      field: "modifiedOn",
      headerName: "Modified On",
      width: 140,
      valueGetter: (params) => {
        const originalValue = params.row[params.field];
        if (originalValue) {
          return dayjs(originalValue).format(Util.dateFormat.DateTime);
        }
        return originalValue;
      },
      type: "date",
    },
  ];

  const onAddClick = () => {
    const surveysCreatedByUser = data.filter(
      (item) => item.createdByUserId === Util.userId
    );
    localStorage.setItem("IsLoggedIn", false);
    navigate("/assessmentdetail/0", { state: { from: "dashboard" } });
  };

  const onAddProjectClick = () => {
    localStorage.setItem("IsLoggedIn", false);
    navigate("/projects/", { state: { from: "Project" } });
    setTimeout(() => {
      dispatch(AddProject({ open: true }));
    }, 0);
  };

  const handletoggleOverLay = () => {
    localStorage.setItem("IsLoggedIn", false);
    toggleOverLay();
  };

  const defaultCheck = () => {
    const value = allProjectNames.filter((e) => {
      return e.company == "Demo Company";
    })
    let data = value[0].name;
    localStorage.setItem("selectedOption", data);
    localStorage.setItem("projectId", value[0].id);
    setSelectedOption(data);
    HandleProjectFliter(data);
    dispatch(SelectedProject(data));
    handletoggleOverLay();
  }

  const HandleProjectFliter = (name) => {
    const value = allProjectNames.filter((e) => {
      return e.name == name;
    });
    dispatch(SelectedProjectId(value[0].id))
    let arr = [{ Field: "IsArchived", Operator: "equals", Value: "false" }, { Field: "ProjectId", Operator: "equals", Value: value[0].id }];
    setBaseFilters(arr);
    setChangeBaseFilter(arr);
    dispatch(AutoCompleteAction({ on: false, newValue: "" }));
  };




  useEffect(() => {
    const storedOption = localStorage.getItem("selectedOption");
    if (storedOption) {
      setSelectedOption(storedOption);
    }
  }, []);




  const paraClickHandle = (ele) => {
    localStorage.setItem("selectedOption", ele.name);
    HandleProjectFliter(ele.name);
    dispatch(SelectedProject(ele.name));
    handletoggleOverLay();
    navigate('/dashboard');
    //window.location.reload();
  };

  const onChangeProjectFliter = (newValue) => {
    localStorage.setItem("selectedOption", newValue)
    HandleProjectFliter(newValue);
    dispatch(SelectedProject(newValue));
  }


  if (autocomplete.on == true && autocomplete.newValue != " ") {
    setSelectedOption(datas);
    onChangeProjectFliter(datas);
  }


  return (
    <>
      {addproject.open ?
        <ProjectBase formContents={project} activeRecord={activeRecord} /> : null}
      {localStorage.getItem("IsLoggedIn") === "true" ? (
        <div>
          <Dialog open={overLay} toggle={toggleOverLay} backdrop="static" sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "500px",  // Set your width here
              },
            },
          }}>
            <DialogTitle toggle={toggleOverLay}>Projects</DialogTitle>
            <DialogContent>
              <FormControl >
                <Input
                  placeholder="Search for projects"
                  onChange={(e) => setSearch(e.target.value)}
                  sx={{ mb: 2, width: 430 }}
                />
              </FormControl>
              <div className="scroll">
                {allProjectNames
                  .filter((ele) => {

                    if (ele.name === "All") {
                      return "";
                    }
                    if (search === "") {
                      return ele;
                    } else if (
                      ele.name.toLowerCase().includes(search.toLowerCase())
                    ) {
                      return ele;
                    }
                  })
                  .map((ele) => {
                    return (
                      <div key={ele?.id}>
                        <p
                          className="pointer"
                          onClick={() => {
                            paraClickHandle(ele)
                          }}
                        >
                          {ele.name}
                        </p>
                        <hr />
                      </div>
                    );
                  })}
              </div>
            </DialogContent>
            <DialogActions>
              <Button

                className="pos-left"
                onClick={defaultCheck}
                color='error'
              >
                Cancel
              </Button>
              <Box style={{ flex: 1 }}></Box>
              {Admin ? (
              <Button
                className="pos-right"
                onClick={onAddProjectClick}
              >
                Add Project
              </Button>
              ) : null}
            </DialogActions>
          </Dialog>
        </div>
      ) : null}

      <Grid container rowSpacing={1} my={4} columnSpacing={2} sx={{ mt: 6 }}>
        <Grid item xs={12} md={6} lg={8} direction="row">
          <Paper sx={{ borderRadius: '15px' }}>
            <Card sx={{ minWidth: 275 }} elevation={0}>
              <CardContent>
                <Typography variant="h5">
                  R-Factor Result
                </Typography>
              </CardContent>
            </Card>
            <Donut
              wholeDataArray={wholeDataArray}
              handleBaseFilter={handleBaseFilter}
            />
          </Paper>
        </Grid >
        <Grid item xs={12} md={4} lg={4}>
          <Grid container rowSpacing={3} columnSpacing={1} direction='column'>
            <CustomInfoBox text={'Complete'} value={wholeDataObject.completedValue} />
            <CustomInfoBox text={'In Progress'} value={wholeDataObject.inProgressValue} />
            <CustomInfoBox text={'In Review'} value={wholeDataObject.reviewValue} />
            <CustomInfoBox text={'In Draft'} value={wholeDataObject.draftsValue} />
          </Grid>

        </Grid>
      </Grid>
      <Box>
        <GridBase
          columns={columns}
          data={data}
          canAdd={true}
          canDelete={false}
          canExport={true}
          setData={setData}
          onRowClick={onRowClick}
          handleClickOpen={onAddClick}
          baseFilters={baseFilters}
          changeBaseFilter={changeBaseFilter}
          defaultPageSize={defaultPageSize}
          isProject={false}
          pinnedColumn={pinnedColumns}
          temp={true}
          ifDashboard={filter}
          isArchived={true}
        />
      </Box>
    </>
  );
}