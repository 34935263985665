import React from 'react';
import { useFormik } from 'formik';
import { FormControl, TextField, Button } from '@mui/material';
import { FormLabel, Grid, Tab, Tabs } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import useStyles from '../styles'
import logo1 from "../assets/logo9.png";
import config from "../config";

const ForgotPasswordPage = () => {
	let token = window.location.hash;
	const removedString = token.substring(token.indexOf('=') + 1);
	var classes = useStyles()
	const navigate = useNavigate()
	const Password = async (data) => {

		const value = {
			token: `${removedString}`,
			password: `${data}`
		}

		let formdata = new FormData();
		formdata.append('token', value.token);
		formdata.append('password', value.password);
		let url = `${config.api_url}/User/ResetPassword?token=${value.token}&password=${value.password}`;
		const response = fetch(url, {
			method: 'put',
			headers: {
				'Content-Type': 'application/json',
			}
		})
	}

	const formik = useFormik({
		initialValues: {
			newPassword: '',
			confirmNewPassword: '',
		},
		onSubmit: values => {
			// Handle form submissions
			navigate('/')
		},
		validate: values => {
			const errors = {};

			if (!values.newPassword) {
				errors.newPassword = 'Cannot be empty';
			}
			if (!values.confirmNewPassword) {
				errors.confirmNewPassword = 'Cannot be empty';
			}
			if (values.newPassword && values.confirmNewPassword && values.newPassword !== values.confirmNewPassword) {
				errors.confirmNewPassword = 'Password mismatch';
			}
			return errors;
		},
	});

	return (
		<Grid className={classes.container}>
			{/* Add white color in this */}
			<div className={classes.logotypeContainer} style={{ backgroundImage: `url(${logo1})`, backgroundSize: "cover" }}>
			</div>
			<div style={{
				display: 'flex',
				justifyContent: 'center',
				width: '50%'
			}} >
				<form onSubmit={formik.handleSubmit} >
					<FormControl sx={{ width: '400px' }} >
						<FormLabel sx={{ marginLeft: '140px' }}>
							<Tabs
								// indicatorColor='primary'
								// textColor='primary'
								centered
							>
								<Tab label='Forgot Password' />
							</Tabs>
						</FormLabel>
						<TextField
							fullWidth
							variant="outlined"
							sx={{ mt: 3 }}
							id="newPassword"
							name="newPassword"
							label="New Password"
							type="password"

							value={formik.values.newPassword}
							onChange={formik.handleChange}
							error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
							helperText={formik.touched.newPassword && formik.errors.newPassword}

						/>
						<TextField
							fullWidth
							variant="outlined"
							sx={{ mt: 3 }}
							id="confirmNewPassword"
							name="confirmNewPassword"
							label="Confirm New Password"
							type="password"

							value={formik.values.confirmNewPassword}
							onChange={formik.handleChange}
							error={formik.touched.confirmNewPassword && Boolean(formik.errors.confirmNewPassword)}
							helperText={formik.touched.confirmNewPassword && formik.errors.confirmNewPassword}

						/>
						<Button type="submit" variant="contained" color="primary" sx={{ mt: 4 }} onClick={() => {
							let data = formik.values.newPassword;
							let confirmData = formik.values.confirmNewPassword;
							if (data == confirmData) {
								Password(data)
							}
						}
						}>
							Submit
						</Button>
					</FormControl>
				</form>
			</div>
		</Grid>
	);
};

export default ForgotPasswordPage;
