import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export const CustomLoader = ({ open, style = {} }) => {
    const sx = { backgroundColor: 'transparent', zIndex: 10, ...style }
    return <Backdrop
        open={open}
        sx={sx}
    >
        <CircularProgress />
    </Backdrop>
}