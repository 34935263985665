import React from "react";
import { HashRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import Questionnaire from "./pages/Questionnaire";
import Dashboard from "./pages/dashboard";
import Layout from "./Layout/Layout";
import ResultList from "./components/List";
import SurveyDetail from "./pages/SurveyDetail";
import DevOpsOpportunity from "./pages/DevOpsOpportunity";
import ContainersOpportunity from "./pages/ContainersOpportunity";
import ModernizationOpportunity from "./pages/ModernizationOpportunity";
import TransformationOpportunity from "./pages/TransformationOpportunity";
import Archive from "./pages/Archive";
import ProjectQuestionnaire from "./pages/Project";
import { ToastContainer } from 'react-toastify';
import { PrivateRoute } from "./route/privateRoute";
import ParseJwtToken from "./components/jwtToken";
import SetupUser from "./pages/SetupUser";
import ForgotPasswordPage from "./pages/forgotPassword";
export default function App() {

  return (<>
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/account/recovery" element={<ForgotPasswordPage />}></Route>
        <Route path="/" element={<Layout />}>
          <Route path="/projects" element={<PrivateRoute><ProjectQuestionnaire /></PrivateRoute>} />
          <Route path="/assessments" element={<PrivateRoute><Questionnaire /></PrivateRoute>} />
          <Route path="/assessmentdetail/:assessmentId" element={<PrivateRoute><SurveyDetail /></PrivateRoute>} />
          {/* <Route path="/projectdetail/:projectId" element={<PrivateRoute><SurveyDetail /></PrivateRoute>} /> */}
          <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
          <Route path="/result" element={<PrivateRoute><ResultList /></PrivateRoute>} />
          <Route path="/archive" element={<PrivateRoute><Archive /></PrivateRoute>} />
          <Route path="/devops" element={<PrivateRoute><DevOpsOpportunity /></PrivateRoute>} />
          <Route path="/containers" element={<PrivateRoute><ContainersOpportunity /></PrivateRoute>} />
          <Route path="/modern" element={<PrivateRoute><ModernizationOpportunity /></PrivateRoute>} />
          <Route path="/transformation" element={<PrivateRoute><TransformationOpportunity /></PrivateRoute>} />
          <Route path="/users" element={<PrivateRoute><SetupUser /></PrivateRoute>} />
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
    <ToastContainer />
  </>
  );
}