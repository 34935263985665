import { Box } from "@mui/system";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from "@mui/material/DialogActions";
import { Typography,Button } from '@mui/material';

export const ContainersModal = (props) => {
  const handleClose=()=>{
    Toggle();
  }
  const { modal, Toggle } = props;

  const sections = [
    {
      title: "Containerization",
      content: "Package the application and its dependencies into a container image using a tool like Docker."
    },
    {
      title: "Container Registry",
      content: "Store the container images in a centralized repository like Docker Hub, Azure Container Registry (ACR), Google Container Registry, or Amazon Elastic Container Registry (ECR)."
    },
    {
      title: "Container Orchestration",
      content: "Deploy and manage containers using a container orchestration tool like Kubernetes, Docker Swarm, Azure Kubernetes Service (AKS), a managed Kubernetes service in Azure or Azure Container Instances (ACI), a serverless solution for running containers or Amazon Elastic Container Service (ECS)."
    },
    {
      title: "Cluster Management:",
      content: "Set up a cluster of nodes to run containers and manage resource allocation example using AKS or ACI in Azure."
    },
    {
      title: "Networking: ",
      content: "Configure the network to allow communication between containers and external resources. For example, Configure the network using Azure Virtual Network (VNet) and Azure Load Balancer to allow communication between containers and external resources."
    },
    {
      title: "Container Orchestration",
      content: "Deploy and manage containers using a container orchestration tool like Kubernetes, Docker Swarm, Azure Kubernetes Service (AKS), a managed Kubernetes service in Azure or Azure Container Instances (ACI), a serverless solution for running containers or Amazon Elastic Container Service (ECS)."
    },
    {
      title: "Container Orchestration",
      content: "Deploy and manage containers using a container orchestration tool like Kubernetes, Docker Swarm, Azure Kubernetes Service (AKS), a managed Kubernetes service in Azure or Azure Container Instances (ACI), a serverless solution for running containers or Amazon Elastic Container Service (ECS)."
    },
    {
      title: "Container Orchestration",
      content: "Deploy and manage containers using a container orchestration tool like Kubernetes, Docker Swarm, Azure Kubernetes Service (AKS), a managed Kubernetes service in Azure or Azure Container Instances (ACI), a serverless solution for running containers or Amazon Elastic Container Service (ECS)."
    },
    {
      title: "Storage",
      content: "Provision and manage persistent storage for containers. For example, provision and manage persistent storage for containers using Azure Disk Storage or Azure Files."
    },
    {
      title: "Monitoring and Logging",
      content: "Monitor the health and performance of containers and the cluster and collect and analyze log data. For example, monitor the health and performance of containers and the cluster using Azure Monitor, and collect and analyze log data using Azure Log Analytics."
    },
    {
      title: "Continuous Integration and Deployment (CI/CD)",
      content: "Integrate containerization into the CI/CD pipeline to automate the build and deployment of containers. For example, integrate containerization into the CI/CD pipeline using Azure DevOps or other CI/CD tools."
    },
    {
      title: "Security",
      content: "Implement security measures to protect containers and the cluster, such as network segmentation and secure image signing. For example, Azure AD integration."
    },
    
  ];

  return (
    <Dialog open={modal} size="lg" toggle={Toggle}>
      <DialogTitle toggle={Toggle}>Implementaton Guidance: Container Opportunity</DialogTitle>
      <DialogContent>
        <Box >
        {sections.map((section, index) => (
      <div key={index}>
        <Typography variant="h6" component="h6"  sx={{mt:1}}>
          {section.title}
        </Typography>
        <Typography variant="body2">
          {section.content}
        </Typography>
      </div>
    ))} 
        </Box>
        </DialogContent>
        <DialogActions>
          <Button   onClick={handleClose}>Ok</Button>
        </DialogActions>
   </Dialog>
  );
};
