import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import { LicenseInfo } from '@mui/x-license-pro';
import store, { persistor } from "./redux/store/configureStore";
import { PersistGate } from "redux-persist/integration/react";
import './App.css';

LicenseInfo.setLicenseKey('cc97b7d6858f3e0a42873e332a75e44cT1JERVI6NDE5MDIsRVhQSVJZPTE2ODE1NzcyNzAwMDAsS0VZVkVSU0lPTj0x');
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<Provider store={store}>
		<PersistGate persistor={persistor}>
			<App />
		</PersistGate>
	</Provider>
);
