import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { SnackBar } from '../redux/actions/action';
import { useDispatch } from 'react-redux';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Autocomplete,
  Box
} from "@mui/material";
import TextField from '@mui/material/TextField';

import config from "../config";
import { Grid, Checkbox, FormControl, FormGroup } from '@mui/material';
const useStyles = makeStyles(() => ({
}));

const UserEditModal = ({ user, open, handleClose, setRefresh }) => {
  if (user.isRegistered == null) {
    user.isRegistered = true;
  }
  const dispatch = useDispatch();
  let authToken = localStorage.getItem("tokenValue");
  const classes = useStyles();

  const [editableUser, setEditableUser] = useState(user);

  const handleRoleChange = (event, newValue) => {
    setEditableUser({ ...editableUser, roleId: newValue?.id });
  };

  const handleFirstNameChange = (event) => {
    setEditableUser({ ...editableUser, firstName: event.target.value });
  };

  const handleLastNameChange = (event) => {
    setEditableUser({ ...editableUser, lastName: event.target.value });
  };

  const handleEmailChange = (event) => {
    setEditableUser({ ...editableUser, email: event.target.value });
  };

  const handleIsApprovedChange = (event) => {
    setEditableUser({ ...editableUser, isDeactivated: !event.target.checked });
  };


  const allRoles = [
    {
      id: 1,
      name: "SuperAdmin",
    },
    {
      id: 2,
      name: "Admin",
    },
    {
      id: 3,
      name: "User",
    },
  ];


  const handleSave = async (id) => {
    const response = await axios.put(
      `${config.api_url}/User/id/?id=${id}`,
      {
        ...editableUser,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken ? authToken : ""}`,
        },
      }
    );
    handleClose();
    response.status === 200 &&
      dispatch(SnackBar({ open: 'true', message: 'User Updated Successfully' }));
      setRefresh(new Date());
  };

  return (
    <Dialog open={open} onClose={handleClose} sx={{
      "& .MuiDialog-container": {
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: "500px",  // Set your width here
        },
      }
    }} >

      <DialogTitle>
        Edit User
      </DialogTitle>

      <DialogContent >
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <FormControl fullWidth>
            <TextField
              label="First Name"
              value={editableUser.firstName}
              onChange={handleFirstNameChange}
            />
          </FormControl>
          <FormControl sx={{ mt: 2 }} fullWidth>
            <TextField
              label="Last Name"
              value={editableUser.lastName}
              onChange={handleLastNameChange}
            />
          </FormControl>
          <FormControl sx={{ mt: 2 }} fullWidth>
            <TextField
              label="Email"
              value={editableUser.email}
              onChange={handleEmailChange}
            />
          </FormControl>
          <FormControl sx={{ mt: 2 }} fullWidth>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={allRoles}
              getOptionLabel={(option) => option.name}
              defaultValue={allRoles.find((role) => role.id === editableUser.roleId)}
              value={allRoles.find((role) => role.id === editableUser.roleId)}
              renderInput={(params) => <TextField {...params} label="Role" autoComplete="off" />}
              onChange={handleRoleChange}
            />
          </FormControl>
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!editableUser.isDeactivated}
                    color="primary"
                    onChange={handleIsApprovedChange}
                  />
                }
                label="Active"
              />
            </FormGroup>
          </FormControl>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}  color="error">
          Cancel
        </Button>
        <Box style={{flex:1}}></Box>
        <Button onClick={() => handleSave(editableUser.id)} color="primary" >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserEditModal;