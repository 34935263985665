import * as yup from 'yup';
const compareValidatorRegex = /^compare:(.+)$/;
export const validationSchema = (formFields) => {
    const validationConfig = {}, initialValues = {}, formDef = [];
    for (const column of formFields) {
        // TODO: handle requiredIfNew
        const { label, type = 'string', required = false, requiredIfNew = false, maxLength, disabled, name } = column;
        let { defaultValue } = column;
        const formLabel = label;
        let config;
        // do not show in form if no header/ caption
        if (!formLabel) {
            continue;
        }
        if (disabled) {
            continue;
        }

        if (type === 'boolean') {
            if (typeof defaultValue !== 'boolean') {
                defaultValue = false;
            }
        }
        if (type === 'radio') {
            config = yup.string().label(formLabel);
            if (required) {
                config = config.required("Please select value");
            }
        }
        if (type === 'string' || type === 'password' || type === 'textarea') {
            if (typeof defaultValue !== 'string') {
                defaultValue = '';
            }
            config = yup.string().label(formLabel);

            if (required) {
                config = config.required(`Please fill the ${formLabel}`);
            }
            if (requiredIfNew) {
                config = config.required();
            }
            if (column.minLength) {
                config = config.min(column.minLength);
            }
            if (maxLength) {
                config = config.max(maxLength);
            }
            if (column.validate) {
                switch (column.validate) {
                    case 'isEmail':
                        config = config.email();
                        break;
                    case 'isUrl':
                        config = config.url();
                        break;
                    case 'isNumeric':
                        config = config.number();
                        break;
                    default:
                        compareValidatorRegex.lastIndex = 0;
                        const compareValidator = compareValidatorRegex.exec(column.validate);
                        if (compareValidator) {
                            const compareFieldName = compareValidator[1];
                            const compareField = formDef.find(f => f.name === compareFieldName);
                            config = config.oneOf([yup.ref(compareFieldName)], `${formLabel} must match ${compareField.label}`);
                            config = config.oneOf([yup.ref(compareFieldName), null], `${formLabel} must match ${compareField.label}`);
                        } else {
                            throw new Error(`Unknown validation ${column.validate}`);
                        }
                }
            }
        }
        if (type === 'number') {
            if (typeof defaultValue !== 'number') {
                defaultValue = 0;
            }
            config = yup.number().label(formLabel);
            if (required) {
                config = config.required();
            }
            if (column.decimal !== true) {
                config = config.integer();
            }
            if (typeof column.minValue === 'number') {
                config = config.min(column.minValue);
            }
            if (typeof column.maxValue === 'number') {
                config = config.max(column.maxValue);
            }
        }
        // TODO: yup model for date
        if (type === 'date') {
            if (Object.prototype.toString.call(defaultValue) === '[object Date]') {
                defaultValue = new Date();
            }
            config = yup.date().label(formLabel);
            if (required) {
                config = config.required(`Please select date`);
            } else {
                config = config.nullable();
            }
            if (column.validate) {
                compareValidatorRegex.lastIndex = 0;
                const compareValidator = compareValidatorRegex.exec(column.validate);
                if (compareValidator) {
                    const compareFieldName = compareValidator[1];
                    const compareField = formDef.find(f => f.name === compareFieldName);
                    config = config.min(yup.ref(compareFieldName), `${formLabel} can't be before ${compareField.label || compareField.header}`);
                } else {
                    throw new Error(`Unknown validation ${column.validate}`);
                }
            }
        }
        if (type === "select") {
            config = yup.string().label(formLabel);    
            if (required) {
              config = config.required("Please select value");
            }
          }
        initialValues[name] = defaultValue;
        if (config) {
            validationConfig[name] = config;
        }
        formDef.push(column);
    }
    return yup.object(validationConfig);
}