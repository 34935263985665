import React, { useState, useEffect } from "react";
import FormBase from "../components/FormBase";
import questions from "../json/questions.json";
import project from "../json/project.json";
import Util from "../util";
import { getRecord } from "../redux/httpUtil/crud-helper";
import { Container, Grid, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import ProjectBase from "../components/ProjectBase";
import { useSelector } from "react-redux";
import config from "../config";

const SurveyDetail = (props) => {
  const [activeRecord, setActiveRecord] = useState(undefined);
  const navigate = useNavigate();
  const { assessmentId, projectId } = useParams();
  const SelectProjectId = useSelector((state) => state.SelectProjectId);
  const [title, setTitle] = React.useState();
  const location=useLocation();
  React.useEffect(() => {
    setTitle(
      location?.state?.from === "dashboard" ? "Assessments" : location?.state?.from 
    );
  }, [location.state && location.state.from]);

  useEffect(() => {
    if (assessmentId) {
      if (assessmentId === "0") {
        let formValues = {};
        formValues.ProjectId = SelectProjectId;
        formValues.assessmentStatusId = 1;
        formValues.Status = "Moving To Cloud";
        setActiveRecord({ id: undefined, formValues });
      }
       else {
         const apiUrl =  `${config.api_url}/${questions.api}`;
        getRecord({ id: assessmentId, api: apiUrl, setActiveRecord: (data) => {
            if (data) {
              const record =  data.record
              let formValues = JSON.parse(record.formFields);

              for (const key in formValues) {
                formValues[key] = formValues[key].value;
              }
              formValues["SurveyTitle"] = record.surveyTitle;
              formValues["Status"] = record.status;
              formValues["Comment"] = record.comment;
              formValues["assessmentStatusId"] = record.assessmentStatusId;
              formValues["ProjectId"] = record.projectId;
              formValues["ToolStrategy"] = data.record.toolStrategy;
              setActiveRecord({ id: record.id, formValues });
            }
          },
        });
      }
    } 
    else if (projectId && projectId !== "0") {
      getRecord({ id: projectId, api: `${config.api_url}/${project.api}`, setActiveRecord: (data) => {
        if (data) {
          const Newdata = data?.record[0] || [];
          setActiveRecord(Newdata);
        }
      },
    });
    }
  }, []);

  const onCancelClick = () => {
    navigate(-1);
  };


  const {Access}=location.state;
  return (
    <>
        <Grid sx={{ mt: 5 }}> 
            {title && title === "Project" ? 
            (<ProjectBase formContents={project} activeRecord={activeRecord} />) : (
              <FormBase access={Access} formContents={questions} activeRecord={activeRecord} fields={Util.questionnaireFields} onCancelClick={onCancelClick}/>
            )}
          </Grid>
    </>
  );
};
export default SurveyDetail;