
import logger from './logger';
export const flag = false;

const saveRecord = async function ({ id, api, data, setActiveRecord }) {
    let url, method;
    if (id) {
        url = `${api}/id/?id=${id}`;
        method = 'PUT';
    } else {
        url = api;
        method = 'POST';
    }
    try {
        const authToken = localStorage.getItem("tokenValue");
        const response = await fetch(url, {
            method,
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken ? authToken : ""}`
            }
        });
        if (response.status === 200) {
            const result = await response.json();
            setActiveRecord && setActiveRecord(result);
            return true;
        }
        else {
            const result = await response.json()
            return false;
        }
    } catch (error) {
        return false
    }
};

const updateRecord = async function ({ id, api, data, setActiveRecord }) {
    let url, method;
    if (id) {
        url = `${api}/id/?id=${id}`;
        method = 'PUT';
    } else {
        url = api;
        method = 'POST';
    }

    try {
        const authToken = localStorage.getItem("tokenValue");
        const response = await fetch(url, {
            method,
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken ? authToken : ""}`
            }
        });
        if (response.status === 200) {
            const result = await response.json();
            result.value.assessmentStatusId = 0;
            setActiveRecord && setActiveRecord(result);
            return true;
        }
        else {
            const result = await response.json()
            return false;
        }

    } catch (error) {
        // logger.error('Save failed', error);
        return false
    }
};

const toTitleCase = (str) => {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1);
        }
    );
}

const getRecord = async ({ api, id, setActiveRecord, page, pageSize, sortModel, filterModel, isFromAssesMentPage = false, baseFilters = [], projectFliter }) => {
    let url;
    if (sortModel && sortModel.length == 0) {
        sortModel = [{ field: "CreatedOn", sort: "desc" }];
    }
    if (id) {
        url = `${api}/id/?id=${id}`;
    } else {
        const searchParams = new URLSearchParams();
        searchParams.set("start", page * pageSize);
        searchParams.set("limit", pageSize);
        searchParams.set("isFromAssesMentPage", isFromAssesMentPage);
        if (sortModel?.length) {
            const sort = sortModel.map(sort => {
                return { sort: toTitleCase(sort.field), dir: sort.sort }
            });
            searchParams.set("sort", JSON.stringify(sort));
        }
        const where = [];
        if (filterModel?.items?.length) {
            filterModel.items.map(filter =>
                where.push({
                    Field: toTitleCase(filter.columnField),
                    Operator: filter.operatorValue,
                    Value: filter.operatorValue === 'isAnyOf' ? filter?.value?.join(',') : filter.value,
                })
            );
        }
        if (baseFilters.length) {
            where.push(...baseFilters);
            if (where[0].Value == 'In Progress')
                where[0].Value = "1"
            else if (where[0].Value == 'Draft')
                where[0].Value = "0"
            else if (where[0].Value == 'Review')
                where[0].Value = "2"
            else if (where[0].Value == 'Complete')
                where[0].Value = "3"

        }
        if (where.length) {
            searchParams.set("where", JSON.stringify(where));
        }
        url = `${api}?${searchParams.toString()}`;

    }
    const authToken = localStorage.getItem("tokenValue");
    fetch(url, {
        headers: {
            'Authorization': `Bearer ${authToken ? authToken : ""}`
        }
    })
        .then(response => {

            if (response.status === 200) {
                response.json().then(responseData => {
                    const { value, recordCount } = responseData;
                    setActiveRecord({ id: value.surveyDataId, record: value, recordCount });

                });
            } else {
                console.log(response.body.toString());
                // logger.error('Could not load record', new Error(response.body.toString()));
            }
        })
        .catch(error => {
            console.log(error);
            // logger.error('Could not load record', error);
        })
        .finally(() => { });
};

const deleteRecord = async function ({ id, api }) {
    if (!id) {
        logger.error('Deleted failed. No active record.');
        return;
    }
    try {
        const authToken = localStorage.getItem("tokenValue");
        const response = await fetch(`${api}/id/?id=${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken ? authToken : ""}`
            }
        });
        if (response.status === 200) {
            return true;
        }
        logger.error('Deleted failed', response.body);
    } catch (error) {
        logger.error('Deleted failed', error);
    }
    return false;
};

const getAllRecords = async ({ api, id, setActiveRecord, filterModel, sortModel, baseFilters = [] }) => {
    let url;
    const searchParams = new URLSearchParams();

    if (sortModel?.length) {
        const sort = sortModel.map(sort => {
            return { sort: toTitleCase(sort.field), dir: sort.sort }
        });
        searchParams.set("sort", JSON.stringify(sort));
    }
    const where = [];
    if (filterModel?.items?.length) {
        filterModel.items.map(filter =>
            where.push({
                Field: toTitleCase(filter.columnField),
                Operator: filter.operatorValue,
                Value: filter.operatorValue === 'isAnyOf' ? filter?.value?.join(',') : filter.value
            })
        );
    }
    if (baseFilters.length) {
        where.push(...baseFilters);
    }
    if (where.length) {
        searchParams.set("where", JSON.stringify(where));
    }
    url = `${api}?${searchParams.toString()}`;
    const authToken = localStorage.getItem("tokenValue");
    fetch(url, {
        headers: {
            'Authorization': `Bearer ${authToken ? authToken : ""}`
        }
    })
        .then(response => {

            if (response.status === 200) {
                response.json().then(responseData => {
                    const { value, recordCount } = responseData;
                    setActiveRecord({ id: value.surveyDataId, record: value, recordCount });
                });
            } else {
                console.log(response.body.toString());
                // logger.error('Could not load record', new Error(response.body.toString()));
            }
        })
        .catch(error => {
            console.log(error);
            // logger.error('Could not load record', error);
        })
        .finally(() => { });

}


const exportRecord = async function ({ api, sortModel, setActiveRecord, filterModel, baseFilters = [], format }) {
    let url;
    url = `${api}/export`;
    const searchParams = new URLSearchParams();
    const timeOffset = -(new Date().getTimezoneOffset());
    searchParams.set("format", format);
    searchParams.set("timeOffset", timeOffset);
    if (sortModel?.length) {
        const sort = sortModel.map(sort => {
            return { sort: toTitleCase(sort.field), dir: sort.sort }
        });
        searchParams.set("sort", JSON.stringify(sort));
    }
    const where = [];
    if (filterModel?.items?.length) {
        filterModel.items.map(filter =>
            where.push({
                Field: toTitleCase(filter.columnField),
                Operator: filter.operatorValue,
                Value: filter.operatorValue === 'isAnyOf' ? filter?.value?.join(',') : filter.value
            })
        );
    }
    if (baseFilters.length) {
        where.push(...baseFilters);
    }
    if (where.length) {
        searchParams.set("where", JSON.stringify(where));
    }
    url = `${api}?${searchParams.toString()}`;

    const authToken = localStorage.getItem("tokenValue");
    fetch(url, {
        headers: {
            'Authorization': `Bearer ${authToken ? authToken : ""}`
        }
    })
        .then(response => {
            if (response.status === 200) {
                response.arrayBuffer().then(responseData => {
                    setActiveRecord(responseData)
                });
            } else {
                // logger.error('Could not load record', new Error(response.body.toString()));
            }
        })
        .catch(error => {
            //logger.error('Could not load record', error);
        })
        .finally(() => { });
}

const getAllUser = async ({ api, id, setActiveRecord }) => {
    const authToken = localStorage.getItem("tokenValue");
    fetch(api, {
        headers: {
            'Authorization': `Bearer ${authToken ? authToken : ""}`
        }
    }).then(response => {
        if (response.status === 200) {
            response.json().then(responseData => {
                setActiveRecord(responseData?.value);
            });
        } else {

        }
    })
    .catch(error => {
        console.log(error);
            // logger.error('Could not load record', error);
    })
    .finally(() => { });
}

const getReports = async ({ api, id, setActiveRecord, sortModel, page, pageSize, baseFilters, filterModel, setData }) => {
    const authToken = localStorage.getItem("tokenValue");
    if (sortModel && sortModel.length == 0) {
        sortModel = [{ field: "CreatedOn", sort: "desc" }];
    }
    const searchParams = new URLSearchParams();
    const where = [];
    if (filterModel?.items?.length) {
        filterModel.items.map(filter =>
            where.push({
                Field: toTitleCase(filter.columnField),
                Operator: filter.operatorValue,
                Value: filter.operatorValue === 'isAnyOf' ? filter?.value?.join(',') : filter.value,
            })
        );
    }
    if (sortModel?.length) {
        const sort = sortModel.map(sort => {
            return { sort: toTitleCase(sort.field), dir: sort.sort }
        });
        searchParams.set("sort", JSON.stringify(sort));
    }
    if (baseFilters?.length) {
        where.push(...baseFilters);
    }
    if (where.length) {
        searchParams.set("where", JSON.stringify(where));
    }
    const url = `${api}?${searchParams.toString()}`;
    fetch(url, {
        headers: {
            'Authorization': `Bearer ${authToken ? authToken : ""}`
        }
    }).then(response => {
        if (response.status === 200) {
            response.json().then(responseData => {
                const { value, recordCount } = responseData;
                setActiveRecord(responseData?.value);
            });
        } else {
            // logger.error('Could not load record', new Error(response.body.toString()));
            setActiveRecord(null);
        }
    })
        .catch(error => {
            console.log(error);
            // logger.error('Could not load record', error);
        })
        .finally(() => { });
}

export {
    saveRecord,
    updateRecord,
    getRecord,
    deleteRecord,
    exportRecord,
    getAllRecords,
    getAllUser,
    getReports
};