import React, { useState } from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

const Demo = (props) => {
    const { handleBaseFilter, wholeDataArray } = props;
    const [activeIndex, setActiveIndex] = useState(null);
    let labelArray = ['REHOST', 'REPLATFORM', 'REARCHITECT', 'REFACTOR', 'RETAIN/REHOST', 'RETAIN', 'RETIRE', 'REPLACE', 'REARCHITECT/REPURCHASE'];
    let array = [];
    const data = [];
    for (let i = 0; i < labelArray.length; i++) {
        array[i] = wholeDataArray.filter((ele) =>
            ele.overRideRFactorValue !== "" ? ele.overRideRFactorValue == labelArray[i] : ele.rFactorValue == labelArray[i]
        )
    }
    function convertToLowercaseExceptFirstCharacter(word) {
        var parts = word.split('/');
        for (var i = 0; i < parts.length; i++) {
            var currentPart = parts[i];
            parts[i] = currentPart.charAt(0).toUpperCase() + currentPart.slice(1).toLowerCase();
        }
        return parts.join('/');
    }
    for (let i = 0; i < array.length; i++) {
        const obj = {
            name: convertToLowercaseExceptFirstCharacter(labelArray[i]),
            value: array[i].length,
        };
        data.push(obj);
    }
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#0000FF', '#00008B', '#00FFFF', '#FF5733', '#c02485'];
    const DEFAULT_COLOR = '#CCCCCC';
    let value = 0;
    const handleClick = (data, index, e) => {
        setActiveIndex(index);
        let datas = localStorage.getItem('value');//used this method beacuse page reload on click
        if (index == datas) {
            handleBaseFilter([{ Field: 'IsArchived', Operator: 'equals', Value: 'false' }]);
            return;
        }
        value = localStorage.setItem('value', index);
        handleBaseFilter([{ Field: 'IsArchived', Operator: 'equals', Value: 'false' }, { Field: 'RFactorValue', Operator: 'equals', Value: data.name }, { Field: 'OverRideRFactorValue', Operator: 'equals', Value: data.name }]);
    }

    const formatData = (value) => {
        const total = data.reduce((sum, entry) => sum + entry.value, 0);
        const percentage = ((value / total) * 100).toFixed(2);
        return `${percentage}%`;
    };

    return (

        <PieChart width={800} height={450}>
            <Legend
                height={36}
                iconType="circle"
                layout="vertical"
                verticalAlign="Top"
                iconSize={15}
                padding={5}
                align='right'
                wrapperStyle={{ marginTop: '107px' }}
            />
            <Pie
                data={data}
                cx={300}
                cy={200}
                innerRadius={160}
                outerRadius={180}
                fill="#8884d8"
                paddingAngle={2}
                dataKey="value"
                onClick={handleClick}
            >
                {data.map((entry, index) => (
                    <Cell key={`cell-${index}`}
                        style={{ outline: 'none' }}
                        fontWeight={index === activeIndex ? 'bold' : 'normal'}
                        fill={entry.value ? COLORS[index % COLORS.length] : DEFAULT_COLOR} />
                ))}
            </Pie>
            <Tooltip formatter={(value) => formatData(value)} />
        </PieChart>
    );
}

export default Demo;
