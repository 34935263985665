import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { List, ListItem, ListItemText } from '@mui/material';
import help from '../json/help.json';
import MultipleTableComponent from './Table';
export const HelpModal = (props) => {
  const { modal, Toggle } = props;
  const result =
    useSelector((state) => {
      const { rFactorValues = {} } = state || {};
      return rFactorValues;
    }) || {};

  const { RfactorPredictor, ...others } = result;
  const handleClose = () => {
    Toggle();
  };

  const { textData, t1, t2, t3, t4, t5, t6, textData1, textData2, textData3, textData4 } = help
  const table1 = [
    {
      headers: ['#', 'NAME', 'DESCRIPTION'],
      rows: [
        [1, 'Moving To Cloud', 'This option should be chosen if the application needs to be moved to the cloud.'],
        [2, 'SaaS', 'This option should be chosen if the application is already a SaaS from a 3rd party provider.'],
        [3, 'Retain', 'This option should be chosen if the application is to be retained in its current state.'],
        [4, 'In The Cloud', 'This option should be chosen if the application is already on one of the public cloud providers.']
      ],
    },
  ];

  const data = [
    {
      id: 1,
      name: "REHOST",
      description:
        "Lift and shift…” / “Quick wins. Move application to target state without any change in the application’s code or architecture. It typically involves lifting and shifting the application. Re-host signifies that the application is stable on-premises and no further code updates are necessary to do a similar job in the target cloud.",
    },
    {
      id: 2,
      name: "REPLATFORM",
      description:
        "Lift, tweak, and shift. Changes to enable the application to use modern cloud technologies/services. Migrating an application or workload to a new platform, such as from a legacy system to a modern, cloud-native platform, while making some changes to the application’s underlying architecture. Replatform is an upgrade path to the application for target cloud supportability and to reduce risks. This path is chosen often when the licensing solution of a database or operating system is very expensive to operate in the cloud.",
    },
    {
      id: 3,
      name: "REARCHITECT",
      description:
        "Rewrite and rearchitect application with native cloud-native language. Re-designing the entire architecture of an application or system to meet new business or technical requirements. The application is built from the ground up on the public cloud.",
    },
    {
      id: 4,
      name: "REFACTOR",
      description:
        "Significant code changes required for the application migration. Making significant changes to an application’s underlying architecture, such as breaking it down into microservices, to improve its scalability, maintainability, and overall performance.",
    },
    {
      id: 5,
      name: "RETAIN/REHOST",
      description:
        "Lift and shift Used when code modification is impossible and the workload is not certified for the cloud, or the data center lease is expiring, and a quick migration is needed. Because the platform is still owned by the organization rehosting cannot take advantage of cloud-native features such as monitoring security and governance.",
    },
    {
      id: 6,
      name: "RETAIN",
      description: "Will not migrate",
    },
    {
      id: 7,
      name: "RETIRE",
      description: "No longer useful and are not being replaced",
    },
    {
      id: 8,
      name: "REPLACE",
      description: "Move to a different product/service or Assets that are no longer useful and are not being replaced or Will not migrate. The application is replaced by a comparable software as a service (SaaS) equivalent example include Salesforce and ServiceNow.",
    },
  ];

  const data1 = [
    {
      headers: ['#', 'NAME', 'DESCRIPTION'],
      rows: [
        [data[0].id, data[0].name, data[0].description],
        [data[1].id, data[1].name, data[1].description],
        [data[2].id, data[2].name, data[2].description],
        [data[3].id, data[3].name, data[3].description],
        [data[4].id, data[4].name, data[4].description],
        [data[5].id, data[5].name, data[5].description],
        [data[6].id, data[6].name, data[6].description],
        [data[7].id, data[7].name, data[7].description]
      ],
    },
  ];
  const datas = [
    {
      id: 1,
      name: "Draft",
      description:
        "An assessment has been started, but it is at a preliminary stage.",
    },
    {
      id: 2,
      name: "In Progress",
      description:
        "The assessment has been started but has not yet been reviewed or completed.",
    },
    {
      id: 3,
      name: "Review",
      description:
        "Assessments with this status require further review before they can be marked as completed.",
    },
    {
      id: 4,
      name: "Complete",
      description:
        "An assessment with this status indicates it has been completed and there is no need for further action.",
    },
  ];


  const data2 = [
    {
      headers: ['#', 'NAME', 'DESCRIPTION'],
      rows: [
        [datas[0].id, datas[0].name, datas[0].description],
        [datas[1].id, datas[1].name, datas[1].description],
        [datas[2].id, datas[2].name, datas[2].description],
        [datas[3].id, datas[3].name, datas[3].description],
      ],
    },
  ];

  const listItems = [
    { primary: 'Pipeline Driven - DevOps (CI/CD) Migration' },
    { primary: 'Containerize to package for migration (Kubernetes)' },
    { primary: 'Fresh Reinstall at Target' },
    { primary: 'DB Replication example, Azure Site Recovery Manager (ASR)' },
    { primary: 'Block-Level Replication example, Zerto' },
    { primary: 'Native Cloud Tools for migration etc.' },
    { primary: 'Replace with Cloud Native Service' },
    { primary: 'Others…' },
  ];

  return (
    <Dialog open={modal} size="lg" toggle={Toggle}>
      <DialogTitle toggle={Toggle}>Help</DialogTitle>
      <DialogContent>
        <Box>
          <div>            
              <Typography variant="h7" component="h6" mt={2}>
                {t1}
              </Typography>
              <Typography variant="body2">
                {textData4}
              </Typography>
              <div style={{ marginTop: '10px' }} />
              <Typography variant="h7" component="h6" mt={2}>
                {t2}
              </Typography>
              <Typography variant="body2" mb={1}>
                {textData1}
              </Typography>
              <div>
                <MultipleTableComponent stickyHeader aria-label="sticky table" tables={table1} />
              </div>
              <div style={{ marginTop: '10px' }} />
              <Typography variant="h7" component="h6" mt={2}>
                {t3}
              </Typography>
              <Typography variant="body2" mb={1}>
                {textData}
              </Typography>              
              <MultipleTableComponent tables={data1} />
              <div style={{ marginTop: '10px' }} />
              <Typography variant="h7" component="h6" mt={2}>
                {t4}
              </Typography>
              <Typography variant="body2">
                {textData2}
              </Typography>
              <div style={{ marginTop: '10px' }} />
              <Typography variant="h7" component="h6" mt={2}>
                {t5}
              </Typography>
              <Typography variant="body2" mb={1}>The four stages in the assessment lifecycle, as follows:</Typography>
              <MultipleTableComponent tables={data2} />
              <div style={{ marginTop: '10px' }} />
              <Typography variant="h7" component="h6" mt={2}>
                {t6}
              </Typography>
              <Typography variant="body2">
                {textData3}
              </Typography>

              <List>
                {listItems.map((item, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={item.primary} />
                  </ListItem>
                ))}
              </List>           
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
      <Button  onClick={handleClose}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
};