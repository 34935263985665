import { Navigate } from "react-router-dom"
import ParseJwtToken from "../components/jwtToken"

export const PrivateRoute = ({children})=>{
    
    const tokenValue  =  localStorage.getItem('tokenValue')
    if(tokenValue){
        const userId =  localStorage.getItem("userId")
        const Decode =  ParseJwtToken(tokenValue)
        return  Decode.UserId === userId ? children : <Navigate to="/" /> 
    }
    
    return <Navigate to="/" />
}