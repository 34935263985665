export const actionType = {
  LOGGED_USER: 'LOGGED_USER',
  RFACTOR_VALUES: 'RFACTOR_VALUES',
  GETALLPROJECT_DATA: 'GETALLPROJECT_DATA',
  Default_Selected_Project: "Default_Selected_Project",
  Default_Project_Id: "Default_Selected_Project_Id",
  Loading_Action: "Toggle_Loading",
  Snackbar_Action: "Snackbar_loading",
  AddProject_Action: "AddProject_Actions",
  Loading: 'Loader',
  Active: 'TurnActive'
};

export function loggedUser(value) {
  return {
    type: actionType.LOGGED_USER,
    value,
  };
}

export function rFactorValues(value) {
  return {
    type: actionType.RFACTOR_VALUES,
    value,
  };
}

export function ProjectData(value) {
  return {
    type: actionType.GETALLPROJECT_DATA,
    value,
  }
}

export function SelectedProject(value) {
  return {
    type: actionType.Default_Selected_Project,
    value,
  }
}

export function SelectedProjectId(value) {
  return {
    type: actionType.Default_Project_Id,
    value,
  }
}


export function loadingToggleAction(value) {
  return {
    type: actionType.Loading_Action,
    value,
  }
}

export function SnackBar(value) {
  return {
    type: actionType.Snackbar_Action,
    value
  }
}

export function setLoader(value) {
  return {
    type: actionType.Loading,
    value
  }
}

export function AddProject(value) {
  return {
    type: actionType.AddProject_Action,
    value
  }
}


export function AutoCompleteAction(value) {
  return {
    type: actionType.Active,
    value,
  }
}