import React, { useState } from 'react';
import { useFormik } from 'formik';
import { Grid, Typography, Tabs, Tab, Fade, Box } from '@material-ui/core';
import Button from "@mui/material/Button";
import useStyles from '../styles';
import { useNavigate } from 'react-router-dom';
import { saveRecord } from '../redux/httpUtil/crud-helper';
import ParseJwtToken from '../components/jwtToken';
import config from "../config";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { SnackBar } from '../redux/actions/action';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from '@mui/material/Alert';
import logo from "../assets/logo.png";
import logo1 from "../assets/logo9.png";
import * as Yup from 'yup';
import { CustomLoader } from '../components/CustomLoader';
import GoogleAuth from '../components/GoogleAuth';
import RegisterWithGoogle from '../components/GoogleRegister';
import GoogleAsLogin from '../components/Google';

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Login = () => {
	const dispatch = useDispatch();
	var classes = useStyles()
	const navigate = useNavigate()
	var [error, setError] = useState(null)
	var [activeTabId, setActiveTabId] = useState(0)
	const [open, setOpen] = useState(false);
	const [open1, setOpen1] = useState(false);
	const [button2, setButton2] = useState(true);
	const [isLoading, setLoader] = useState(false);

	const items = useSelector(state => state.SnackBarValue);
	const TextFieldSx = {
		'& .MuiInputLabel-asterisk': { color: 'error.main', fontWeight: 'bold' }
	}

	const validationSchema = Yup.object().shape({
		loginEmail: Yup.string().required('This field is required'),
		loginPassword: Yup.string().required('This field is required'),
	});
	const validationSchema1 = Yup.object().shape({
		firstName: Yup.string().required('This field is required'),
		lastName: Yup.string().required('This field is required'),
		signupEmail: Yup.string().required('This field is required'),
		signupPassword: Yup.string().required('This field is required'),
	});

	const fetchData = async (data) => {
		const response = await fetch(`${config.api_url}/User/forgetPassword?email=${data}`)
		const result = await response.json();
		if (result.message == 'Email Sent to User') {
			setOpen(true);
		}
		else {
			setOpen1(true);
		}

	}


	const handleClose = () => {
		setOpen(false);
	};
	const handleClose1 = () => {
		setOpen1(false);
	};

	const initialValues = {
		signupEmail: '',
		loginEmail: '',
		loginPassword: '',
		signupPassword: '',
		firstName: '',
		lastName: ''
	}

	const handleClosed = () => {
		dispatch(SnackBar({ open: 'false', message: '' }));
	}

	const formik = useFormik({
		initialValues,
		validationSchema: validationSchema1,
		onSubmit: (values, { setSubmitting, resetForm }) => {
			setLoader(true);
			const formData = {}
			formData.email = values.signupEmail
			formData.password = values.signupPassword
			formData.firstName = values.firstName
			formData.lastName = values.lastName
			saveRecord({
				id: null,
				api: `${config.api_url}/User/create`,
				data: formData,
				setActiveRecord: res => {
					setLoader(false);
					if (res.isSuccess) {
						dispatch(SnackBar({ open: 'true', message: 'User signed up sucessfully' }));
					} else {
						dispatch(SnackBar({ open: 'true', message: `${res.message}` }));
					}
					setActiveTabId(0);
				}
			})
			resetForm()
		}
	})

	const formik1 = useFormik({
		initialValues,
		validationSchema: validationSchema,
		onSubmit: (values, { resetForm }) => {
			const formData = {};
			if (values.button === 'cancel') {
				resetForm();
			} else {
				setLoader(true);
				if (activeTabId === 0) {
					formData.email = values.loginEmail
					formData.password = values.loginPassword
					saveRecord({
						id: null,
						api: `${config.api_url}/User/login`,
						data: formData,
						setActiveRecord: res => {
							setLoader(false);
							if (res.isSuccess) {
								localStorage.setItem('tokenValue', res.value)
								localStorage.setItem('ArchiveBy', values.loginEmail)
								localStorage.setItem('IsLoggedIn', true)
								const Decode = ParseJwtToken(res.value)
								localStorage.setItem('userId', Decode.UserId)
								setTimeout(() => {
									navigate('/dashboard')
								}, 500)
							} else {
								if (res.error === 'Password is incorrect') {
									dispatch(SnackBar({ open: 'true', message: 'Incorrect Password' }));
								} else if (res.error === 'User is not approved. Please contact admin') {
									dispatch(SnackBar({ open: 'true', message: 'You are not authorized' }));
								} else {
									dispatch(SnackBar({ open: 'true', message: res.error }));
								}
							}
						}
					})
					// }
				}
				resetForm()
			}

		}
	})

	return (
		<>
			<Snackbar
				open={items.open == 'false' ? false : items.open} autoHideDuration={3000} onClose={handleClosed} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
				<Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
					{items.message}
				</Alert>
			</Snackbar>
			<Grid container className={classes.container}>
				<div className={classes.logotypeContainer} style={{ backgroundImage: `url(${logo1})`, backgroundSize: "cover" }}>
				</div>
				<div className={classes.formContainer}>
					<Box>
						<img src={logo} height={100} width={180} alt="logo" />
					</Box>
					<Box>
						<CustomLoader open={isLoading} style={{ position: 'relative', top: '50%', zIndex: 1000 }} />
						<div className={classes.form}>
							<Tabs
								value={activeTabId}
								onChange={(e, id) => setActiveTabId(id)}
								indicatorColor='primary'
								textColor='primary'
								centered
							>
								<Tab label='Login' />
								<Tab label='Register' />
							</Tabs>
							{activeTabId === 0 && (
								<React.Fragment>
									<Fade in={error}>
										<Typography color='secondary' className={classes.errorMessage}>
											Something is wrong with your login or password :(
										</Typography>
									</Fade>
									<form onSubmit={formik1.handleSubmit}>
										<Grid item p={1} xs={12}>
											<TextField p={1} id='loginEmail' type='email' placeholder='Email Address' sx={TextFieldSx} onChange={formik1.handleChange} value={formik1.values.loginEmail} maxLength="50" autoComplete='off' label="Email" margin='normal' className={formik1.errors.loginEmail && formik1.touched.loginEmail ? 'error' : ''} fullWidth />
										</Grid>
										{formik1.touched.loginEmail && formik1.errors.loginEmail && (
											<div className={classes.error}>{formik1.errors.loginEmail}</div>
										)}
										<Grid item p={1} xs={12}>
											<TextField p={1} id='loginPassword' type='password' placeholder='Password' sx={TextFieldSx} onChange={formik1.handleChange} value={formik1.values.loginPassword} maxLength="50" autoComplete='off' label="Password" margin='normal' fullWidth />
										</Grid>
										{formik1.touched.loginPassword && formik1.errors.loginPassword && (
											<div className={classes.error}>{formik1.errors.loginPassword}</div>
										)}
										<Button
											variant="contained"
											size='large'
											color='primary'
											fullWidth
											sx={{ mt: 1 }}
											className={classes.createAccountButton}
											type='submit'
											disabled={formik.isSubmitting}
											onClick={() => {
												formik.setFieldValue('button', 'submit')
											}}
										>
											LOGIN
										</Button>
										<div>
											<Button
												variant="contained"
												color='primary'
												size='large'
												type='submit'
												sx={{ mt: 1 }}
												fullWidth
												className={classes.forgetButton}
												disabled={formik.isSubmitting}
												onClick={() => {
													setButton2(false);
													formik1.setFieldValue('button', 'cancel')
													let data = formik1.values.loginEmail
														fetchData(data);
												}}
											>
												FORGOT PASSWORD
											</Button>
											
											<Dialog open={open} onClose={handleClose}>
												<DialogContent>
													<DialogContentText sx={{ display: 'flex', justifyContent: 'center' }} >
														Password resetting instructions has been sent to your
														<br></br>
														primary email address.
													</DialogContentText>
												</DialogContent>
												<DialogActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
													<Button onClick={handleClose}>Ok</Button>
												</DialogActions>
											</Dialog>
											<Dialog open={open1} onClose={handleClose1}>
												<DialogContent>
													<DialogContentText sx={{ display: 'flex', justifyContent: 'center' }} >
														No valid users found that match specified information
													</DialogContentText>
												</DialogContent>
												<DialogActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
													<Button size='small'
														variant='contained'
														color='primary'
														onClick={handleClose1}>Ok</Button>
												</DialogActions>
											</Dialog>
										</div>
									</form>
									<div className="signup-riht-container">
												<div className="sign-up-box"></div>
												<div className="blue-border-box">
													<br></br>
													<GoogleAuth children={<GoogleAsLogin/>} />
												</div>
											</div>
								</React.Fragment>
							)}
							{activeTabId === 1 && (
								<React.Fragment>
									<Fade in={error}>
										<Typography color='secondary' className={classes.errorMessage}>
											Something is wrong with your login or password :(
										</Typography>
									</Fade>
									<form onSubmit={formik.handleSubmit}>
										<Grid item p={1} xs={12}>
											<TextField p={1} id='firstName' type='text' placeholder='First Name' sx={TextFieldSx} onChange={formik.handleChange} value={formik.values.firstName} maxLength="50" autoComplete='off' label="First Name" margin='normal' fullWidth />
										</Grid>
										{formik.touched.firstName && formik.errors.firstName && (
											<div className={classes.error}>{formik.errors.firstName}</div>
										)}
										<Grid item p={1} xs={12}>
											<TextField p={1} id='lastName' type='text' placeholder='Last Name' sx={TextFieldSx} onChange={formik.handleChange} value={formik.values.lastName} maxLength="50" autoComplete='off' label="Last Name" margin='normal' fullWidth />
										</Grid>
										{formik.touched.lastName && formik.errors.lastName && (
											<div className={classes.error}>{formik.errors.lastName}</div>
										)}
										<Grid item p={1} xs={12}>
											<TextField p={1} id='signupEmail' type='email' placeholder='Email Address' sx={TextFieldSx} onChange={formik.handleChange} value={formik.values.signupEmail} maxLength="50" autoComplete='off' label="Email Address" margin='normal' fullWidth />
										</Grid>
										{formik.touched.signupEmail && formik.errors.signupEmail && (
											<div className={classes.error}>{formik.errors.signupEmail}</div>
										)}
										<Grid item p={1} xs={12}>
											<TextField p={1} id='signupPassword' type='password' placeholder='Password' sx={TextFieldSx} onChange={formik.handleChange} value={formik.values.signupPassword} maxLength="50" autoComplete='off' label="Password" margin='normal' fullWidth />
										</Grid>
										{formik.touched.signupPassword && formik.errors.signupPassword && (
											<div className={classes.error}>{formik.errors.signupPassword}</div>
										)}
										<Button
											variant="contained"
											color='primary'
											size='large'
											type='submit'
											sx={{ mt: 1 }}
											fullWidth
											className={classes.createAccountButton}
										>
											REGISTER
										</Button>
									</form>
									<div className="signup-riht-container">
											<div className="sign-up-box"></div>
											<div className="blue-border-box">
												<br></br>
												<GoogleAuth  children={<RegisterWithGoogle />} />
											</div>
										</div>
								</React.Fragment>
							)}
						</div>
						<Typography color='primary' className={classes.copyright}>
							© 2014-{new Date().getFullYear()}{' '}
							<a
								style={{ textDecoration: 'none', color: 'inherit' }}
								href='https://www.leapxgroup.com'
								rel='noopener noreferrer'
								target='_blank'
							>
								LEAPX Group
							</a>
							&nbsp;All rights reserved.
						</Typography>
					</Box>
				</div>


			</Grid>
		</>

	)
}

export default Login