const menuItems = [
    {
        path: 'dashboard', text: 'Dashboard', icon: 'dashboard', hasChildren: false
    },
    {
        path: 'projects', text: 'Projects', icon: 'projectsIcon', hasChildren: false
    },
    {
        path: 'assessments', text: 'Assessments', icon: 'assignmentIcon', hasChildren: false
    },
    {
        path: '', text: 'Reports', icon: 'assignmentIcon', hasChildren: true,
        children: [{
            path: '/devops',
            text: 'DevOps Opportunity',
            icon:'devopsIcon'
        },
        {
            path: '/containers',
            text: 'Containers Opportunity',
            icon:'conatinerIcon'
        }, {
            path: '/transformation',
            text: 'App.Transformation Opportunity',
            icon:'transformationIcon'
        },
        {
            path: '/modern',
            text: 'App.Modernization Opportunity',
            icon:'modernizationIcon'
        }]
    }
]

export default menuItems;