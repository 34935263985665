export default {
  questionnaireFields: {
    Criticality: '',
    RateofChange: '',
    StrategicValue: '',
    IntegrationCount: '',
    ArchModernTechStack: '',
    RiskFactors: '',
    UnderstandingofSystem: '',
    LatencyRequirement: '',
    PlatformDependencies: '',
    MoveUrgency: '',
    DBComplexity: '',
    SaaSbased: '',
    SaaSOffering: '',
    Security: '',
    DataSecurity: '',
    HighAvailability: '',
    SurveyTitle:''
  },
  userId: '057dc4f7-e666-4ac2-8e97-0f61a4545e15',
  dateFormat: {
    Date: 'mm/DD/YYYY',
    DateTime: 'MM/DD/YYYY HH:mm ',
    Timr: 'HH:mm'
  },
  ToLowerCse: function convertToLowercaseExceptFirstCharacter(word) {
    var parts = word.split('/');
    for (var i = 0; i < parts.length; i++) {
      var currentPart = parts[i];
      parts[i] = currentPart.charAt(0).toUpperCase() + currentPart.slice(1).toLowerCase();
    }
    return parts.join('/');
  },
};
