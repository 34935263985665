import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';

const MultipleTableComponent = ({ tables }) => {
  return (
    <div>
      {tables.map((table, index) => (
        <Table key={index}  stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {table.headers.map((header, headerIndex) => (
                <TableCell key={headerIndex}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {table.rows.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <TableCell key={cellIndex}>{cell}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ))}
    </div>
  );
};

export default MultipleTableComponent;
