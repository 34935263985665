import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ParseJwtToken from "../components/jwtToken";
import { FormHelperText, DialogTitle } from '@material-ui/core';
import Button from '@mui/material/Button';
import { Dialog, DialogActions, DialogContent, Grid, Typography, Tooltip, Box } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useFormik } from "formik";
import OutlinedInput from '@mui/material/OutlinedInput';
import { saveRecord, deleteRecord, getAllRecords } from "../redux/httpUtil/crud-helper";
import Alert from "../components/Modal";
import { rFactorValues, SnackBar } from "../redux/actions/action";
import { useNavigate, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import project from "../json/project.json";
import { FetechAllProjectName } from "../redux/httpUtil/fetchAllproject";
import { validationSchema } from "./formik/validationSchema";
import { ResultModal } from "./ResultModal";
import config from '../config';
const key = ["assessment", "Assessments"];

class FieldMappers {

  textarea = ({ index, item, options, formik }) => {
    return (
      <FormControl fullWidth>
        <Typography for={item.value} mb={1} mt={1}>
          {index + ". " + item.label}
        </Typography>
        <OutlinedInput
          type="textarea"
          name={item.name}
          id={item.value}
          placeholder={item.label}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          invalid={formik?.errors[item.name]}
          value={formik?.values[item.name]}
        />
        <FormHelperText error
          invalid={formik?.errors[item.name]}
        >
          {formik?.errors[item.name]}
        </FormHelperText>
      </FormControl>
    );
  };
  checkbox = ({ index, item, options, formik }) => (
    <FormControl>
      <Typography for={item.value}>
        {index + ". " + item.label}
      </Typography>
      {(options?.length > 0 &&
        options.map((data) => {
          return (

            <Grid>
              <Typography check>
                <OutlinedInput
                  type="checkbox"
                  name={item.name}
                  id={item.value}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={formik?.errors[item.name]}
                  value={formik?.values[item.name]}
                />{" "}
                {data.label}
              </Typography>
            </Grid>
          );
        })) || (
          <Typography>
            <OutlinedInput
              type="checkbox"
              name={item.name}
              id={item.value}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={formik?.errors[item.name]}
              value={formik?.values[item.name]}
            />{" "}
            {item.label}
          </Typography>
        )}
      <FormHelperText invalid={formik?.errors[item.name]}>
        {formik?.errors[item.name]}
      </FormHelperText>
    </FormControl>
  );
  select = ({ index, item, options, formik, isDisable }) => {
    return (
      <FormControl fullWidth>
        <Typography for={item.value} mb={1} mt={1}>
          {index + ". " + item.label}
        </Typography>

        <Select
          disableUnderline
          name={item.name}
          id={item.value}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          invalid={formik?.errors[item.name]}

          value={formik?.values[item.name] || " "}
          disabled={isDisable}
        >
          {options.map((data) => {
            return (
              <MenuItem key={data.label} value={data.value}>
                {data.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };
  email = ({ index, item, options, formik }) => {
    return (
      <FormControl>
        <Typography for={item.value} >
          {index + ". " + item.label}
        </Typography>
        <OutlinedInput
          type="email"
          name={item.name}
          id={item.value}
          placeholder={item.label}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          invalid={formik?.errors[item.name]}
          value={formik?.values[item.name]}
        />
        <FormHelperText invalid={formik?.errors[item.name]} error>
          {formik?.errors[item.name]}
        </FormHelperText>
      </FormControl>
    );
  };
  number = ({ index, item, options, formik }) => {
    return (
      <FormControl>
        <Typography for={item.value} >
          {index + ". " + item.label}
        </Typography>
        <OutlinedInput
          type="number"
          name={item.name}
          id={item.value}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          invalid={formik?.errors[item.name]}
          value={formik?.values[item.name]}
        />
        <FormHelperText invalid={formik?.errors[item.name]} error>
          {formik?.errors[item.name]}
        </FormHelperText>
      </FormControl>
    );
  };
  string = ({ index, item, options, formik }) => {
    return (
      <FormControl fullWidth>
        <Typography for={item.value} mb={1} mt={1}>
          {index + ". " + item.label}
        </Typography>
        <OutlinedInput
          type="text"
          name={item.name}
          id={item.value}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          invalid={formik?.errors[item.name]}
          value={formik?.values[item.name]}
        />
        <FormHelperText error
          invalid={formik?.errors[item.name]}
        >
          {formik?.errors[item.name]}
        </FormHelperText>
      </FormControl>
    );
  };
  password = ({ index, item, options, formik }) => {
    return (
      <FormControl>
        <Typography for={item.value} >
          {index + ". " + item.label}
        </Typography>
        <OutlinedInput
          type="password"
          name={item.name}
          id={item.value}
          placeholder={item.label}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          invalid={formik?.errors[item.name]}
          value={formik?.values[item.name]}
        />
        <FormHelperText invalid={formik?.errors[item.name]} error>
          {formik?.errors[item.name]}
        </FormHelperText>
      </FormControl>
    );
  };
  date = ({ index, item, options, formik }) => {
    <FormControl>
      <Typography for={item.value} >
        {index + ". " + item.label}
      </Typography>
      <OutlinedInput
        type="date"
        name={item.name}
        id={item.value}
        placeholder={item.label}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        invalid={formik?.errors[item.name]}
        value={formik?.values[item.name]}
      />
      <FormHelperText invalid={formik?.errors[item.name]} error>
        {formik?.errors[item.name]}
      </FormHelperText>
    </FormControl>;
  };
  radio = ({ index, item, options, formik }) => {
    return (
      <FormControl sx={{ width: "100%" }}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography sx={{ mt: 2.6 }} >
              {index + ". " + item.Criteria}
            </Typography>
          </Grid >
          <Grid item xs={9}>
            <Typography sx={{ mt: 3 }}>{item.label}</Typography>
            <Box >
              <RadioGroup name={item.name}>
                {options.map((data, i) => {
                  return (
                    <FormControl>
                      <Tooltip title={data.tooltip_label} placement="left" arrow enterDelay={500}>
                        <FormControlLabel value={data.value} key={data.label} control={<Radio onChange={formik.handleChange} checked={data.value == formik?.values[item.name]} />} label={data.label} />
                      </Tooltip>
                    </FormControl>
                  );
                })}
              </RadioGroup>
              <FormHelperText invalid={formik?.errors[item.name]} error>
                {formik?.errors[item.name]}
              </FormHelperText>
            </Box>
          </Grid>
        </Grid>
      </FormControl>
    );
  };
}

const fieldMappers = new FieldMappers();

const FormBase = (props) => {
  const { formContents, fields, activeRecord, access } = props;
  const { assessmentId, projectId } = useParams();
  const { state } = useLocation();
  const from = state?.from || "Assessments";
  const [isDisable, setIsDisable] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [modalProps, setModalProps] = useState({ title: "", value: "" });
  const [formFields, setFormFields] = useState(formContents.data);
  const [values, setValues] = React.useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ProjectList = useSelector((state) => state);

  const tokenValue = localStorage.getItem("tokenValue");
  let hasEditAccess = false;
  const userId = localStorage.getItem("userId");

  if (tokenValue) {
    const decodedToken = ParseJwtToken(tokenValue);
    const userRole = decodedToken.UserRole;
    const currentProjectId = localStorage.getItem("projectId");
    // check if user has admin privileges
    hasEditAccess = userRole && userRole.includes("SuperAdmin");

    if (!hasEditAccess && ProjectList && ProjectList.allProjectNames) {
      const selectedProject = ProjectList.allProjectNames.find(a => a.id === currentProjectId);
      if (selectedProject) {
        if (selectedProject.permittedUsers) {
          const permittedUser = selectedProject.permittedUsers.find(a => a.userId === userId);
          if (permittedUser) {
            hasEditAccess = permittedUser.accessId === 1;//TODO create enum for 
          }
        }
      }
    }
  }

  const { selectProjectValues, allProjectNames } = ProjectList;

  const [ProjectOption, setProjectOption] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [resultModal, setResultModal] = useState(false);
  const toggleDelete = () => setDeleteModal(!deleteModal);
  const toggleResult = () => setResultModal(!resultModal);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [isDraft, setIsDraft] = useState(false);

  useEffect(() => {
    dispatch(FetechAllProjectName(false));
  }, []);
  React.useEffect(() => {
    if (allProjectNames && assessmentId == "0") {
      let arr = [];
      allProjectNames?.map((e) => {
        arr.push({ label: e.name == "All" ? "select your project" : e.name, value: e.name == "All" ? "" : e.id });
      });
      setProjectOption(arr);
    }

    if (assessmentId != "0") {
      const baseFilters = [];
      const sortModel = [];
      let arr = [];

      getAllRecords({
        id: null,
        api: `${config.api_url}/${project.api}`,
        setActiveRecord: (res) => {
          if (res) {
            const Data = res?.record;
            {
              Data?.map((e) => {
                let obj = {};
                obj.label = e.name;
                obj.value = e.id;

                arr.push(obj);
              });
            }
            setProjectOption(arr);
          }
        },
        sortModel,
        baseFilters
      });
    }
  }, [allProjectNames, assessmentId]);

  const onCancelClick = () => {
    navigate(key.includes(from) ? "/assessments" : "/dashboard");
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...values, ...fields, ...activeRecord?.formValues },
    validationSchema: isDraft ? null : validationSchema(formFields),
    onSubmit: (values, { resetForm }) => {
      let formValues = { ...values },
        data = [],
        formData = {};
      if (formValues && formValues.SurveyTitle != '') {//do not submit the form if the title is empty
        if (!values["Status"]) {
          values["Status"] = "Moving To Cloud";
        }
        if (!values["assessmentStatusId"]) {
          values["assessmentStatusId"] = 1;
        }
        if (isDraft) {
          values["assessmentStatusId"] = 0;
          values["rFactorValue"] = "";
          formData.RfactorPredictor = "";
          delete formValues["RFactorValue"];
          delete formValues["Rehost"];
          delete formValues["Replatform"];
          setResultModal(false);
        }
        for (let key in values) {
          for (let i = 0; i < formFields.length; i++) {
            if (formFields[i].name == key) {
              if (
                key != "SurveyTitle" &&
                key != "Status" &&
                key !== "Comment" &&
                key !== "assessmentStatusId" &&
                key !== "ProjectId" &&
                key !== "ToolStrategy"
              ) {
                if (
                  values["Status"] != "Moving To Cloud" &&
                  formValues["Comment"] != ""
                ) {
                  formValues[key] = {
                    value: 0,
                    excelMapping: formFields[i].excelMapping,
                  };
                  data.push({
                    FieldName: key,
                    Value: 0,
                    ExcelMapping: formFields[i].excelMapping,
                  });
                } else {
                  formValues[key] = {
                    value: values[key],
                    excelMapping: formFields[i].excelMapping,
                  };
                  data.push({
                    FieldName: key,
                    Value: values[key],
                    ExcelMapping: formFields[i].excelMapping,
                  });
                }
              } else if (key === "SurveyTitle") {
                formData.SurveyTitle = formValues[key];
                delete formValues[key];
              } else if (key === "Status") {
                formData.Status = formValues[key];
                delete formValues[key];
              } else if (key === "Comment") {
                formData.Comment = formValues[key];
                delete formValues[key];
              } else if (key === "assessmentStatusId") {
                formData.assessmentStatusId = formValues[key];
                delete formValues[key];
              } else if (key === "ProjectId") {
                formData.ProjectId = formValues[key];
                delete formValues[key];
              }
            }
            if (key === "ToolStrategy") {
              formData.ToolStrategy = values.ToolStrategy;
            }
          }
          setIsDisable(true);
          localStorage.setItem("IsLoggedIn", false);
        }

        formData.CreatedByUserId = localStorage.getItem("userId");
        formData = {
          ...formData,
          FormFields: JSON.stringify(formValues),
          RFactorValue: "",
        };
        if (!formData["Status"]) {
          formData.Status = "Moving To Cloud";
        }

        if (!formData["assessmentStatusId"]) {
          formData["assessmentStatusId"] = 1;
        }

        if (isDraft) {
          formData["assessmentStatusId"] = 0;
          formData["rFactorValue"] = "";
          formData.RfactorPredictor = "";
          delete formValues["rFactorValue"];
          delete formValues["Rehost"];
          delete formValues["Replatform"];
        }

        saveRecord({
          id: activeRecord?.id,
          api: `${config.api_url}/${formContents?.api}`,
          data: formData,
          setActiveRecord: (res) => {
            if (res && res.isSuccess && res.value.status === "Moving To Cloud") {
              saveRecord({
                id: null,
                api: `${config.api_url}/SurveyData/readexcel/`,
                data,
                setActiveRecord: (response) => {
                  if (response.isSuccess) {
                    const values = response.value;
                    saveRecord({
                      id: res.value.id,
                      api: `${config.api_url}/${formContents?.api}`,
                      data: {
                        ...formData,
                        RFactorValue: isDraft ? "" : values.rfactorPredictor,
                        Rehost: isDraft
                          ? ""
                          : Number(Number(values.rehost).toFixed(4)),
                        Replatform: isDraft
                          ? ""
                          : Number(Number(values.replatform).toFixed(4)),
                      },
                    });
                    dispatch(
                      rFactorValues({
                        Rehost: isDraft ? "" : Number(values.rehost).toFixed(4),
                        Replatform: isDraft
                          ? ""
                          : Number(values.replatform).toFixed(4),
                        Refactor: isDraft
                          ? ""
                          : Number(values.refactor).toFixed(4),
                        Repurchase: isDraft
                          ? ""
                          : Number(values.repurchase).toFixed(4),
                        RfactorPredictor: isDraft ? "" : values.rfactorPredictor,
                      })
                    );
                    if (isDraft) {
                      setResultModal(false);
                      if ((formData["Status"] = "Retain")) {
                        formData["rFactorValue"] = "";
                      }

                      if ((formData["Status"] = "Retire")) {
                        formData["rFactorValue"] = "";
                      }

                      if ((formData["Status"] = "SaaS")) {
                        formData["rFactorValue"] = "";
                      }
                      formData["rFactorValue"] = "";
                      formData.RfactorPredictor = "";
                      delete formValues["rFactorValue"];
                      delete formValues["Rehost"];
                      delete formValues["Replatform"];
                      navigate("/assessments");
                    } else {
                      setResultModal(true);
                    }
                  }
                },
              });
            } else if (
              res &&
              !res.isSuccess &&
              res.message ===
              "An assessment with the same name already exists in this project"
            ) {
              dispatch(SnackBar({ open: 'true', message: res.message }));
              setIsDisable(false);
            } else {
              onCancelClick();
            }
          },
        });
      }
    },
  });

  const updateProject = () => { };
  useEffect(() => {
    formik.submitForm();
  }, [isDraft])
  const UpdateModal = (
    <Dialog isOpen={modal} toggle={toggle}>
      <DialogTitle toggle={toggle}>Update Assessment ?</DialogTitle>
      <DialogContent>
        <p>This Assessment will be Updated</p>
        <FormControl
        >
          <Button
            color='error'
            onClick={onCancelClick}
          >
            Cancel
          </Button>
          <Button
            onClick={updateProject}>
            Update
          </Button>
        </FormControl>
      </DialogContent>
    </Dialog>
  );

  const onDeleteClick = () => {
    deleteRecord({ id: activeRecord?.id, api: `${config.api_url}/${formContents?.api}` }).then(
      (success) => {
        if (success) {
          onCancelClick();
        }
      }
    );
    dispatch(SnackBar({ open: 'true', message: 'Assessment Deleted Successfully' }));
  };

  const closeModal = () => {
    onCancelClick();
  };

  useEffect(() => {
    if (
      !formik?.values?.Status ||
      formik?.values?.Status === "Moving To Cloud"
    ) {
      const filteredFields = formContents.data.filter(
        (item) => item.label !== "Comment"
      );
      setFormFields(filteredFields);
    } else {
      const filteredFields = formContents.data.filter(
        (item) =>
          item.label === "Project" ||
          item.label === "Comment" ||
          item.label === "Application Strategy" ||
          item.label === "Assessment Title" ||
          item.label === "Status"
      );
      setFormFields(filteredFields);
    }
  }, [formik?.values?.Status]);

  const handleDelete = () => {
    toggleDelete();
  };
  return (
    <>
      <Alert
        title={modalProps.title}
        body={modalProps.value}
        open={showConfirm}
        onCancel={closeModal}
      />
      <Dialog open={deleteModal} toggle={toggleDelete}>
        < DialogTitle
          toggle={toggleDelete}>Delete Forever ?</DialogTitle>
        <DialogContent>
          <Typography>
            This Assessment will be deleted forever and you won't be able to
            restore it.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button color='error' onClick={toggleDelete}>
            Cancel
          </Button>
          <Box style={{ flex: 1 }}></Box>
          <Button
            type="submit"
            color="primary"
            onClick={onDeleteClick}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {resultModal && <ResultModal modal={resultModal} Toggle={toggleResult} />}
      <Box>
        <form onSubmit={formik.handleSubmit} >
          <FormControl sx={{ width: '100%' }}>
            {UpdateModal}
            {formFields.length > 0 &&
              formFields.map((item, index) => {
                const { options, type, label } = item;
                index = index + 1;

                if (label === "Project" && assessmentId !== "0") {
                  return fieldMappers[type]({
                    index,
                    item,
                    options: ProjectOption,
                    formik,
                    isDisable: true,
                  });
                } else if (label === "Project") {
                  return fieldMappers[type]({
                    index,
                    item,
                    options: ProjectOption,
                    formik,
                    isDisable: false,
                  });
                }

                if (
                  formik?.values?.Status !== "Moving To Cloud" &&
                  (label === "Project" ||
                    label === "Application Strategy" ||
                    label === "Comment" ||
                    label === "Assessment Title" ||
                    label === "Status")
                ) {
                  return fieldMappers[type]({ index, item, options, formik });
                } else if (
                  !formik?.values?.Status ||
                  (formik?.values?.Status === "Moving To Cloud" &&
                    label !== "Comment")
                ) {
                  return fieldMappers[type]({ index, item, options, formik });
                }
              })}

          </FormControl>

          <FormControl sx={{ float: 'right', mt: 2 }}>
            <Box display='flex' justifyContent='flex-end' gap={2} >
              <Button
                type="cancel"
                sx={{ mr: 1 }}
                variant="contained"
                onClick={onCancelClick}
              >
                Cancel
              </Button>
              {hasEditAccess ? (
                assessmentId !== "0" && (
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={handleDelete}
                    sx={{ mr: 1 }}
                  >
                    Delete
                  </Button>
                )
              ) : (
                assessmentId !== "0" && (
                  <Button type="button" variant="contained" disabled>
                    Delete
                  </Button>
                )
              )}
              {hasEditAccess ? (
                <>
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={(e) => {
                      setIsDraft(true);
                      e.preventDefault();
                      formik.submitForm();
                    }}
                  >
                    Save & Exit
                  </Button>
                  <Button type="submit" variant="contained" color="primary" sx={{ ml: 1, mr: 1 }}>
                    Submit
                  </Button>
                </>
              ) : (
                <>
                  <Button type="button" variant="contained" disabled color="primary">
                    Save & Exit
                  </Button>
                  <Button type="submit" variant="contained" disabled color="primary">
                    Submit
                  </Button>
                </>
              )}
            </Box>

          </FormControl>
        </form>


      </Box>
    </>
  );
};

export default FormBase;
