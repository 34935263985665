import React, { useEffect, useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import ArchiveIcon from '@mui/icons-material/Archive';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Input, FormHelperText, FormControl, DialogActions } from '@material-ui/core';
import { Paper, Box, Autocomplete, TextField, Grid, DialogContentText } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate} from "react-router-dom";
import dayjs from "dayjs";
import { Typography } from '@mui/material';
import Button from "@mui/material/Button";
import GridBase from "../components/GridBase";
import { deleteRecord, saveRecord, updateRecord } from "../redux/httpUtil/crud-helper";
import Util from "../util";
import questions from "../json/questions.json";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import { SelectedProject, SelectedProjectId, loadingToggleAction, SnackBar, AutoCompleteAction } from "../redux/actions/action";
import { FetechAllProjectName } from "../redux/httpUtil/fetchAllproject";
import ParseJwtToken from "../components/jwtToken";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import ModeEditSharpIcon from '@mui/icons-material/ModeEditSharp';
import { EditCellProps } from '@mui/x-data-grid';
import { Link } from '@mui/material';
import useStyles from '../styles'
import config from "../config";

const predictedOption = [
  { value: "Rehost", label: "Rehost" },
  { value: "Replatform", label: "Replatform" },
  { value: "Refactor", label: "Refactor" },
  { value: "Re Architect", label: "Re Architect" },
  { value: "Retain", label: "Retain" },
  { value: "Replace", label: "Replace" }
];
const StatusOption = [
  { value: "In Progress", label: "In Progress" },
  { value: "Review", label: "Review" },
  { value: "Complete", label: "Complete" }
];


const Questionnaire = (props) => {
  const defaultPageSize = 25;
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [params, setParams] = useState({ Id: "" });
  const [data, setData] = useState([]);
  const [updateOver, setUpdateOver] = useState("");
  const [updateValidData, setUpdateValidData] = useState(data);
  const [editableFlag, setEditableFlag] = useState(false);
  const ProjectList = useSelector((state) => state);
  const { selectProjectValues, allProjectNames } = ProjectList;
  const dispatch = useDispatch();
  const projectId = useSelector((s) => s.SelectProjectId);
  const [changeBaseFilter, setChangeBaseFilter] = useState(true);
  const ProjectIdLocal = localStorage.getItem("projectId");
  const [selectedOption, setSelectedOption] = useState("");
  const [selectProjectId, setselectProjectId] = useState(
    ProjectIdLocal === "null" ? null : ProjectIdLocal
  );
  const sortModel = [{ field: 'CreatedOn', sort: 'desc' }]

  var classes = useStyles()
  const autocomplete = useSelector((state) => state.autocomplete);
  let value = autocomplete.newValue;

  useEffect(() => {
    dispatch(loadingToggleAction(true));
    dispatch(FetechAllProjectName());
  }, []);
  const [baseFilters, setBaseFilters] = useState(
    selectProjectId === null
      ? [
        { Field: "IsArchived", Operator: "equals", Value: "false" },
        { Field: "ProjectId", Operator: "equals", Value: null }
      ]
      : [
        { Field: "IsArchived", Operator: "equals", Value: "false" },
        { Field: "ProjectId", Operator: "equals", Value: selectProjectId }
      ]
  );

  useEffect(() => {
    localStorage.setItem("projectId", projectId);
  }, [projectId]);

  const onAddClick = () => {
    const surveysCreatedByUser = data.filter(
      (item) => item.createdByUserId === Util.userId
    );
    localStorage.setItem("IsLoggedIn", false);
    navigate("/assessmentdetail/0", { state: { from: "Assessments" } });
  };

  const handleChange = (e, params) => {
    setUpdateOver(e.target.value);
    if (params.field === "assessmentStatusId") {
      setParams({ Id: params.id, name: "assessmentStatusId" });
      let gridData = data;
      const formData = data.filter((e) => e.id === params.id)[0];
      formData.assessmentStatusId = e.target.value;
      const index = gridData.findIndex((e) => e.id == params.id);
      gridData[index].assessmentStatusId = e.target.value;
      const isSaved = saveRecord({
        id: params.id,
        api: `${config.api_url}/${questions.api}`,
        data: formData,
      });
      if (isSaved) {
        setData(gridData);
      }
    } else {
      setParams({ Id: params.id, name: "rfactor" });
      toggle();
    }
  };

  const onRowClick = (record) => {
    if (record.field == "toolStrategy") {
      setEditableFlag(true);
      return;
    }
    else if (!record.isEditable && record.field != undefined) {
      navigate(`/assessmentdetail/${record.id}`, {
        state: { from: "Assessments" },
      });
    }
  };


  const tokenValue = localStorage.getItem("tokenValue");
  const userId = localStorage.getItem("userId");
  let hasEditAccess = false;
  if (tokenValue) {
    const currentProjectId = localStorage.getItem("projectId");
    const decodedToken = ParseJwtToken(tokenValue);
    const userRole = decodedToken.UserRole;
    hasEditAccess = userRole && userRole.includes("SuperAdmin");
    // check if user has admin privileges

      if (!hasEditAccess && ProjectList && ProjectList.allProjectNames) {
        const selectedProject = ProjectList.allProjectNames.find(a => a.id === currentProjectId);
        if (selectedProject) {
          if (selectedProject.permittedUsers) {
            const permittedUser = selectedProject.permittedUsers.find(a => a.userId === userId);
            if (permittedUser) {
              hasEditAccess = permittedUser.accessId === 1;//TODO create enum for 
            }
          }
        }
      }
  }
  let pinnedColumns = "surveyTitle"
  const columns = [
    {
      field: "surveyTitle",
      headerName: "Assessment Title",
      width: 250,
      type: "string",
      cellClassName: "fixed-column",
      renderCell: (params) => (
        <Tooltip title={params.row.surveyTitle}>
          <Link
            component="div"
            onClick={() => onRowClick(params)}
            style={{ cursor: !hasEditAccess ? "default" : "pointer", textDecoration: "none" }}
          >
            {params.row.surveyTitle}
          </Link>
        </Tooltip>
      )
    },
    {
      field: "status",
      headerName: "Application Strategy",
      width: 200,
      renderCell: (params) => (
        <Tooltip title={params.row.status}>
          <div onDoubleClick={() => onRowClick(params)}>
            <span>{params.row.status}</span>
          </div>
        </Tooltip>
      ),
    },

    {
      field: "rFactorValue",
      headerName: "Predicted R-factor",
      width: 250,
      renderCell: (params) => (
        <Tooltip>
          <div onDoubleClick={() => onRowClick(params)}>
            {params.row.assessmentStatusId === 0 ? (
              ""
            ) : (
              <span>{Util.ToLowerCse(params.row.rFactorValue)}</span>
            )}
          </div>
        </Tooltip>
      ),
    },
    {
      field: "overRideRFactorValue",
      headerName: "Override R-factor",
      width: 250,
      // valueOptions: predictedOption,
      renderCell: (params) => {
        if (hasEditAccess || !hasEditAccess) {
          return (
            <Tooltip title={params.row.overRideRFactorComment}>

              <Select
                className={classes.MuiSelect}
                sx={{
                  "& fieldset": {
                    border: "none",
                  },
                  minWidth: 250
                }}
                value={params.row.overRideRFactorValue}
                onChange={(e) => handleChange(e, params)}
                disabled={!hasEditAccess}
              >
                <MenuItem value={"REHOST"}>Rehost</MenuItem>
                <MenuItem value={"REHOST+/REPLATFORM"} >Rehost+/Replatform</MenuItem>
                <MenuItem value={"REFACTOR"} >Refactor</MenuItem>
                <MenuItem value={"REARCHITECT"} >Rearchitect</MenuItem>
                <MenuItem value={"RETAIN"} >Retain</MenuItem>
                <MenuItem value={"REPLACE"}>Replace</MenuItem>
              </Select>
            </Tooltip>
          );
        } else {
          return <div>{params.row.overRideRFactorValue}</div>;
        }
      },
      editable: hasEditAccess,
      cellMode: "edit",
    },
    {
      field: "assessmentStatusId",
      headerName: "Status",
      valueOptions: StatusOption,
      width: '150',
      renderCell: (params) => (
        <FormControl fullWidth>
          <Select
            className={classes.MuiSelect}
            sx={{
              "& fieldset": {
                border: "none",
              },

              minWidth: 150
            }}
            value={
              params.row.assessmentStatusId === 1
                ? 1
                : params.row.assessmentStatusId === 2
                  ? 2
                  : params.row.assessmentStatusId === 3
                    ? 3
                    : 0
            }
            onChange={(e) => handleChange(e, params)}
            disabled={
              (!hasEditAccess && params.row.assessmentStatusId !== 0) ||
              params.row.assessmentStatusId === 0
            }
          // Disable if isAdmin is false and assessmentStatusId is not 0
          >
            {params.row.assessmentStatusId === 0 && (
              <MenuItem value={0}>Draft</MenuItem>
            )}
            <MenuItem value={1}>In Progress</MenuItem>
            <MenuItem value={2}>Review</MenuItem>
            <MenuItem value={3}>Complete</MenuItem>
          </Select>
        </FormControl>
      ),
      valueOptions: ({ row }) => ['Draft', 'In Progress', 'Review', 'Complete'],
      type: "singleSelect",
      cellMode: "edit",
    },
    {
      field: 'toolStrategy',
      headerName: 'Tool Strategy',
      editable: editableFlag,
      width: 350,
      renderCell: (params) => (
        <Tooltip title={params.row.toolStrategy}>
          <Box
            onClick={!hasEditAccess ? undefined : () => onRowClick(params)}
            sx={{ display: 'flex', justifyContent: 'space-between', width: '350px' }}
          >
            <span>{params.row.toolStrategy}</span>
            <span><ModeEditSharpIcon fontSize="small" /></span>
          </Box>
        </Tooltip>
      ),
    },
    {
      field: "createdOn",
      headerName: "Created On",
      width: 140,
      valueGetter: (params) => {
        const originalValue = params.row[params.field];
        if (originalValue) {
          return dayjs(originalValue).format(Util.dateFormat.DateTime);
        }
        return originalValue;
      },
      type: "date",
    },
    {
      field: "modifiedOn",
      headerName: "Modified On",
      width: 140,
      valueGetter: (params) => {
        const originalValue = params.row[params.field];
        if (originalValue) {
          return dayjs(originalValue).format(Util.dateFormat.DateTime);
        }
        return originalValue;
      },
      type: "date",
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 130,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <>           
            {hasEditAccess && (
              <>
               <IconButton
              color="primary"
              onClick={() => {
                copyAssessment(params)
              }}
            >
              <Tooltip title={"Copy"}>
                <ContentCopyIcon />
              </Tooltip>
            </IconButton>
                <IconButton
                  color="primary"
                  onClick={() => openModal({ id: params.id, name: "delete" })}
                >
                  <Tooltip title={"Delete"}>
                    <DeleteIcon />
                  </Tooltip>
                </IconButton>
                <IconButton
                  color="primary"
                  onClick={() => openModal({ id: params.id, name: "archive" })}

                >
                  <Tooltip title={"Archive"}>
                    <ArchiveIcon />
                  </Tooltip>
                </IconButton>
              </>
            )}
          </>
        );
      },
    },
  ];


  const getCopiedAssessmentTitle = (name) => {
    const copyCount = name.substring(
      name.lastIndexOf("Copy") + (name.endsWith("Copy") ? 1 : 6),
      name.endsWith("Copy") ? name.length - 3 : name.lastIndexOf(")")
    );

    if (Number(copyCount)) {
      name = name.replace(copyCount, Number(copyCount) + 1);
    } else {
      name += name.endsWith("Copy") ? ` (${copyCount + 1})` : " Copy";
    }
    for (const assessment of data) {
      if (name === assessment.surveyTitle) {
        return getCopiedAssessmentTitle(assessment.surveyTitle);
      }
    }
    return name;
  };

  const copyAssessment = ({ row }) => {
    let title = `${row.surveyTitle} - Copy`;
    const copiedAssessment = data.filter((e) => e.surveyTitle === title);
    if (copiedAssessment?.length) {
      title = getCopiedAssessmentTitle(title);
    }
    delete row.id;
    row.surveyTitle = title;
    updateRecord({
      id: null,
      api: `${config.api_url}/${questions.api}`,
      data: row,
      setActiveRecord: (res) => {

        if (res.isSuccess) {
          dispatch(SnackBar({ open: 'true', message: 'Assessment copied Successfully' }));
          navigate(`/assessmentdetail/${res.value.id}`, {
            state: { from: "Assessments" },
          });
        } else {
          dispatch(SnackBar({ open: 'true', message: 'Assessment not copied Successfully' }));
        }
      },
    });
  };

  const handleCellEdit = (params) => {
    if (
      setParams({
        Id: params.id,
        [params.field]: params.value,
        name: "rfactor",
      })
    ) {
      toggle();
    } else if (params.field === "toolStrategy") {
      const validGridData = data;
      const validFormData = data.filter((e) => e.id === params.id)[0];
      if (params.value.length > 50) {
        const validString = params.value.slice(0, 50);

        validFormData.toolStrategy = validString;
        const index = validGridData.findIndex((e) => e.id == params.id);
        validGridData[index].toolStrategy = validString;
        setUpdateValidData(validGridData);
      } else {
        validFormData.toolStrategy = params.value;
      }
      const isSavedWork = saveRecord({
        id: params.id,
        api: `${config.api_url}/${questions.api}`,
        data: validFormData,
      });
      if (isSavedWork) {
        setData(validGridData);
      }
    }
    setEditableFlag(false);
  };

  const openModal = ({ id, name }) => {
    toggle();
    setParams({ Id: id, name: name });
  };

  const validationSchema = (formfields) => {
    const validationConfig = {};
    let config;
    if (params?.name === "rfactor") {
      config = yup.string().label("OverRideRFactorComment");
      config = config.required(`Please fill the Comment`);
      validationConfig["OverRideRFactorComment"] = config;
    } else {
      config = yup.string().label("DeleteComment");
      config = config.required(`Please fill the Comment`);
      validationConfig["DeleteComment"] = config;
    }
    return yup.object(validationConfig);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {},
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      let gridData = data;
      const formData = data.filter((e) => e.id === params.Id)[0];
      if (values.OverRideRFactorComment) {
        formData.OverRideRFactorComment = values.OverRideRFactorComment;
        formData.OverRideRFactorValue = updateOver;
        const index = gridData.findIndex((e) => e.id == params.Id);
        gridData[index].overRideRFactorValue = updateOver;
        gridData[index].overRideRFactorComment = values.OverRideRFactorComment;
      } else {
        formData.ArchiveComment = values.DeleteComment;
        formData.IsArchived = true;
        formData.ArchivedOn = new Date();
        formData.ArchiveBy = localStorage.getItem("userId");
        gridData = gridData.filter((assessment) => assessment.id !== params.Id);
        dispatch(SnackBar({ open: 'true', message: 'Assessment Archived Successfully' }));
      }
      const isSaved = saveRecord({
        id: params.Id,
        api: `${config.api_url}/${questions.api}`,
        data: formData,
      });

      if (isSaved) {
        setData(gridData);
      }
      toggle();
      resetForm();
    },
  });

  const handleCancle = () => {
    formik.resetForm();
    toggle();
    setData(data);
  };


  const handleDialogSubmit = () => {
    formik.handleSubmit();
  }

  const deleteAssessment = () => {
    deleteRecord({ id: params.Id, api: `${config.api_url}/${questions.api}`});
    setModal(!modal);
    setChangeBaseFilter(!changeBaseFilter);
    dispatch(SnackBar({ open: 'true', message: 'Assessment Deleted Successfully' }));
  };

  const refactorCommentModal = (
    <Dialog open={modal} toggle={toggle}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",  // Set your width here
          },
        },
      }}
    >
      <DialogTitle toggle={toggle}>Comment</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <FormControl fullWidth>
            <TextField
              type="textarea"
              name="OverRideRFactorComment"
              id="OverRideRFactorComment"
              placeholder="Comment"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={formik?.errors["OverRideRFactorComment"]}
              value={formik?.values["OverRideRFactorComment"]}
            />
            <FormHelperText
              invalid={formik?.errors["OverRideRFactorComment"]}
            >
              {formik?.errors["OverRideRFactorComment"]}
            </FormHelperText>
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancle} color='error' >
          Cancel
        </Button>
        <Box style={{ flex: 1 }} />
        <Button type="submit" onClick={handleDialogSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )

  const handleClose = () => {
    setModal(false);
    formik.resetForm();
  };

  const archiveModal = (
    <Dialog open={modal} toggle={toggle} onClosed={handleClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",  // Set your width here
          },
        },
      }}>
      <DialogTitle toggle={toggle}>Reason for Archive</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <TextField
            type="textarea"
            name="DeleteComment"
            id="DeleteComment"
            placeholder="Archive Comment"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            invalid={formik?.errors["DeleteComment"]}
            value={formik?.values["DeleteComment"]}
          />
          <FormHelperText
            invalid={formik?.errors["DeleteComment"]}
          >
            {formik?.errors["DeleteComment"]}
          </FormHelperText>
        </FormControl>
        {/* </form> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancle} color='error'>
          Cancel
        </Button>
        <Box style={{ flex: 1 }} />
        <Button type="submit" onClick={handleDialogSubmit}  >
          Submit
        </Button>
      </DialogActions>
    </Dialog>

  );

  const deleteModal = (
    <Dialog open={modal} toggle={toggle}>
      <DialogTitle toggle={toggle}>Delete Forever ?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This Assessment will be deleted forever and you won't be able to
          restore it.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        < Button onClick={handleCancle} color='error'>
          Cancel
        </Button>
        <Box style={{ flex: 1 }} />
        <Button
          type="submit"
          onClick={deleteAssessment}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )

  const actionModals = {
    delete: deleteModal,
    archive: archiveModal,
    rfactor: refactorCommentModal,
  };

  const HandleProjectFliter = (name) => {
    let value = allProjectNames.filter((e) => {
      return e.name == name;
    });
    let k = value.length
    localStorage.setItem("selectedOption", name);
    let data = localStorage.getItem("projectId")
    if (k == 0) {
      dispatch(SelectedProjectId(data));
      let arr = [
        { Field: "IsArchived", Operator: "equals", Value: "false" },
        { Field: "ProjectId", Operator: "equals", Value: data },
      ];
      setBaseFilters(arr);
      // changing setChangeBaseFilter only for  fetch again
      setChangeBaseFilter(!changeBaseFilter);

    }
    else {
      dispatch(SelectedProjectId(value[0]?.id));
      let arr = [
        { Field: "IsArchived", Operator: "equals", Value: "false" },
        { Field: "ProjectId", Operator: "equals", Value: value[0].id },
      ];
      setBaseFilters(arr);
      // changing setChangeBaseFilter only for  fetch again
      setChangeBaseFilter(!changeBaseFilter);
    }
    dispatch(AutoCompleteAction({ on: false, newValue: "" }));
  };

  if (autocomplete.on == true && autocomplete.newValue != " ") {
    setSelectedOption(value)
    HandleProjectFliter(value);
    dispatch(SelectedProject(value));
  }

  useEffect(() => {
    const storedOption = localStorage.getItem("selectedOption");
    if (storedOption) {
      setSelectedOption(storedOption);
      HandleProjectFliter(storedOption);
      dispatch(SelectedProject(storedOption));
    }
  }, []);
  return (
    <>
      {actionModals[params?.name]}
      <Box sx={{ mt: 10 }}>
        <GridBase
          columns={columns}
          updateValidData={updateValidData}
          onCellEditCommit={(currentRow) => handleCellEdit(currentRow)}
          isCellEditable={() => true}
          data={data}
          isAssessment={true}
          canAdd={true}
          canDelete={false}
          canExport={true}
          setData={setData}
          onRowClick={onRowClick}
          handleClickOpen={onAddClick}
          changeBaseFilter={changeBaseFilter}
          baseFilters={baseFilters}
          defaultPageSize={defaultPageSize}
          pinnedColumn={pinnedColumns}
          gridSpace={true}
          sortModel={sortModel}
        />
      </Box>
    </>
  );
};
export default Questionnaire;
