import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Input, FormHelperText, DialogActions } from '@material-ui/core';
import { useFormik } from 'formik';
import { saveRecord } from '../redux/httpUtil/crud-helper';
import { Box, DialogContent, Grid } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import Alert from './Modal';
import { useNavigate, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { FetechAllProjectName } from '../redux/httpUtil/fetchAllproject';
import { validationSchema } from './formik/validationSchema';
import { SelectedProject, SelectedProjectId, SnackBar, AddProject } from '../redux/actions/action';
import Dialog from '@mui/material/Dialog';
import Button from "@mui/material/Button";
import FormControl from '@mui/material/FormControl';
import { DialogTitle, Tooltip } from '@material-ui/core';
import config from '../config';

class FieldMappers {
    "textarea" = ({ index, item, options, formik }) => {
        return (
            <FormControl>
                <Typography for={item.value} >{index + '. ' + item.label}</Typography>
                <OutlinedInput type="textarea" name={item.name} id={item.value} placeholder={item.label} onChange={formik.handleChange}
                    onBlur={formik.handleBlur} invalid={formik?.errors[item.name]}
                    value={formik?.values[item.name]} />
                <FormHelperText invalid={formik?.errors[item.name]} >{formik?.errors[item.name]}</FormHelperText>
            </FormControl>
        )
    };
    "checkbox" = ({ index, item, options, formik }) =>
        <FormControl>
            <Typography for={item.value} >{index + '. ' + item.label}</Typography>
            {options?.length > 0 && options.map((data) => {
                return (
                    <Grid>
                        <Typography check >
                            <Input type="checkbox"
                                name={item.name}
                                id={item.value}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                invalid={formik?.errors[item.name]}
                                value={formik?.values[item.name]} />{' '}
                            {data.label}
                        </Typography>
                    </Grid>
                )
            }) ||
                <Typography >
                    <OutlinedInput type="checkbox"
                        name={item.name}
                        id={item.value}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        invalid={formik?.errors[item.name]}
                        value={formik?.values[item.name]} />{' '}
                    {item.label}
                </Typography>
            }
            <FormHelperText invalid={formik?.errors[item.name]} >{formik?.errors[item.name]}</FormHelperText>
        </FormControl>
    "select" = ({ index, item, options, formik }) => {

        return (
            <FormControl>
                <Typography for={item.value} >{index + '. ' + item.label}</Typography>
                <OutlinedInput

                    type="select"
                    name={item.name}
                    id={item.value}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={formik?.errors[item.name]}
                    value={formik?.values[item.name]}
                >
                    {options.map((data) => {
                        return <option key={data.label} value={data.value}>{data.label}</option>;
                    })}
                </OutlinedInput>
                <FormHelperText invalid={formik?.errors[item.name]} >{formik?.errors[item.name]}</FormHelperText>
            </FormControl >
        )
    };
    "email" = ({ index, item, options, formik }) => {
        return (
            <FormControl>
                <Typography for={item.value} >{index + '. ' + item.label}</Typography>
                <OutlinedInput type="email" name={item.name} id={item.value} placeholder={item.label} onChange={formik.handleChange}
                    onBlur={formik.handleBlur} invalid={formik?.errors[item.name]}
                    value={formik?.values[item.name]} />
                <FormHelperText invalid={formik?.errors[item.name]} >{formik?.errors[item.name]}</FormHelperText>
            </FormControl>
        )
    };
    "number" = ({ index, item, options, formik }) => {
        return (
            <FormControl>
                <Typography for={item.value} >{index + '. ' + item.label}</Typography>
                <OutlinedInput type="number" name={item.name} id={item.value} onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={formik?.errors[item.name]}
                    value={formik?.values[item.name]} />
                <FormHelperText invalid={formik?.errors[item.name]} >{formik?.errors[item.name]}</FormHelperText>
            </FormControl>
        )
    };
    "string" = ({ index, item, options, formik }) => {
        return (
            <FormControl fullWidth>
                <Typography for={item.value} >{item.label}</Typography>
                <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                    <OutlinedInput
                        sx={{}}
                        type="text" name={item.name} id={item.value} onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        invalid={formik?.errors[item.name]}
                        value={formik?.values[item.name]}
                        placeholder="Please enter "
                    />
                    <FormHelperText invalid={formik?.errors[item.name]}>{formik?.errors[item.name]}</FormHelperText>
                </Grid>

            </FormControl>

        )
    };
    "password" = ({ index, item, options, formik }) => {
        return (
            <FormControl>
                <Typography for={item.value} >{index + '. ' + item.label}</Typography>
                <OutlinedInput type="password" name={item.name} id={item.value} placeholder={item.label} onChange={formik.handleChange}
                    onBlur={formik.handleBlur} invalid={formik?.errors[item.name]}
                    value={formik?.values[item.name]}
                />
                <FormHelperText invalid={formik?.errors[item.name]}>{formik?.errors[item.name]}</FormHelperText>
            </FormControl>
        )
    };
    "date" = ({ index, item, options, formik }) => {
        <FormControl>
            <Typography for={item.value} >{index + '. ' + item.label}</Typography>
            <OutlinedInput type="date" name={item.name} id={item.value} placeholder={item.label} onChange={formik.handleChange}
                onBlur={formik.handleBlur} invalid={formik?.errors[item.name]}
                value={formik?.values[item.name]} />
            <FormHelperText invalid={formik?.errors[item.name]}>{formik?.errors[item.name]}</FormHelperText>
        </FormControl>
    };
    "radio" = ({ index, item, options, formik }) => {
        return (
            <FormControl tag="fieldset" row>
                <Grid md={3}>
                    <Typography >{index + '. ' + item.Criteria}</Typography>
                </Grid>
                <Grid md={6}>
                    <Typography >{item.label}</Typography>
                </Grid>
                <Grid>
                    {options.map((data, i) => {
                        return (
                            <FormControl check inline key={i}>
                                <Typography check>
                                    <OutlinedInput type="radio" name={item.name} data-tip={item.label} id={data.value} key={data.value} onChange={formik.handleChange}
                                        onBlur={formik.handleBlur} invalid={formik?.errors[item.name]} checked={data.value == formik?.values[item.name]} value={data.value} data-for={`tooltip-${data.tooltip_label}`}
                                    />{' '}
                                    {data.label}
                                </Typography>
                                <Tooltip id={`tooltip-${data.tooltip_label}`} effect="solid">
                                    {data.tooltip_label}
                                </Tooltip>
                            </FormControl>
                        )
                    })}
                </Grid>
                {formik?.errors[item.name] &&
                    <div className="radio-error"><span>{"Please select value"}</span></div>
                }
            </FormControl>
        )
    };
}

const fieldMappers = new FieldMappers();

const ProjectBase = (props) => {
    const { formContents, fields, activeRecord, setRefresh } = props;
    const { state } = useLocation();
    const [isDisable, setIsDisable] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false);
    const [modalProps, setModalProps] = useState({ title: '', value: '' });
    const [formFields, setFormFields] = useState(formContents.data);
    const [values, setValues] = React.useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { projectId } = useParams();
    const [params, setParams] = useState({ Id: '' })
    const [modal, setModal] = useState(false)
    const toggle = () => setModal(!modal)
    const [Value, setvalue] = useState()
    const [success, setSuccess] = useState(false)
    const [isTrue, setIsTrue] = useState(true);
    const onCancelClick = () => {
        setIsTrue(!isTrue);
        dispatch(AddProject({ open: false }));
    }

    const onChangeProjectFliter = (value) => {
        dispatch(SelectedProjectId(value.id))
        dispatch(SelectedProject(value.name));
        localStorage.setItem("projectId", value.id);
        localStorage.setItem("selectedOption", value.name);
        navigate(`/assessments`)
    }

    const openModal = ({ id, name }) => {
        toggle()
        setParams({ Id: id, name: name })
    }
    let formData = {};


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { ...values, ...activeRecord },
        validationSchema: validationSchema(formFields),
        onSubmit: (values, { resetForm }) => {
            let formValues = { ...values }
            const userId = localStorage.getItem("userId")
            formData.createdByUserId = userId;
            formData = { ...formData, ...formValues };
            setvalue({
                activeRecord: activeRecord,
                formContents: formContents,
                formData: formData
            });
            if (activeRecord?.id) {
                openModal({ id: params.Id, name: 'update' })
            }
            else {
                updateProject({
                    activeRecord: activeRecord,
                    formContents: formContents,
                    formData: formData
                });
            }
        }
    });

    const closeModal = () => {
        setShowConfirm(false);
        onCancelClick();
    };



    const updateProject = (Value) => {
        const { activeRecord, formContents, formData } = Value;
        saveRecord({
            id: activeRecord?.id, api: `${config.api_url}/${formContents?.api}`, data: formData, setActiveRecord: (res) => {
                
                if (res.isSuccess) {  
                    activeRecord?.id ? onCancelClick() : onChangeProjectFliter(res.value);
                    dispatch(FetechAllProjectName());
                    localStorage.setItem("projectId", res.value.id);
                    localStorage.setItem("selectOption", res.value.name);
                    dispatch(SnackBar({ open: 'true', message: `${res.message}` }));
                    dispatch(AddProject({ open: false }));
                    setModal(false);
                    setRefresh(new Date);
                }
                else if (res && !res.isSuccess && res.message) {
                    dispatch(SnackBar({ open: 'true', message: `${res.message}` }));
                    setIsDisable(false)
                    setModal(false);
                }
                else {
                    onCancelClick();
                }
            }
        });
    }

    const CancelClick = () => {
        setModal(false);
    }

    const UpdateModal = (
        <Dialog open={modal} toggle={toggle} sx={{
            "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "500px",  // Set your width here
                },
            }
        }} >
            <DialogTitle toggle={toggle}>Update Project ?</DialogTitle>
            <DialogContent>
                <Typography>
                    This Project will be Updated.
                </Typography>
                <DialogActions>
                    <Button color='error' onClick={CancelClick} >
                        Cancel
                    </Button>
                    <Box style={{flex:1}}></Box>
                    <Button onClick={() => updateProject(Value)} >
                        Update
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );

    return (
        <>
            {UpdateModal}
            <Alert title={modalProps.title} body={modalProps.value} open={showConfirm} onCancel={closeModal} />
            <Box>
                <Dialog open={isTrue} fullWidth>
                    <DialogTitle>
                        Project
                    </DialogTitle>
                    <DialogContent>
                        <form onSubmit={formik.handleSubmit} >
                            {formFields.length > 0 && formFields.map((item, index) => {
                                const { options, type, label } = item;
                                return (
                                    <Box>
                                        {fieldMappers[type]({ index: index + 1, item, options, formik })}
                                    </Box>
                                )
                            })}
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button type="button"  color="error" onClick={onCancelClick}>Cancel</Button>
                        <Box style={{flex:1}}></Box>
                        <Button type="submit"  color="primary" onClick={formik.handleSubmit} disabled={isDisable}>{projectId == "0" ? 'Submit' : 'Submit'}</Button>
                    </DialogActions>
                </Dialog>

            </Box >

        </>
    )
};

export default ProjectBase;