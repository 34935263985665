import React, { useState } from "react";
import { useEffect } from "react";
import { Box } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import Util from "../util";
import project from "../json/project.json";
import { getAllRecords } from "../redux/httpUtil/crud-helper";
import { useDispatch, useSelector } from "react-redux";
import { SelectedProject, SelectedProjectId, AutoCompleteAction } from "../redux/actions/action";
import { FetechAllProjectName } from "../redux/httpUtil/fetchAllproject";
import { ContainersModal } from "../components/ContainersModal";
import config from "../config";
import GridBase from "../components/GridBase";
const ContainersOpportunity = () => {
  const projectId = useSelector((s) => s.SelectProjectId)
  const [reports, setReports] = useState([]);
  const [Modal, setModal] = React.useState(false);
  const navigate = useNavigate();
  const ProjectIdLocal = localStorage.getItem("projectId")
  const [selectedOption, setSelectedOption] = useState("");
  const [selectProjectId, setselectProjectId] = useState(ProjectIdLocal === "null" ? null : ProjectIdLocal);
  const [projectList, setProjectList] = useState(JSON.parse(localStorage.getItem('projectList')) || []);
  let defaultPageSize = 25;
  const [baseFilters, setBaseFilters] = useState(
    selectProjectId === null
      ? [
        { Field: "ReportType", Operator: "equals", Value: "1" },
        { Field: "ProjectId", Operator: "equals", Value: null },
      ]
      : [
        { Field: "ReportType", Operator: "equals", Value: "1" },
        { Field: "ProjectId", Operator: "equals", Value: selectProjectId },
      ]
  );

  const [changeBaseFilter, setChangeBaseFilter] = useState([
    { Field: "IsArchived", Operator: "equals", Value: "false" },
  ]);


  const autocomplete = useSelector((state) => state.autocomplete);
  let data = autocomplete.newValue;


  useEffect(() => {
    getAllRecords({
      id: null, api: `${config.api_url}/${project.api}`, setActiveRecord: (res) => {
        if (res) {
          const Data = res?.record
          setProjectList(Data)
        }
      },
    });
  }, [])

  useEffect(() => {
    localStorage.setItem('projectList', JSON.stringify(projectList));
  }, [projectList]);


  const dispatch = useDispatch();
  const ProjectList = useSelector((state) => state);
  const { selectProjectValues, allProjectNames } = ProjectList;

  useEffect(() => {
    dispatch(FetechAllProjectName());
  }, []);

  const onRowClick = (record) => {
    if (!record.isEditable) {
      navigate(`/assessmentdetail/${record.id}`, {
        state: { from: "dashboard" },
      });
    }
  };

  useEffect(() => {
    localStorage.setItem("projectId", projectId);
  }, [projectId]);
  let pinnedColumns = 'surveyTitle'
  const columns = [
    {
      field: "surveyTitle",
      headerName: "Assessment Title",
      width: 250,
      type: "string",
      renderCell: (params) => (
        <Tooltip title={params.row.surveyTitle}>
          <span onClick={() => {
            onRowClick(params)
          }}>{params.row.surveyTitle}</span>
        </Tooltip>

      ),
    },
    {
      field: "status",
      headerName: "Application Strategy",
      width: 160,
      type: "string",
    },
    {
      field: "rFactorValue",
      headerName: "Predicted R-factor",
      width: 230,
      renderCell: (params) => (
        <Tooltip title={params.row.rFactorValue}>
          <span>{Util.ToLowerCse(params.row.rFactorValue)}</span>
        </Tooltip>
      ),
    },
    {
      field: "overRideRFactorValue",
      headerName: "Override R-factor",
      width: 200,
      renderCell: (params) => (
        <Tooltip title={params.row.overRideRFactorComment}>
          <span>{Util.ToLowerCse(params.row.overRideRFactorValue)}</span>
        </Tooltip>
      ),
    },
    {
      field: "assessmentStatusId",
      headerName: "Status",
      width: 100,
      valueGetter: (params) => {
        switch (params.value) {
          case 0:
            return "Draft";
          case 1:
            return "In progress";
          case 2:
            return "Review";
          case 3:
            return "Complete";
        }
      },
      valueOptions: ({ row }) => ['Draft', 'In Progress', 'Review', 'Complete'],
      type: "singleSelect",
    },
    {
      field: "toolStrategy",
      headerName: "Tool Strategy",
      width: 320,
    },
    {
      field: "createdOn",
      headerName: "Created On",
      width: 140,
      valueGetter: (params) => {
        const originalValue = params.row[params.field];
        if (originalValue) {
          return dayjs(originalValue).format(Util.dateFormat.DateTime);
        }
        return originalValue;
      },
      type: "date",
    },
    {
      field: "modifiedOn",
      headerName: "Modified On",
      width: 140,
      valueGetter: (params) => {
        const originalValue = params.row[params.field];
        if (originalValue) {
          return dayjs(originalValue).format(Util.dateFormat.DateTime);
        }
        return originalValue;
      },
      type: "date",
    },
  ];

  const HandleProjectFliter = (name) => {
    localStorage.setItem("selectedOption", name);
    const value = allProjectNames.filter((e) => {
      return e.name == name;
    });
    let k = value.length;
    let data = localStorage.getItem("projectId");
    if (k == 0) {
      dispatch(SelectedProjectId(data));
      let arr = [{ Field: "ReportType", Operator: "equals", Value: "1" }, 
      { Field: "ProjectId", Operator: "equals", Value: data }];
      setBaseFilters(arr);
      // changing setChangeBaseFilter only for  fetch again
      setChangeBaseFilter(!changeBaseFilter);
    }
    else {
      dispatch(SelectedProjectId(value[0]?.id));
      let arr = [{ Field: "ReportType", Operator: "equals", Value: "1" },
        { Field: "ProjectId", Operator: "equals", Value: value[0].id }];
      setBaseFilters(arr);
      // changing setChangeBaseFilter only for  fetch again
      setChangeBaseFilter(!changeBaseFilter);
    }
    dispatch(AutoCompleteAction({ on: false, newValue: "" }));
  };

  if (autocomplete.on == true && autocomplete.newValue != " ") {
    setSelectedOption(data)
    HandleProjectFliter(data);
    dispatch(SelectedProject(data));
  }

  useEffect(() => {
    const storedOption = localStorage.getItem("selectedOption");
    if (storedOption === 'All') {
      setSelectedOption('');
    } else {
      setSelectedOption(storedOption);
    }
  }, []);



  const toggle = () => {
    setModal(!Modal);
  };

  return (
    <>
      {Modal && <ContainersModal modal={Modal} Toggle={toggle} />}
      <Box sx={{ mt: 10 }}>
      <GridBase
        columns={columns}
        data={reports}
        canDelete={false}
        value={2}
        guidence={true}
        defaultPageSize={defaultPageSize}
        isReports={true}
        pinnedColumn={pinnedColumns}
        gridSpace={true}
        changeBaseFilter={changeBaseFilter}
        baseFilters={baseFilters}
        setData={setReports}
      />
      </Box>
    </>
  );
}

export default ContainersOpportunity