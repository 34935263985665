import React, { useEffect, useState } from "react";
import GridBase from "../components/GridBase";
import { Paper, Box, Autocomplete, TextField, Tooltip, DialogActions, Typography } from "@mui/material";
import { saveRecord } from "../redux/httpUtil/crud-helper";
import questions from "../json/questions.json";
import project from "../json/project.json";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import FormControl from '@mui/material/FormControl';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FetechAllProjectName } from "../redux/httpUtil/fetchAllproject";
import Alert from '@mui/material/Alert';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SnackBar } from '../redux/actions/action';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import IconButton from '@mui/material/IconButton';
import config from "../config";

const Archive = () => {
  const defaultPageSize = 25;
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [data, setData] = useState([]);
  const [params, setParams] = useState({ Id: "" });
  const [isProject, setIsProject] = useState(true);
  const ProjectList = useSelector((state) => state);
  const { selectProjectValues, allProjectNames } = ProjectList;
  const dispatch = useDispatch();
  const [SelectProjectName, setSelectProjectName] = useState("All");
  const [selectedProjectId, setSelectedProjectId] = useState("All");
  
  const [changeBaseFilter, setChangeBaseFilter] = useState(true);

  const [baseFilters, setBaseFilters] = useState(
    selectedProjectId === "All"
      ? [
        { Field: "IsArchived", Operator: "equals", Value: "true" },
        { Field: "ProjectId", Operator: "equals", Value: null },
      ]
      : [
        { Field: "IsArchived", Operator: "equals", Value: "true" },
        { Field: "ProjectId", Operator: "equals", Value: selectedProjectId },
      ]
  );
  const columns = [
    {
      field: "surveyTitle",
      headerName: "Assessment Title",
      width: 250,
      type: "string",
      renderCell: (params) => (
        <Tooltip title={params.row.surveyTitle}>
          <span>{params.row.surveyTitle}</span>
        </Tooltip>
      ),
    },
    {
      field: "status",
      headerName: "Application Strategy",
      width: 160,
      type: "string",
    },
    {
      field: "overRideRFactorValue",
      headerName: "Override R-factor",
      width: 200,
    },
    {
      field: "projectName",
      headerName: "Project",
      width: 180,
      type: "string",
      renderCell: (params) => (
        <Tooltip title={params.row.projectName}>
          <span>{params.row.projectName}</span>
        </Tooltip>
      ),
      filterable: false,
    },
    {
      field: "archiveComment",
      headerName: "Archive Comment",
      width: 350,
      type: "string",
      renderCell: (params) => (
        <Tooltip title={params.row.archiveComment}>
          <span>{params.row.archiveComment}</span>
        </Tooltip>
      )
    },
    {
      field: "archivedOn",
      headerName: "Archive On",
      width: 140,
      valueGetter: (params) => {
        const originalValue = params.row[params.field];
        if (originalValue) {
          return dayjs(originalValue).format('MM/DD/YYYY HH:mm');
        }
        return originalValue;
      },
      type: "date",
    },
    {
      field: "archivedByUser",
      headerName: "Archive By",
      width: 280,
    },
    {
      field: "UnArchive",
      headerName: "Actions",
      width: 150,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <IconButton
            color="primary"
            onClick={() => openModal(params)}
          >
            <Tooltip title={"Unarchive"}>
              <UnarchiveIcon />
            </Tooltip>
          </IconButton>
        );
      },
    },
  ];

  useEffect(() => {
    let data = dispatch(FetechAllProjectName(true));
  }, []);

  const projectcolumns = [
    {
      field: "name",
      headerName: "Project Title",
      width: 250,
      type: "string",
      renderCell: (params) => (
        <Tooltip title={params.row.name}>
          <span>{params.row.name}</span>
        </Tooltip>
      ),
    },
    {
      field: "company",
      headerName: "Company Name",
      width: 200,
      type: "string",
      renderCell: (params) => (
        <Tooltip title={params.row.company}>
          <span>{params.row.company}</span>
        </Tooltip>
      ),
    },

    {
      field: "archiveComment",
      headerName: "Archive Comment",
      width: 350,
      type: "string",
      renderCell: (params) => (
        <Tooltip title={params.row.archiveComment}>
          <span>{params.row.archiveComment}</span>
        </Tooltip>
      ),
    },
    {
      field: "archivedOn",
      headerName: "Archive On",
      width: 160,
      valueGetter: (params) => {
        const originalValue = params.row[params.field];
        if (originalValue) {
          return dayjs(originalValue).format('MM/DD/YYYY HH:mm');
        }
        return originalValue;
      },
      type: "date",
    },
    {
      field: "archivedByUser",
      headerName: "Archive By",
      width: 280,
      filterable: false
    },
    {
      field: "UnArchive",
      headerName: "Actions",
      width: 150,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <IconButton
            color="primary"
            onClick={() => openModal(params)}
          >
            <Tooltip title={"Unarchive"}>
              <UnarchiveIcon />
            </Tooltip>
          </IconButton>
        );
      },
    },
  ];

  const openModal = (params) => {
    setParams({ Id: params.id });
    toggle();
  };

  const restoreAssessment = () => {
    if (isProject) {
      const formData = data.filter((e) => e.id === params.Id)[0];
      formData.archiveComment = "";
      formData.archivedOn = null;
      formData.isArchived = false;

      saveRecord({
        id: formData.id,
        api: `${config.api_url}/${project.api}`,
        data: formData,
      }).then((e) => {
        if (e) {
          const gridData = data.filter((project) => project.id !== params.Id);
          setData(gridData);
          dispatch(FetechAllProjectName());
          dispatch(SnackBar({ open: 'true', message: 'Unarchived Successfully' }));
          toggle();
        }
      });
    } else {
      const formData = data.filter((e) => e.id === params.Id)[0];

      formData.ArchiveComment = "";
      formData.ArchivedOn = null;
      formData.IsArchived = false;
      saveRecord({
        id: formData.id,
        api: `${config.api_url}/${questions.api}`,
        data: formData,
        setActiveRecord: (res) => {
          if (!res.isSuccess) {
            dispatch(SnackBar({ open: 'true', message: `${res.message}` }));
          } else {
            dispatch(SnackBar({ open: 'true', message: 'Restored Successfully' }));
            const gridData = data.filter(
              (assessment) => assessment.id !== params.Id
            );
            setData(gridData);
          }
        },
      });
      toggle();
    }
  };
  const HandleChange = (e) => {
    let value = e.target.value;
    if (value == "project") {
      setIsProject(true);
    }
    else {
      setIsProject(false);
    }
  };

  const HandleProjectFliter = (name) => {
    setSelectProjectName(name);
    const value = allProjectNames.filter((e) => {
      return e.name == name;
    });
    const k = value.length;
    const data = localStorage.getItem("projectId");
    const projectValue = k === 0 ? data : value[0].id;
    const arr = [
      { Field: "IsArchived", Operator: "equals", Value: "true" },
      { Field: "ProjectId", Operator: "equals", Value: projectValue },
    ];

    setBaseFilters(JSON.parse(JSON.stringify(arr)));
    setSelectedProjectId(projectValue);
    setChangeBaseFilter(!changeBaseFilter);
  };

  return (
    <>
      <Box
        sx={{ display: 'flex', justifyContent: 'end', mt: 10 }}
      >
        {!isProject && (
          <Autocomplete
            sx={{ width: '300px' }}
            disablePortal
            id="combo-box-demo"
            options={allProjectNames.map((item) => item.name)}
            value={SelectProjectName}
            renderInput={(params) => (
              <TextField {...params} label="Search Project" />
            )}
            onChange={(event, newValue) => {
              HandleProjectFliter(newValue || "All");
            }}
          />
        )}
        <FormControl sx={{ ml: 1, minWidth: 300 }}>
          <Select
            sx={{ minHeight: 55 }}
            onChange={HandleChange}
            defaultValue={"project"}
          >
            <MenuItem value="">
            </MenuItem>
            <MenuItem value={"project"}>Project</MenuItem>
            <MenuItem value={"assessment"}>Assessment</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
        <Dialog open={modal} toggle={toggle}>
          <DialogTitle toggle={toggle}>
            Restore {isProject ? "Project" : "Assessment"} ?
          </DialogTitle>
          <DialogContent>
            <Typography>
              Restored {isProject ? "Project" : "Assessment"} will move back
              to {isProject ? "Project" : "Assessment"}.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={toggle} color='error'>
              Cancel
            </Button>
            <Box style={{ flex: 1 }}></Box>
            <Button type="submit" onClick={restoreAssessment} >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
        <GridBase
          columns={isProject ? projectcolumns : columns}
          data={data}
          setData={setData}
          checkboxSelection={!isProject}
          canDelete={!isProject}
          canAdd={false}
          canExport={false}
          baseFilters={isProject ? [
            { Field: "IsArchived", Operator: "equals", Value: "true" },
          ] : baseFilters}
          defaultPageSize={defaultPageSize}
          isProject={isProject}
          isArchived={true}
          gridSpace={true}
          changeBaseFilter={changeBaseFilter}
        />
      </Box>
    </>
  );
};

export default Archive;