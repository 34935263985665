import React from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
const Google_ClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const GoogleAuth = ({ children }) => {
	return (
		<GoogleOAuthProvider clientId={Google_ClientId}>
			{children}
		</GoogleOAuthProvider>
	);
};

export default GoogleAuth;
