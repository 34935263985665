function getUrl () {
    const hostName = window.location.hostname;
    if (hostName === 'localhost') {
        return 'https://localhost:7059/api';//backend url for localhost
    }
    else {
        return `https://${hostName}/backend/api`;
    }
}
export default {
    api_url: getUrl()
}