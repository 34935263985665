import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Button,Typography } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export const ResultModal = (props) => {
  const { modal, Toggle } = props;
  const navigate = useNavigate();
  const {state} = useLocation();
  const {from}=state;
  const result =
    useSelector((state) => {
      const { rFactorValues = {} } = state || {};
      return rFactorValues;
    }) || {};

  const { RfactorPredictor, ...others } = result;
  const onClick = ()=>{
    Toggle()
    if (from === "assessment" || from === 'Assessments' || from === 'Assessment' || from === 'assessments') {
        navigate('/assessments');
    } else {
        navigate('/dashboard');
    }

  }

  return (
     <Dialog open={modal} >
         <DialogTitle>Result</DialogTitle>
     <DialogContent>
    <Typography>Predicted R-factor is {RfactorPredictor}</Typography>
       </DialogContent>
       <DialogActions>
       <Button  onClick={onClick}>
          Ok
        </Button>
       </DialogActions>
    </Dialog>
  );
};
