import { useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { red } from "@mui/material/colors";

//simport { makeStyles } from "@mui/styles"
export default makeStyles(theme => ({
  container: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
  },
  logotypeContainer: {
    width: "60%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [useTheme().breakpoints.down("md")]: {
      width: "50%",
    },
    [useTheme().breakpoints.down("md")]: {
      display: "none",
    },
    color:"white"
  },
  logotypeImage: {
    width: 165,
    marginBottom: useTheme().spacing(4),
  },
  logotypeText: {
    color: "white",
    fontWeight: 400,
    [useTheme().breakpoints.down("md")]: {
      
    },
  },
  formContainer: {
    width: "40%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [useTheme().breakpoints.down("md")]: {
      width: "50%",
    },
  },
  form: {
    width: 400,
    [useTheme().breakpoints.down("md")]: {
    //  width: 280,
    },
    [useTheme().breakpoints.down("sm")]: {
      // width: 232,
    }
  },
  tab: {
    fontWeight: 400
  },
  greeting: {
    fontWeight: 500,
    textAlign: "center",
    marginTop: useTheme().spacing(4),
  },
  subGreeting: {
    fontWeight: 500,
    textAlign: "center",
    marginTop: useTheme().spacing(2),
  },
  googleButton: {
    marginTop: useTheme().spacing(6),
    backgroundColor: "white",
    width: "100%",
    textTransform: "none",
  },
  googleButtonCreating: {
    marginTop: 0,
  },
  googleIcon: {
    width: 30,
    marginRight: useTheme().spacing(2),
  },
  creatingButtonContainer: {
    marginTop: useTheme().spacing(2.5),
    height: 46,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  createAccountButton: {
    height: 46,
    textTransform: "none",
    marginTop: 32,
    backgroundColor: '#1565c0',
    color: '#FFFFFF !important'
  },
  formDividerContainer: {
    marginTop: useTheme().spacing(4),
    marginBottom: useTheme().spacing(4),
    display: "flex",
    alignItems: "center",
  },
  formDividerWord: {
    paddingLeft: useTheme().spacing(2),
    paddingRight: useTheme().spacing(2),
  },
  formDivider: {
    flexGrow: 1,
    height: 1,
    backgroundColor: useTheme().palette.text.hint + "40",
  },
  errorMessage: {
    textAlign: "center",
  },
  textFieldUnderline: {
    "&:before": {
      borderBottomColor: useTheme().palette.primary.light,
    },
    "&:after": {
      borderBottomColor: useTheme().palette.primary.main,
    },
    "&:hover:before": {
      borderBottomColor: `${useTheme().palette.primary.light} !important`,
    },
  },
  textField: {
    borderBottomColor: useTheme().palette.background.light,
  },
  formButtons: {
    width: "100%",
    marginTop: useTheme().spacing(4),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  forgetButton: {
    height: 46,
    textTransform: "none",
    fontWeight: 400,
    marginTop: 18,
    backgroundColor: '#1565c0',
    color: '#FFFFFF !important' 
  },
  loginLoader: {
    marginLeft: useTheme().spacing(4),
  },
  copyright: {
    marginTop: useTheme().spacing(4),
    whiteSpace: "pre-wrap",
    [useTheme().breakpoints.up("md")]: {
      position: "absolute",
      bottom: useTheme().spacing(2),
    },
  },
  typography: {
    h3: {
      color:useTheme().palette.background.light,
    }
  },
  error: {
    color: 'red'
  },
  MuiSelect: {
        fontSize: '14px !important', // Set the font size for the Select component
  },
  dialogButton:{
    marginRight:'19em !important'
  },
  dialogButtonRight:{
    marginRight:'1em !important'
  }
}
));