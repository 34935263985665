import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Button from '@mui/material/Button';
import axios from "axios";
import UserEditModal from "./UserEditModal";
import config from "../config";
import { useDispatch, useSelector } from "react-redux";
import { SelectedProject, SelectedProjectId, SnackBar, AutoCompleteAction } from "../redux/actions/action";
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import GridBase from "../components/GridBase";
import IconButton from '@mui/material/IconButton';
import Tooltip from "@mui/material/Tooltip";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const SetupUser = () => {
  const defaultPageSize = 25;
  let authToken = localStorage.getItem("tokenValue");
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);
  const [pendingUsers, setPendingUsers] = useState([]);
  const ProjectList = useSelector((state) => state);
  const { selectProjectValues, allProjectNames } = ProjectList;
  const [changeBaseFilter, setChangeBaseFilter] = useState(true);
  const [selectedOption, setSelectedOption] = useState("");
  const ProjectIdLocal = localStorage.getItem("projectId");
  const [refresh, setRefresh] = useState(null);
  const [selectProjectId, setselectProjectId] = useState(
    ProjectIdLocal === "null" ? null : ProjectIdLocal
  );
  const [dialogConfig, setDialogConfig] = useState({ open: false, title: '', message: '', action: null });

  const handleOpenDialog = (title, message, action) => {
    setDialogConfig({ open: true, title, message, action });
  };

  const handleCloseDialog = () => {
    setDialogConfig({ ...dialogConfig, open: false });
  };

  useEffect(() => {
    let data = localStorage.getItem("projectId");
  }, []);
  const [baseFilters, setBaseFilters] = useState(
    selectProjectId === null
      ? [
        { Field: "ProjectId", Operator: "equals", Value: null },
      ]
      : [
        { Field: "ProjectId", Operator: "equals", Value: selectProjectId },
      ]
  );
  const fetchUser = async (data) => {
    const response = await axios.get(`${config.api_url}/User?projectId=${data}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken ? authToken : ""}`,
      },
    });
    const users = response.data.value;
    // sort users based on isApproved property
    users.sort((a, b) => {
      if (a.isApproved === b.isApproved) {
        return new Date(b.createdOn) - new Date(a.createdOn);
      } else {
        return a.isApproved ? 1 : -1;
      }
    });
    setPendingUsers(users);
  };

  useEffect(() => {
    const storedOption = localStorage.getItem("selectedOption");
    if (storedOption) {
      setSelectedOption(storedOption);
    }
  }, []);


  const autocomplete = useSelector((state) => state.autocomplete);
  let data = autocomplete.newValue;

  const handleApproved = async (user) => {
    const response = await axios.put(
      `${config.api_url}/User/id/?id=${user.id}`,
      {
        ...user,
        isApproved: true,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken ? authToken : ""}`,
        },
      }
    );
    response.status === 200 &&
      dispatch(SnackBar({ open: 'true', message: 'User Approved Successfully' }));
    setRefresh(new Date());
  };

  const handleDelete = async (id) => {
    const response = await axios.delete(
      `${config.api_url}/User/id/?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken ? authToken : ""}`,
        },
      }
    );
    response.status === 200 &&
      dispatch(SnackBar({ open: 'true', message: 'User Deleted Successfully' }));
    setRefresh(new Date());
  };

  const handleUserSelection = (selection) => {
    setSelectedUser(selection);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const pendingColumns = [
    { field: "firstName", headerName: "First Name", width: 180 },
    { field: "lastName", headerName: "Last Name", width: 180 },
    { field: "email", headerName: "Email", width: 280 },
    {
      field: "roleId",
      headerName: "Role",
      width: 170,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {params.value === 1
            ? "SuperAdmin"
            : params.value === 3
              ? "User"
              : "Admin"}
        </div>
      ),
    },
    {
      field: "isDeactivated",
      headerName: "Status",
      width: 160,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {params.value === true ? "Inactive" : "Active"}
        </div>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      width: 140,
      renderCell: (params) => {
        const isApproved =
          params.row.isApproved === true && pendingUsers.includes(params.row);
        return (
          <>
            {!isApproved && (
              <>
                <Button variant="contained" color="primary" onClick={() => handleOpenDialog('Confirm Approval', 'Are you sure you want to approve?', () => handleApproved(params.row))}>
                  <DoneIcon />
                </Button>
                <Button sx={{ ml: 1 }} variant="contained" color="error" onClick={() => handleOpenDialog('Confirm User Deletion', 'Are you sure you want to delete this user?', () => handleDelete(params.row.id))}>
                  <ClearIcon />
                </Button>
              </>
            )}
            {isApproved && (
              <>
                <IconButton color="primary" onClick={() => handleOpenDialog(
                  'Confirm Deletion',
                  'Are you sure you want to delete this user?',
                  () => handleDelete(params.row.id)
                )}>
                  <Tooltip title="Delete">
                    <DeleteIcon />
                  </Tooltip>
                </IconButton>
              </>
            )}
          </>
        );
      },
    },
  ];

  const HandleProjectFliter = (name) => {
    let value = allProjectNames.filter((e) => {
      return e.name == name;
    });
    let k = value.length;
    localStorage.setItem("selectedOption", name);
    let data = localStorage.getItem("projectId");
    if (k == 0) {
      dispatch(SelectedProjectId(data));
      let arr = [
        { Field: "ProjectId", Operator: "equals", Value: data },
      ];
      setBaseFilters(arr);
      // changing setChangeBaseFilter only for  fetch again
      setChangeBaseFilter(!changeBaseFilter);

    }
    else {
      dispatch(SelectedProjectId(value[0]?.id));
      let arr = [
        { Field: "ProjectId", Operator: "equals", Value: value[0].id },
      ];
      setBaseFilters(arr);
      // changing setChangeBaseFilter only for  fetch again
      setChangeBaseFilter(!changeBaseFilter);
    }
    dispatch(AutoCompleteAction({ on: false, newValue: "" }));
  };

  if (autocomplete.on == true && autocomplete.newValue != " ") {
    setSelectedOption(data)
    HandleProjectFliter(data);
    dispatch(SelectedProject(data));
  }

  const dialog = (
    <Dialog open={dialogConfig.open} onClose={handleCloseDialog}>
      <DialogTitle>{dialogConfig.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogConfig.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color='error'>Cancel</Button>
        <Box style={{ flex: 1 }}></Box>
        <Button onClick={() => {
          dialogConfig.action();
          handleCloseDialog();
        }}>
          {dialogConfig.title === 'Confirm Approval' ? 'Approve' : 'Delete'}
        </Button>
      </DialogActions>
    </Dialog>
  )

  return (
    <>
      {dialog}
      {open && (
        <UserEditModal
          open={open}
          user={selectedUser}
          handleClose={handleClose}
          setRefresh={setRefresh}
        />
      )}
      <Box sx={{ mt: 10 }}>
        <GridBase
          columns={pendingColumns}
          isUser={true}
          data={pendingUsers}
          canAdd={false}
          canDelete={false}
          canExport={false}
          refresh={refresh}
          onRowClick={(row) => {
            if (row.row.isApproved) {
              handleUserSelection(row.row);
            }
          }}
          disableSelectionOnClick
          defaultPageSize={defaultPageSize}
          checkboxSelection={false}
          setData={setPendingUsers}
          gridSpace={true}
          changeBaseFilter={changeBaseFilter}
          baseFilters={baseFilters}
        />
      </Box>
    </>
  );
};

export default SetupUser;