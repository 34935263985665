import { actionType } from '../actions/action';
const initialState = {
  loggedUser: {
    Username: '',
    Password: '',
    ErrorMsg: {
      user: '',
      pass: '',
    },
  },

  rFactorValues: {
    Rehost: 0,
    Replatform: 0,
    Refactor: 0,
    Repurchase: 0,
    RfactorPredictor: 0
  },

  AllProjectNameValues: [],

  SelectedProject: "All",

  ProjectId: null,
  showLoading: false,
  projectProps: {
    open: false,
  },
  isLoading: false,
  isActive: {
    on: false,
    newValue: ''
  },
  dialogProps: {
    open: false,
    message: ''
  }
};

const loggedUser = (state = initialState.questionnaireState, action) => {
  switch (action.type) {
    case actionType.QUESTIONNAIRE_REDUCER:
      state = Object.assign([], state, action.value);
      return state;
    default:
      return state;
  }
};


const rFactorValues = (state = initialState.rFactorValues, action) => {
  switch (action.type) {
    case actionType.RFACTOR_VALUES:
      state = Object.assign([], state, action.value);
      return state;
    default:
      return state;
  }
};


const AllProjectNames = (state = initialState.AllProjectNameValues, action) => {
  switch (action.type) {
    case actionType.GETALLPROJECT_DATA:
      state = action.value
      return state;

    default:
      return state;
  }
};

const SelectProjectValues = (state = initialState.SelectedProject, action) => {
  switch (action.type) {
    case actionType.Default_Selected_Project:
      state = action.value
      return state;

    default:
      return state;
  }
};
const SelectProjectId = (state = initialState.ProjectId, action) => {
  switch (action.type) {
    case actionType.Default_Project_Id:
      state = action.value
      return state;

    default:
      return state;
  }
};

const SnackBardata = (state = initialState.dialogProps, action) => {
  switch (action.type) {
    case actionType.Snackbar_Action:
      state = Object.assign([], state, action.value);
      return state;
    default:
      return state;
  }
}

const loadingToggleAction = (state = initialState.showLoading, action) => {
  switch (action.type) {
    case actionType.Loading_Action:
      state = action.value
      return state;

    default:
      return state;
  }
}
const loadingActionReducer = (state = initialState.isLoading, action) => {
  switch (action.type) {
    case actionType.Loading:
      state = action.value
      return state;
    default: return state
  }
}

const AddNewProject = (state = initialState.projectProps, action) => {
  switch (action.type) {
    case actionType.AddProject_Action:
      state = Object.assign([], state, action.value);
      return state;
    default:
      return state;
  }
}


const AutocompleteFunction = (state = initialState.isActive, action) => {
  switch (action.type) {
    case actionType.Active:
      state = Object.assign([], state, action.value);
      return state;
    default:
      return state;
  }
}

export {
  loggedUser,
  rFactorValues,
  AllProjectNames,
  SelectProjectValues,
  SelectProjectId,
  loadingToggleAction,
  loadingActionReducer,
  SnackBardata,
  AddNewProject,
  AutocompleteFunction
};
