import { useState } from "react";
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Typography,
    Autocomplete,
    Box,
    ListItem,
    ListItemText,
    Button,
    List,
    DialogActions
} from "@mui/material";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch } from 'react-redux';
import { SnackBar } from '../redux/actions/action';
import ParseJwtToken from "./jwtToken";
import config from "../config";
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const InvitationModal = (props) => {
    const { modal, Toggle, data, onUpdateParams } = props;
    const [selectedAccessLevel, setSelectedAccessLevel] = useState("Viewer");
    const [email, setEmail] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedValue, setSelectedValue] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(true);
    const dispatch = useDispatch();
    const [selectedAccessLevelValue, setSelectedAccessLevelValue] = useState("ReadOnly");

    let authToken = localStorage.getItem("tokenValue");

    const handleAccessLevelSelect = (value) => {
        setSelectedAccessLevel(value);
        setSelectedAccessLevelValue(value === "Editor" ? "ReadWrite" : "ReadOnly");
    };
    const [selectedValues, setSelectedValues] = useState({});
    const [isupdate, setupdate] = useState(false);
    const [datas, setdatas] = useState();
    const handleSelectChange = (event, index, row) => {
        let values = event.target.value == "ReadWrite" ? 0 : 1;
        // const { value } = event.target;
        setSelectedValues(prevSelectedValues => ({
            ...prevSelectedValues,
            [index]: values,
        }));
        const updatedPermittedUsers = [...data.row.permittedUsers];
        // Create a copy of permittedUsers array

        updatedPermittedUsers[index] = { ...row, accessId: values }; // Update the specific item at the given index

        const updatedData = { ...data }; // Create a copy of the data object
        updatedData.row.permittedUsers = updatedPermittedUsers; // Update the permittedUsers array in the copied data object

        setdatas(updatedData); // Update the state with the modified data object
        setupdate(!isupdate);
        onUpdateParams(updatedData);
    };


    const [selectedUsers, setSelectedUsers] = useState([]);

    const handleEmailChange = (event, values) => {
        setSelectedUsers(values);
        setIsValidEmail(validateEmails(values));
        if(validateEmails(values) && event.target.value != undefined){
            setEmail([...email, event.target.value]);
        }
    };

    const validateEmails = (values) => {
        for (let i = 0; i < values.length; i++) {
            const email = values[i];
            if (!emailRegex.test(email)) {
                return false;
            }
        }
        return true;
    };

    const handleEmailBlur = () => {
        setIsValidEmail(validateEmails(selectedUsers));

    };

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const OnClickHandle = async () => {
        try {
            const apiUrl = `${config.api_url}/Project/id/share?id=${data.id}&email=${email}&accessType=${selectedValue}`;
            const response = await fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authToken ? authToken : ""}`,
                },
                // body: JSON.stringify(payload)
            });
            response.status === 200 &&
                dispatch(SnackBar({ open: 'true', message: 'Project Shared Successfully' }));
            Toggle();
            // window.location.reload();
        } catch (error) {

        }
    };

    const deleteUser = () => {
    }

    const userId = localStorage.getItem("userId");
    let hasEditAccess = false;
    if (authToken) {
        const decodedToken = ParseJwtToken(authToken);
        const userRole = decodedToken.UserRole;
        // check if user has admin privileges
        hasEditAccess = userRole && userRole.includes("SuperAdmin");

        if (!hasEditAccess && data && data.row && data.row.permittedUsers) {
            const currentRow = data.row;
            if (currentRow) {
                const permittedUser = currentRow.permittedUsers.find(a => a.userId === userId);
                if (permittedUser) {
                    hasEditAccess = permittedUser.accessId === 1;//TODO create enum for 
                }
            }
        }
    }

    return (
        <div>
            <Button variant="contained" color="primary" onClick={Toggle}>
                Share "{data.row.name}" Project
            </Button>
            <Dialog open={modal} toggle={Toggle} size="lg">
                <DialogTitle toggle={Toggle}>Share "{data.row.name}" Project</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Box style={{ display: "flex", marginTop: '20px', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                            <Autocomplete
                                multiple
                                id="users-select"
                                options={users}
                                freeSolo
                                getOptionLabel={(option) => (
                                    <div style={{ wordWrap: 'break-word' }}>
                                        {option.name ? option.name : option}
                                    </div>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        sx={{
                                            width: "350px",
                                            marginRight: "28px",
                                            marginLeft: "18px"
                                        }}
                                        {...params}
                                        variant="outlined"
                                        placeholder="Add users and click enter..."
                                        error={!isValidEmail}
                                        helperText={!isValidEmail && 'Please enter a valid email'}
                                    />
                                )}
                                onBlur={handleEmailBlur}
                                onChange={handleEmailChange}
                                value={selectedUsers}
                            />

                            <Grid item xs={12} md={6}>
                                {hasEditAccess ? (
                                    <FormControl>
                                        <InputLabel id="demo-simple-select-label">Access</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="dropdown"
                                            value={selectedValue}
                                            onChange={handleChange}
                                            label="Access"
                                            sx={{ width: 150 }}
                                        >
                                            <MenuItem value="ReadWrite">Editor</MenuItem>
                                            <MenuItem value="ReadOnly">Viewer</MenuItem>
                                        </Select>
                                    </FormControl>)
                                    : (<FormControl>
                                        <InputLabel id="demo-simple-select-label">Access</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="dropdown"
                                            value={selectedValue}
                                            onChange={handleChange}
                                            label="Access"
                                            sx={{ width: 150 }}
                                        >
                                            <MenuItem value="ReadOnly">Viewer</MenuItem>
                                        </Select>
                                    </FormControl>)
                                }

                            </Grid>
                        </Box>
                        <Box component="div" sx={{ display: 'block', width: 1 }}>
                            <Grid><Typography sx={{ ml: 2.2, mt: 1 }} color="textPrimary">People with access</Typography></Grid>
                            <Divider />
                            <Box sx={{ maxHeight: 300 }} >
                                {data?.row?.permittedUsers?.map((user, index) => (
                                    <List disablePadding key={index}>
                                        <ListItem sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Avatar alt="No Image" sx={{ mr: 1 }}>
                                                {user.userEmail.charAt(0).toUpperCase()}
                                            </Avatar>
                                            <ListItemText>{user.userEmail}</ListItemText>
                                            <div style={{ flex: 1 }}></div> {/* Empty div for spacing */}
                                            <Typography variant="body1">
                                                {user.accessId === 1 ? "Editor" : "Viewer"}
                                            </Typography>
                                        </ListItem>
                                    </List>
                                ))}
                            </Box>
                        </Box>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button color="error" onClick={Toggle}>
                        Cancel
                    </Button>
                    <Box style={{ flex: 1 }}></Box>
                    <Button color="primary" onClick={OnClickHandle} disabled={!isValidEmail} >
                        Submit
                    </Button>{" "}
                </DialogActions>
            </Dialog>
        </div>
    );
};
