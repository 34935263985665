import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import {persistStore,persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
const middlewares = [thunk];
const persistConfig={
  key:'persist-key',
  storage
}
const persistedReducer=persistReducer(persistConfig,rootReducer)
const store = createStore(
  persistedReducer,
  {},
  composeWithDevTools( applyMiddleware(...middlewares))
);
const persistor=persistStore(store);
export default store;
export {persistor}

