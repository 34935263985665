import React, { useState, useEffect } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Box } from "@mui/material";
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useLocation } from 'react-router-dom';
import questions from '../json/questions.json';
import project from '../json/project.json';
import { getAllRecords, getAllUser, getRecord, getReports } from '../redux/httpUtil/crud-helper';
import { SnackBar } from '../redux/actions/action';
import logger from '../redux/httpUtil/logger';
import Export from '../pages/Export';
import { useDispatch, useSelector } from 'react-redux';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import { DevOpsModal } from "../components/DevOpsModal";
import { ContainersModal } from './ContainersModal';
import { ModernizationModal } from './ModernizationModal';
import { TransformationModal } from './TransformationModal';
import ParseJwtToken from './jwtToken';
import config from "../config";
import { Typography } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from "@mui/material/DialogActions";
import { setLoader } from '../redux/actions/action';

const GridBase = (props) => {
    const { onRowClick, handleClickOpen, data, columns, onCellEditCommit, isCellEditable, baseParams = {}, baseFilters = [], checkboxSelection = false,
        canAdd, canDelete, canExport, updateValidData, guidence, value, changeBaseFilter, defaultPageSize, isProject, isUser, isReports, isArchived = false, pinnedColumn, temp, ifDashboard, gridSpace, refresh } = props;
    const { setData } = props;
    let flag = true;
    if (data.length == 0) {
        flag = false;
    }
    const titles = {
        "/projects": "Project",
        "/": "Assessment",
        "/assessments": "Assessment",
        "/dashboard": "Assessment",
        "/archive": "Archive",
        "/archives": "Archive",
        "/users": "User"
    };
    const [title, setTitle] = React.useState(titles["/"]);
    const location = useLocation();

    React.useEffect(() => {
        setTitle(titles[location.pathname]);
    }, [location.pathname]);

    const [pageSize, setPageSize] = useState(defaultPageSize);
    const [recordCount, setRecordCount] = useState(0);
    const [page, setPage] = useState(0);
    const [sortModel, setSortModel] = useState([
        {
            field: "CreatedOn",
            sort: "desc"
        }]);
    const [filterModel, setFilterModel] = useState(null);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
    const [selectionModel, setSelectionModel] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const toggleDelete = () => setDeleteModal(!deleteModal);
    let authToken = localStorage.getItem("tokenValue");
    const dispatch = useDispatch();
    const [userDetails, setUserDetails] = useState(null)
    const [projectList, setProjectList] = useState(null);
    const [kodal, setKodal] = React.useState(false);
    const ProjectList = useSelector((state) => state);

    const [pinnedColumns, setPinnedColumns] = React.useState({
        left: [pinnedColumn],
        right: ['actions']
    });

    const handlePinnedColumnsChange = React.useCallback((updatedPinnedColumns) => {
        setPinnedColumns(updatedPinnedColumns);
    }, []);

    useEffect(() => {
        if (isArchived || isProject || isUser) {
            dispatch(setLoader(true));
            getAllUser({
                api: `${config.api_url}/User`,
                setActiveRecord: (res) => {
                    setUserDetails(res);
                    dispatch(setLoader(false));
                },
            });
        }

        const baseFilters = [];

        if (!isProject && !isUser) {
            dispatch(setLoader(true));
            getAllRecords({
                id: null, api: `${config.api_url}/${project.api}`, setActiveRecord: (res) => {
                    if (res) {
                        const Data = res?.record
                        setProjectList(Data)
                        dispatch(setLoader(false));
                    }
                }, sortModel, baseFilters
            });
        }
    }, [isProject])

    useEffect(() => {
        if (isArchived && !userDetails) {
            return;
        }
        if (!isProject && !projectList && !isUser) {
            return;
        }
        if (isProject) {
            dispatch(setLoader(true));
            getRecord({
                id: null, api: `${config.api_url}/${project.api}`, setActiveRecord: (res) => {
                    if (res) {
                        let data = res.record
                        if (userDetails) {
                            data.map((obj) => {
                                let value = userDetails.filter((e) => {
                                    return e.id == obj?.createdByUserId
                                })
                                if (value[0]?.email) {
                                    obj.createdBy = value[0]?.email
                                }
                                else {
                                    obj.createdBy = value[0]?.email
                                }
                            })
                        }

                        if (userDetails && isArchived) {
                            data.map((obj) => {
                                let value = userDetails.filter((e) => {
                                    return e.id == obj?.archivedByUserId
                                })
                                if (value[0]?.email) {
                                    obj.archivedByUserEmail = value[0]?.email
                                }
                                else {
                                    obj.archivedByUserEmail = obj?.archivedByUserId
                                }
                            })
                        }
                        setData(data);
                        setRecordCount(res.recordCount);
                        dispatch(setLoader(false));
                    }
                }, page, pageSize, sortModel, filterModel, ...baseParams, baseFilters
            })
        }
        else if (isUser) {
            dispatch(setLoader(true));
            getRecord({
                id: null, api: `${config.api_url}/User`, setActiveRecord: (res) => {
                    if (res) {
                        let data = res.record
                        if (userDetails) {
                            data.map((obj) => {
                                let value = userDetails.filter((e) => {
                                    return e.id == obj?.createdByUserId
                                })
                                if (value.length > 0) {
                                    obj.createdBy = value[0]?.email;
                                }
                            })
                        }
                        if (userDetails && isArchived) {
                            data.map((obj) => {
                                let value = userDetails.filter((e) => {
                                    return e.id == obj?.archivedByUserId
                                })
                                if (value.length) {
                                    obj.archivedByUserEmail = value[0]?.email ? value[0]?.email : obj?.archivedByUserId;
                                }
                            })
                        }
                        setData(data);
                        setRecordCount(res.recordCount);
                        dispatch(setLoader(false));
                    }
                }, page, pageSize, filterModel, ...baseParams, baseFilters, sortModel
            })
        }
        else if (isReports) {
            dispatch(setLoader(true));
            getReports({
                id: null, api: `${config.api_url}/Report`, setActiveRecord: (res) => {
                    dispatch(setLoader(false));
                    setRecordCount(res.recordCount);
                    let data = res
                    if (projectList && projectList.length) {
                        data.map((obj) => {
                            let value = projectList.filter((e) => {
                                return e.id == obj?.projectId
                            })
                            obj.projectName = value[0]?.name
                        })
                    }
                    setData(data);
                }, page, pageSize, sortModel, filterModel, ...baseParams, baseFilters, setData
            });
        } else {
            getRecord({
                id: null, api: `${config.api_url}/${questions.api}`, setActiveRecord: (res) => {
                    if (res) {
                        let data = res.record.surveyData
                        if (projectList && projectList.length) {
                            data.map((obj) => {
                                let value = projectList.filter((e) => {
                                    return e.id == obj?.projectId
                                })
                                obj.projectName = value[0]?.name
                            })
                        }
                        if (userDetails && isArchived) {
                            data.map((obj) => {
                                let value = userDetails.filter((e) => {
                                    return e.id == obj?.archiveBy
                                })
                                if (value[0]?.email) {
                                    obj.archivedByUserEmail = value[0]?.email
                                }
                                else {
                                    obj.archivedByUserEmail = obj?.archiveBy
                                }
                            })
                        }
                        setData(data);
                        setRecordCount(res.recordCount);
                    }
                }, page, pageSize, sortModel, filterModel, ...baseParams, baseFilters
            })
        }
    }, [title, isProject, page, projectList, userDetails, pageSize, sortModel, filterModel, location, selectionModel, updateValidData, changeBaseFilter, refresh]);
    const getGridRowId = (row) => {
        return row.id
    }

    const handleDelete = () => {
        toggleDelete();
    }


    const deleteAssessment = async () => {
        toggleDelete();
        try {
            const response = await fetch(`${config.api_url}/${questions.api}/ids`, {
                method: "POST",
                body: JSON.stringify(selectionModel),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken ? authToken : ""}`
                }
            });
            if (response.status === 200) {
                dispatch(SnackBar({ open: 'true', message: 'Selected Assessments deleted successfully' }));
                setSelectionModel([]);
            }
            else {

                logger.error('Delete failed', response.body);
            }
        } catch (error) {
            logger.error('Delete failed', error);
        }
    }

    let hasEditAccess = false;
    const tokenValue = localStorage.getItem('tokenValue');
    if (tokenValue) {
        const Decode = ParseJwtToken(tokenValue);
        // check if user has admin privileges
        hasEditAccess = Decode.UserRole && Decode.UserRole.includes('Admin') || Decode.UserRole && Decode.UserRole.includes('SuperAdmin');
        
        if (!isProject && !hasEditAccess && ProjectList && ProjectList.allProjectNames) {
            const currentProjectId = localStorage.getItem("projectId");
            const selectedProject = ProjectList.allProjectNames.find(a => a.id === currentProjectId);
            if (selectedProject) {
                if (selectedProject.permittedUsers) {
                    const userId = localStorage.getItem("userId");
                    const permittedUser = selectedProject.permittedUsers.find(a => a.userId === userId);
                    if (permittedUser) {
                        hasEditAccess = permittedUser.accessId === 1;//TODO create enum for 
                    }
                }
            }
        }
    }
    
    const toggle = () => {
        setKodal(!kodal);
    };

    const CustomToolbar = function (props) {
        return (
            <GridToolbarContainer {...props}
            >
                {hasEditAccess && canAdd && <Button startIcon={<AddIcon />} onClick={handleClickOpen} size="small">Add {title}</Button>}
                {canDelete && <Button disabled={!selectionModel.length} startIcon={<DeleteIcon />} onClick={handleDelete} size="small">Delete</Button>}
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                {canExport && <Export baseFilters={baseFilters} isComingFromProject={isProject} filterModel={filterModel} />}
                {kodal && value == 1 && <DevOpsModal modal={kodal} Toggle={toggle} />}
                {kodal && value == 2 && <ContainersModal modal={kodal} Toggle={toggle} />}
                {kodal && value == 3 && <ModernizationModal modal={kodal} Toggle={toggle} />}
                {kodal && value == 4 && <TransformationModal modal={kodal} Toggle={toggle} />}
                {guidence && <Button startIcon={< FileOpenIcon />} type="click" onClick={toggle}>Implementation guidance</Button>}

            </GridToolbarContainer>
        );
    };

    const noDataFunction = () => {
        return <div style={{
            textAlign: "center",
            margin: "20px"
        }}>
            {flag ? <Box sx={{ display: 'center' }}>
                <CircularProgress />
            </Box> : "No data found"}
        </div>
    }




    return (
        <>
            <Dialog open={deleteModal} toggle={toggleDelete}>
                <DialogTitle toggle={toggleDelete}>Delete Forever ?</DialogTitle>
                <DialogContent>
                    <Typography>This Assessment will be deleted forever and you won't be able to restore it.</Typography>
                </DialogContent>
                <DialogActions>
                    <Button color="error" onClick={toggleDelete}>Cancel</Button>
                    <Box style={{ flex: 1 }}></Box>
                    <Button onClick={deleteAssessment}>Delete</Button>
                </DialogActions>
            </Dialog>

            <DataGridPro
                sx={{
                    mt: temp ? 0 : gridSpace ? 3 : 10, ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
                        "margin-top": "1em"
                        // "margin-bottom": "1em"
                    },
                }}
                onRowDoubleClick={onRowClick}
                disableColumnMenu
                checkboxSelection={checkboxSelection}
                disableSelectionOnClick
                checkboxSelectionVisibleOnly={true}
                onSelectionModelChange={(newSelectionModel) => setSelectionModel(newSelectionModel)}
                selectionModel={selectionModel}
                onCellEditCommit={onCellEditCommit}
                isCellEditable={isCellEditable}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[25, 50, 100]}
                onPageSizeChange={setPageSize}
                onPageChange={setPage}
                pagination={true}
                rowCount={recordCount}
                rows={data || []}
                rowHeight={30}
                autoHeight
                components={{
                    Toolbar: CustomToolbar,
                    NoRowsOverlay: noDataFunction,
                }}
                density="standard"
                hideFooterSelectedRowCount={true}
                getRowId={getGridRowId}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                //sorting options
                sortingOrder={['desc', 'asc']}
                sortModel={sortModel}
                // sortingMode="server"
                onSortModelChange={setSortModel}

                //filtering options
                filterMode="server"
                onFilterModelChange={setFilterModel}

                //pagination options
                paginationMode="server"

                //Pinning the column
                pinnedColumns={pinnedColumns}
                onPinnedColumnsChange={handlePinnedColumnsChange}
            />
        </>
    )
};

export default GridBase;
