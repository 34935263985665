import React from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { Grid, Typography, Button } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';

const ResultList = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { from } = state;
    const result = useSelector(state => {
        const { rFactorValues = {} } = state || {};
        return rFactorValues;
    }) || {};

    const { RfactorPredictor, ...others } = result;
    const onHandleBack = () => {
        if (from === "assessment" || from === 'Assessments' || from === 'Assessment' || from === 'assessments') {
            navigate('/assessments');
        } else {
            navigate('/dashboard');
        }
    }

    return (
        <>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 10, mb: 2 }}>
                <Typography component="h2" variant="h6" color="primary" py={1.5}>SCORESHEET</Typography>
            </Box>
            <Box >
                <Grid container alignItems="center"
                    justifyContent="center" row  >
                    <Table sx={{ height: 330, width: 800 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <h5>Criteria</h5>
                                </TableCell>
                                <TableCell>
                                    <h5>Score</h5>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.entries(others).map(([key, value]) => (
                                <TableRow key={key}>
                                    <TableCell>{key}</TableCell>
                                    <TableCell>{value}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>
            </Box>
            <Box sx={{ mt: 4 }}>
                <h4 >RfactorPredictor = {RfactorPredictor}</h4>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 6, mb: 2 }}>
                <Button variant="contained" onClick={onHandleBack}>Back</Button>
            </Box>
        </>
    )
}

export default ResultList