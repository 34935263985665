import { Box, } from "@mui/system";
import { Typography,Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from "@mui/material/DialogActions";


export const DevOpsModal = (props) => {
   const handleClose=()=>{
    Toggle();
   }
  const { modal, Toggle } = props;
  const steps = [
    {
      title: 'Version control:',
      content: 'Set up a version control system like Git to manage code and track changes.',
    },
    {
      title: 'Continuous Integration (CI):',
      content: 'Automate the build and testing process using tools like Jenkins, TravisCI, or CircleCI.',
    },
    {
      title: 'Continuous Deployment (CD):',
      content: 'Automate the deployment process to various environments such as test, staging, and production.',
    },
    {
      title: 'Infrastructure as Code (IaC):',
      content: 'Manage and provision infrastructure using code and automate infrastructure updates. Tools like Terraform, CloudFormation, or Ansible can be used.',
    },
    {
      title: 'Monitoring and Logging:',
      content: 'Set up monitoring and logging to track application performance and detect any issues early. Tools like Datadog, New Relic, or Loggly can be used.',
    },
    {
      title: 'Test Automation:',
      content: 'Automate testing at various levels such as unit testing, integration testing, and end-to-end testing.',
    },
    {
      title: 'Release Management:',
      content: 'Implement a streamlined process for managing releases and versioning.',
    },
    {
      title: 'Security:',
      content: 'Incorporate security best practices and tools into the development process, such as static code analysis and security testing.',
    },
  ];

  return (
    <Dialog open={modal} size="lg" toggle={Toggle}>
      <DialogTitle toggle={Toggle}>Implementaton Guidance: DevOps Opportunity</DialogTitle>
      <DialogContent>
        <Box>
          
            <div>
            {steps.map((step, index) => (
        <div key={index}>
          <Typography variant="h6" component="h6"  sx={{mt:1}}>
            {step.title}
          </Typography>
          <Typography variant="body2">
            {step.content}
          </Typography>
        </div>
      ))}
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
    </Dialog>
  );
};
