import { ProjectData, loadingToggleAction } from "../actions/action";
import { getAllRecords } from "./crud-helper";
import project from "../../json/project.json";
import config from "../../config";

const sortModel = [{ field: 'createdOn', sort: 'desc' }]
export const FetechAllProjectName = (isArchived = false) => (dispatch) => {
  let baseFilters = [{ Field: 'IsArchived', Operator: 'equals', Value: 'false' }]

  if (isArchived) {
    baseFilters = [];
  }
  return getAllRecords({
    id: null, api: `${config.api_url}/${project.api}`, setActiveRecord: (res) => {
      if (res) {
        const Data = res?.record
        Data.splice(0, 0, {
          name: "All",
          id: null
        });
        dispatch(ProjectData(Data))
      }
    }, sortModel, baseFilters
  });
}



