import { Box } from "@mui/system";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from "@mui/material/DialogActions";
import { Typography,Button } from '@mui/material';

export const ModernizationModal = (props) => {
  const handleClose=()=>{
    Toggle();
  }
  const { modal, Toggle } = props;
  const sections = [
    {
      title: 'Assessment:',
      content: 'Assess the current state of the application and its architecture, identifying areas for improvement and aligning with business goals.',
    },
    {
      title: 'Refactoring:',
      content: 'Refactor the application code to improve maintainability, scalability, and performance.',
    },
    {
      title: 'Microservices Architecture:',
      content: 'Decompose the monolithic application into smaller, independent microservices, allowing for more flexible deployment and scaling.',
    },
    {
      title: 'Containerization:',
      content: 'Package the application and its dependencies into container images, enabling easier deployment and management.',
    },
    {
      title: 'DevOps Practices:',
      content: 'Implement DevOps practices, such as continuous integration and deployment, infrastructure as code, and monitoring and logging.',
    },
    {
      title: 'Cloud Migration:',
      content: 'Move the application to a cloud platform, such as Azure, AWS, or Google Cloud, to take advantage of its scalability, security, and cost-effectiveness.',
    },
    {
      title: 'Automation:',
      content: 'Automate manual processes and workflows, reducing errors and increasing efficiency.',
    },
    {
      title: 'Data Management:',
      content: 'Evaluate and improve data management strategies, such as data storage, backup, and recovery.',
    },
    {
      title: 'Security:',
      content: 'Implement security best practices and tools, such as encryption, network segmentation, and threat detection.',
    },
    {
      title: 'Monitoring and Feedback:',
      content: 'Continuously monitor the application and gather feedback from users to identify areas for improvement and ensure that the transformation goals are met.',
    },
  ];
  return (
    <Dialog open={modal} size="lg" toggle={Toggle}>
      <DialogTitle toggle={Toggle}>Implementaton Guidance: Modernization Opportunity</DialogTitle>
      <DialogContent>
        <Box >
          <div>
          {sections.map((section, index) => (
        <div key={index}>
          <Typography variant="h6" component="h6"  sx={{mt:1}}>
            {section.title}
          </Typography>
          <Typography variant="body2">
            {section.content}
          </Typography>
        </div>
      ))}
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
          <Button  onClick={handleClose}>Ok</Button>
        </DialogActions>
    </Dialog>
  );
};
